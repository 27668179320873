import React, { Component ,useState} from 'react';
import {Modal,Row,Col,Table} from 'react-bootstrap'
import {PROUDCT_URL_IMAGE,PRODUCT_MORE_URL_IMAGE} from '../modules/app/config'
import '../Style/Component/dailog.css'
import {ErrorOutline,Close,LocalMallTwoTone} from '@material-ui/icons';
import {Badge} from '@material-ui/core'
import ProductRecommand from '../Component/productsRecommend'
import recommandProd from '../Data/recommandProd.json';
import AddressData from '../Data/Addressprovices.json'
import {NOTIMAGE,LOGO} from '../Assets/images'
import {Link} from 'react-router-dom';
import { keyword } from '../utils/keywords';
import amount from '../utils/fomartAmount'
export function Dailog(props) {
    return (
      <Modal {...props} 
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body style={{textAlign:'center'}}>
          {
            props.icon ?
            props.icon:<ErrorOutline style={{fontSize:100}}/>
          }
       
        <br/>
            {props.label}
        </Modal.Body>
        <Modal.Footer style={{justifyContent:'center'}}>
            <button onClick={props.onHide?props.onHide:null} id="btnHide">{keyword('ok')?keyword('ok'):"Ok"}</button>
        </Modal.Footer>
      </Modal>
    );
  }


export function NewDailog(props) {
    const data=props.dataDialog;
    // if(data){
      
    // }
  }

  export function SizeGuide(props) {
    const DataSizes=props.DataSizes;
    function _loopHeader(data){
      var result=[];
      if(data){
        result.push(
          data.map((hearder,index)=>{
            return(
              <td key={index}>{hearder['name']}</td>
            )
          })
        )
      }
      return result
    }
    function _loopBody(data){
      var result=[];
      var iArray=[],i=0,j=0,k=0;
      if(data){
        data.map((item)=>{
          iArray.push(item['data'])
        })
      for(i=0;i<iArray.length;i++){
        result.push
        (
          <td >
            <tr >
            {iArray[i].map((item,index)=>{
                if(index % 2===0){
                  return(
                        <tr>
                            {item}
                        </tr>
                      )
                }else{
                  return(
                    <tr style={{textAlign:'center'}}>
                        {item}
                    </tr>
                  )
                }
              })}
            </tr>
          </td>
        )
      }
    }
    
      return result
    }
    return (
      <Modal {...props} 
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
       <Close id="id_remove" onClick={props.onHide?props.onHide:null}/>
        <Modal.Body style={{textAlign:'center',padding:50}}>
          {DataSizes!=null && 
            <Table >
            <thead>
                {_loopHeader(DataSizes)}
            </thead>
            <tbody>
                {_loopBody(DataSizes)}
            </tbody>
          </Table>
          }
        </Modal.Body>
      </Modal>
    );
  }

  export function DailogAddressAA(props) {
    const [inputName, setInputName] = useState('');
    const [numberPhone, setNumberPhone] = useState('');
    const [building, setBuilding] = useState('');
    const [province, setProvince] = useState('');
    const [addresses,setAddresses]= useState(JSON.parse(localStorage.getItem('amatakUserAddress')));
    const [actionEdit,setActionEdit]=useState(false);
    function loopLocalAddress(){
      var result=[];
      addresses.map((l,i)=>{
        result.push(
          <tr key={i} className={i===0?'selectedAdd':null} >
              <td style={{textAlign:'left'}}>
                  <label>{l['name']},{l['phone']},{l['province']},{l['building']}</label>
              </td>
              <td style={{textAlign:'right'}}>
                  {/* <button id="buttonEdit" onClick={()=>{editAddress(l['id'])}}>edit</button> */}
                  {
                    // addresses.length<=1? null : <button id="buttonDelete" onClick={()=>{deleteAddree(l['id'])}}>delete</button>
                  }
              </td>
          </tr>
        )
      })
      return result;
    }

    function loopTypeAddress(data){
    var result =[];
    data.map((address,i)=>{
        result.push(
          <div className="col-sm-2">
            <button key={i} className={province===address['name']?"btnChoiseProvideClicked":"btnChoiseProvide"}  onClick={()=>{setProvince(address['name'])}}>
                {address['name']}
            </button>
          </div>
        )
    })
    return result;
  }
  function loopTypeOfBuilding(){
    var typeOfBuilding=[{"name":keyword('home-1')?keyword('home-1'):"Home"},{"name":keyword('school')?keyword('school'):"School"}];
    var result =[];
    typeOfBuilding.map((data,i)=>{
      result.push(
        <button key={i} className={building===data['name']?"btnTypeBuildingClicked":"btnTypeBuilding"}  onClick={()=>{setBuilding(data['name'])}}>
            {data['name']}
        </button>
      )
    })
    return result;
  }
  function addLocalStorage(){
      var list=[];
      if((inputName && numberPhone && building && province)!=''){

        if(actionEdit===true){
          alert('edit')
        }
        if(actionEdit===false){
          var id='amatak'+Math.random();
          list.push({'id':id,"name":inputName,"phone":numberPhone,'province':province,'building':building});
          for(var i=0;i<JSON.parse(localStorage.getItem('amatakUserAddress')).length;i++){
            list.push(JSON.parse(localStorage.getItem('amatakUserAddress'))[i]);
          }
          // localStorage.setItem('amatakUserAddress',JSON.stringify(list));
          setInputName('');setBuilding('');
          setNumberPhone('');setProvince();
        }
        
      }
      // if(addresses.length<JSON.parse(localStorage.getItem('amatakUserAddress')).length){
      //     setAddresses(JSON.parse(localStorage.getItem('amatakUserAddress')));
      // }
    }
    return (
      <Modal {...props} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered>
        <Modal.Header>
              <h3>{keyword('choose-an-address')?keyword('choose-an-address'):"Select an address"}</h3>
              <Close id="id_remove" onClick={props.onHide?props.onHide:null}/>
        </Modal.Header>
        <Modal.Body style={{textAlign:'center',padding:50,paddingTop:10}}>
            <div className="showAllAddress">
              <h4 style={{textAlign:'left'}}>{keyword('word-for-location-you-defined')?keyword('word-for-location-you-defined'):"Location you defined"}</h4>
              <Table hover size="sm">
                  <tbody>
                  {loopLocalAddress()}
                  </tbody>
              </Table>
               
            </div>
            <div className="inputNewAddress">
                <input id="inputName" placeholder={keyword('name')?keyword('name'):"Name"} value={inputName}  onChange={()=>setInputName(document.getElementById('inputName').value)}></input>
                <input id="numberPhone" placeholder={keyword('phone-number')?keyword('phone-number'):"Phone Number"} value={numberPhone} onChange={()=>{setNumberPhone(document.getElementById('numberPhone').value)}}></input>
                <div className="choise">
                    <label> {keyword('city-province')?keyword('city-province'):"Please select a province or city:"}</label><br/>
                    <div className="row">
                        {loopTypeAddress(AddressData['results'])}
                    </div>
                    <label>{keyword('location')?keyword('location'):"Location"}</label><br/>
                    <div className="row ">
                        {loopTypeOfBuilding()}
                    </div>
                </div>
                <button id="btnFinishAddress" onClick={()=>{addLocalStorage()}}>{keyword('ok')?keyword('ok'):"Ok"}</button>
            </div>
        </Modal.Body>
      </Modal>
    );
   }

  export function Receipt(props) {
    const data=props.dataDialog;
    const count = props.count;
    const sku=props.sku;
    const prodSKU=props.prodSKU;
    const Total=0;
    const amout=props.amout;
    function loopData(data){
      var result=[];
      if(data){
        data.map((itm,i)=>{
          result.push(
            <tr key={i}>
                <td className='col1'>{i+1}</td>
                <td className='col2'>{itm['name']}</td>
                <td className='col2'>{
                  sku.map((a,j)=>{
                    return(
                        <p key={j}>
                          {a['value']}
                        </p>
                    );
                  })
                }</td>
                <td className='col3'>{itm['minPrice']}</td>
                <td className='col2'>{count}</td>
                <td className='col2'>{amout}</td>
            </tr>
          )
        })
      }
      return result;
    }
    function loopAddresses(){
      var result=[];
      var A=JSON.parse(localStorage.getItem('amatakUserAddress'));
      if(A.length>0){
        result.push(
          <div>
                 <label>
                     {keyword('name')?keyword('name'):"Name"}៖ {A[0]['name']}<br/>
                     {keyword('phone-number')?keyword('phone-number'):"Phone Number"}៖​ {A[0]['phone']}<br/>
                     {keyword('city-province')?keyword('city-province'):"Province / City:"}៖​ {A[0]['province']}<br/>
                     {keyword('location')?keyword('location'):"Location"}៖​ {A[0]['building']}
                 </label>
                 <br/>
                 <button id="editAddress" onClick={props.editOrAddAddress}>{keyword('update')?keyword('update'):"Edit"}</button>
               </div>
        )
      }
      return result;
    }
        return (
          <Modal {...props} 
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered> 
            <Modal.Header>
            <Row className="hearderReceipt">
                    <Col sm={4}>
                        <img src={LOGO} id="img_logo_receipt"/>
                    </Col>
                    <Col sm={8}>
                        <h3>{props.label}!</h3>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body style={{textAlign:'left'}}>
                <div className="insertAddressReceipt">
                    {loopAddresses()}
                </div>
                <div className="tableProductsReceipt">
                    <Table>
                      <thead>
                        <tr>
                          <th className='col1'>{keyword('word-for-comfirm-buy-no')?keyword('word-for-comfirm-buy-no'):"No"}</th>
                          <th className='col2'>{keyword('product')?keyword('product'):"Product"}</th>
                          <th className='col3'>{keyword('details')?keyword('details'):"Details"}</th>
                          <th className='col2'>{keyword('price')?keyword('price'):"price"}</th>
                          <th className='col2'>{keyword('amount')?keyword('amount'):"Amount"}</th>
                          <th className='col2'>  {keyword('money-amount')?keyword('money-amount'):"Total amount"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loopData(data)}   
                      </tbody>
                    </Table>
                </div>
                <div className="tableTotalReceipt">
                    <table >
                      <tbody>
                        <tr>
                          <td className="title">{keyword('amount-price')?keyword('amount-price'):"Total"}</td>
                          <td className="money">$ {amout>0?amount(amout):'0'} </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <button id="btnPaymentNow" onClick={props.checkAddress}>{keyword("pay")?keyword("pay"):"Pay"}</button>
            </Modal.Body>
          </Modal>
      );
    // }
  }