import { all } from "redux-saga/effects";
import { appSaga } from "../modules/app";
import { userSaga } from "../modules/user";
// import {getDataSaga} from '../modules/getData'
import {AddressSaga} from '../modules/address'
import {chatSaga} from '../modules/chat'
import {ShopSaga} from '../modules/shop'

import {productDataSaga} from '../modules/product'
// import {skuproductSaga} from '../modules/skuProduct'
const sagas = [
  appSaga(),
  userSaga(),
  AddressSaga(),
  chatSaga(),
  productDataSaga(),
  ShopSaga()
];

export default function*() {
  yield all(sagas);
}
