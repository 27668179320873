import { connect } from "react-redux";
import Footer from "../Component/footer"
import {
  getPorductCategory,
    } from '../modules/product/reducer'
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = {
  getPorductCategory,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);
