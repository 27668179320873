import { connect } from "react-redux";
import ChangePassword from "../Component/changPassword"
import {confirm_pin,addNew_pin} from '../modules/user/reducer'
const mapStateToProps = state => ({
  ...state,err_CONFIRM_PIN:state.user.err_CONFIRM_PIN
});

const mapDispatchToProps = {
  confirm_pin,addNew_pin

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
