// export const API_URL = "http://192.168.0.101:8088";
//  export const API_URL = "https://js.amatak.net/";


// export const LOGO_IMAGE_URL = 'https://www.amatak.net/admin_/files/logo/'

// export const PROUDCT_THUMBNAIL_URL_IMAGE = 'https://www.amatak.net/admin_/files/products/th/'
// export const PROUDCT_URL_IMAGE = 'https://www.amatak.net/admin_/files/products/'
// export const PRODUCT_MORE_URL_IMAGE = 'https://www.amatak.net/admin_/files/product-images/'
// export const PRODUCT_THUMBNAIL_MORE_URL_IMAGE = 'https://www.amatak.net/admin_/files/product-images/th/'

// export const CATEGORIES_URL_IMAGE = 'https://www.amatak.net/admin_/files/categories/'
// export const CATEGORIES_THUMBNAIL_URL_IMAGE = 'https://www.amatak.net/admin_/files/categories/th/'

// export const STORE_URL_IMAGE = 'https://www.amatak.net/admin_/files/stores/'

// export const BRAND_URL_IMAGE = 'https://www.amatak.net/admin_/files/brands/'
// export const BRAND_THUMBNAIL_URL_IMAGE = 'https://www.amatak.net/admin_/files/brands/th/'

// export const SLIDE_URL_IMAGE = 'https://www.amatak.net/admin_/files/slides/'

// export const MEMBER_URL_IMAGE = 'https://www.amatak.net/admin_/files/members/'
// export const MEMBER_THUMBNAIL_URL_IMAGE = 'https://www.amatak.net/admin_/files/members/th/'




export const API_URL = 'https://kuanghsienapi.kuanghsien.com/'
export const CHAT_URL = 'https://customer.amatak.chat/'
export const API_URL_SUNO = 'https://www.suno.asia/wechat-topup/api/'
export const LOGO_IMAGE_URL = 'https://kuanghsienadmin.kuanghsien.com/files/logo/'
export const PROUDCT_THUMBNAIL_URL_IMAGE = 'https://kuanghsienadmin.kuanghsien.com/files/products/th/'
export const PROUDCT_URL_IMAGE = 'https://kuanghsienadmin.kuanghsien.com/files/products/'
// export const PROUDCT_URL_IMAGE = 'https://customeradmin.amatak.net/files'
export const PRODUCT_MORE_URL_IMAGE = 'https://kuanghsienadmin.kuanghsien.com/files/product-images/'
export const PRODUCT_THUMBNAIL_MORE_URL_IMAGE = 'https://kuanghsienadmin.kuanghsien.com/files/product-images/th/'

export const CATEGORIES_URL_IMAGE = 'https://kuanghsienadmin.kuanghsien.com/files/categories/'
export const CATEGORIES_THUMBNAIL_URL_IMAGE = 'https://kuanghsienadmin.kuanghsien.com/files/categories/th/'

export const STORE_URL_IMAGE = 'https://kuanghsienadmin.kuanghsien.com/files/stores/'

export const BRAND_URL_IMAGE = 'https://kuanghsienadmin.kuanghsien.com/files/brands/'
export const BRAND_THUMBNAIL_URL_IMAGE = 'https://kuanghsienadmin.kuanghsien.com/files/brands/th/'

export const SLIDE_URL_IMAGE = 'https://kuanghsienadmin.kuanghsien.com/files/slides/'

export const MEMBER_URL_IMAGE = 'https://kuanghsienadmin.kuanghsien.com/files/members/'
export const MEMBER_THUMBNAIL_URL_IMAGE = 'https://kuanghsienadmin.kuanghsien.com/files/members/th/'



// products_discount/card/

// products_discount/discount_amount/