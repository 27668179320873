import React, { Component } from 'react'
import {Row,Col,Container, Nav,Modal, Table} from 'react-bootstrap';
import {CloseOutlined} from '@material-ui/icons'
import {NOTIMAGE,LOGO,GIF} from '../Assets/images'
import {PROUDCT_URL_IMAGE} from '../modules/app/config'

import ComfirmPin from '../containers/compornentComfirmPin'
import AddAddress from '../containers/compornentAddAddress';
import NewPin from '../containers/compornentNewPin'
import '../Style/Component/comfirmbuy.css'
import '../Assets/color.css'
import {
    Dailog,
   } from '../Component/dailog'
import {setColor} from '../utils/colors'
import { keyword } from '../utils/keywords';
import amount from '../utils/fomartAmount'
export default class ComfirmBuy extends Component {
    constructor(props){
        super(props);
        this.state={
            addAddress:false,

            paymentPin:false,

            inputPin:false,

            comfrimPin:false,

            Provice:"",
            addressShippingPrice:0,
            dataNotificationStoreName:[],
            valueCodeDis:'',
            ui:{
                errorPhoneNumber:false,
                messageErrorPhoneNumser:'',
                inputCodePromo:false,
                alreadyBuy:false
              },
            disCount:{
                type:0,
                varDis:0
            }
        }
    }
    componentDidMount(){
        const {get_addressData,dataCart} =this.props
        if(localStorage.getItem('persist:amatakWebsite')){
            const user_sms = JSON.parse(localStorage.getItem('persist:amatakWebsite'));
            const login_sms=JSON.parse(user_sms.user);
            if(login_sms.login && login_sms.login.message==="success"){
              if(login_sms.login && login_sms.login.token){
                this.props.get_address();
              }
              // else{
              //   props.history.push('/')
              // }
            }
            else{
              // window.location.assign('/login');
            }
        }
        
        this._getDataUser();
       }

    componentDidUpdate(){
        var localsto= localStorage.getItem('@UserAddress-Amatak');
        if(localsto){
            var A=JSON.parse(localsto);
          if(A['address']!=this.state.Provice){
              return(
                this.setState({
                    Provice:A['address']
                  })
                  ,
                  this.props.get_shipping_address_price(A['address'])
              )
            }
            if(A['address']===this.state.Provice){
               return
            }
        }else if(!localsto){
            if(!localsto && this.state.Provice!=''){
                return(
                    this.setState({
                        Provice:''
                    })
                )
            }
        }
    }

    componentWillReceiveProps(nextProps){
        const {AddressData,buyAlready,get_address,user,dataCart,errorsent_trancsaction,discout_cart} = this.props;
        var localsto= localStorage.getItem('@UserAddress-Amatak');
       
        if(nextProps.AddressData.get_shipping_address_price && nextProps.AddressData.get_shipping_address_price!= AddressData.get_shipping_address_price){
            if(nextProps.AddressData.get_shipping_address_price['message']==="success"){
                this.setState({
                    addressShippingPrice:nextProps.AddressData.get_shipping_address_price.results['price']
                })
            }
        }

        if(nextProps.AddressData.get_address && nextProps.AddressData.get_address !=AddressData.get_address){
           if(nextProps.AddressData.get_address['message']==="success"){
            var localsto= localStorage.getItem('@UserAddress-Amatak');
            if(localsto==='' && nextProps.AddressData.get_address['data'].length!=0){
                var DATA=nextProps.AddressData.get_address['data'][nextProps.AddressData.get_address['data'].length-1];
                localStorage.setItem('@UserAddress-Amatak',JSON.stringify(DATA));
            }else{
                return
            }
           }
        }
        if(nextProps.user.confirm_PIN && nextProps.user.confirm_PIN !=user.confirm_PIN){
            if(nextProps.user.confirm_PIN.message==="success"){
                if(localsto){
                    var A=JSON.parse(localsto);
                    
                    let convertArrayToObject =[];
                    dataCart.map((data)=>{
                        convertArrayToObject.push(
                            {
                                "ID":data['ID'],
                                "qty":data['qty'],
                                "skuAlias":data['alias']
                            }
                        )
                    })    

                var dataCheck=
                                {
                                    "dataCheckOut":
                                    convertArrayToObject
                                    ,
                                    "address": {
                                    "name":A['name'],
                                    "phone":A['phone'],
                                    "address":A['address'],
                                    "addDetail":A['addDetail'],
                                    "locationType":A['locationType'],
                                    "adID":A['adID']
                                    },
                                    "shippingPrice": this.state.addressShippingPrice
                                }
                this.props.checkOut_user(dataCheck)
                }
            }
        }
        
        if(nextProps.user.err_check_out_user && nextProps.user.err_check_out_user != user.err_check_out_user){
            if(nextProps.user.err_check_out_user.data.message==="balance_not_enough"){
                this.setState({
                    ui:{
                        ...this.state.ui,
                        errorPhoneNumber:true,
                        messageErrorPhoneNumser:keyword('you-do-not-have-enough-money-to-purchase-this-product')?keyword('you-do-not-have-enough-money-to-purchase-this-product'):"You do not have enough money to purchase this item."
                    }
                })
              
            }else{
                this.setState({
                    ui:{
                        ...this.state.ui,
                        errorPhoneNumber:true,
                        messageErrorPhoneNumser:keyword('connection-problem')?keyword('connection-problem'):"Connection problem!"
                    }
                });
              
            }
        }
      
        if(nextProps.buyAlready && nextProps.buyAlready!=buyAlready){
            if(nextProps.buyAlready.message==='success'){
                this.props.getCartList();
                this.setState({
                    comfrimPin:false,
                    ui:{...this.state.ui,alreadyBuy:true}
                });
            }
            
        }
        if(nextProps.discout_cart && nextProps.discout_cart!=discout_cart){
           if(nextProps.discout_cart.message==="invalid_card_code"){
               this.setState({
                   ui:{
                       ...this.state.ui,
                       messageErrorPhoneNumser:keyword('this-card-number-is-incorrect')?keyword('this-card-number-is-incorrect'):"Please fill in correctly.",
                        errorPhoneNumber:true
                   }
               })
           }
           if(nextProps.discout_cart.message==="card_code_used_already"){
                this.setState({
                    ui:{
                        ...this.state.ui,
                        messageErrorPhoneNumser:keyword('this-card-number-is-already-used')?keyword('this-card-number-is-already-used'):"Please fill in correctly.",
                        errorPhoneNumber:true
                    }
                })
           }
           if(nextProps.discout_cart.message==="success"){
               if(nextProps.discout_cart.results['0']['status']===1){
                    this.setState({
                        ui:{
                            ...this.state.ui,
                            messageErrorPhoneNumser:keyword('you-get-a-discount-from-the-card')?keyword('you-get-a-discount-from-the-card'):"Success",
                            errorPhoneNumber:true
                        }
                    })
               }
               else{
                    this.setState({
                        disCount:{
                            ...this.state.disCount,
                            type:nextProps.discout_cart.results['0']['type'],
                            varDis:nextProps.discout_cart.results['0']['amount']
                        },
                        ui:{
                            ...this.state.ui,
                            inputCodePromo:false
                        }
                        
                    })
               }
           }
        }
    }
    _handleUi(key,value){
        this.setState({
            ui:{
                ...this.state.ui,[key]:value
            }
        })
    }
    _getDataUser(){
        var paymentPin=JSON.parse(localStorage.getItem('persist:amatakWebsite'));
        if(paymentPin&&paymentPin['user']){
            var payment_user=JSON.parse(paymentPin['user']);
            if(payment_user['login'] && payment_user['login_sms']){
                var loginData=payment_user['login']['data']['paymentPin']
                this.setState({
                    paymentPin:loginData
                });
            }
           
    
            //Get location price shipping address
            var localsto= localStorage.getItem('@UserAddress-Amatak');
            if(localsto){
                var A=JSON.parse(localsto);
                this.setState({
                    Provice:A['address']
                });
                this.props.get_shipping_address_price(A['address']);
            }
        }
    }
        
    _buy(){
        var localsto= localStorage.getItem('@UserAddress-Amatak');
        if(localsto===''){
            this.setState({addAddress:true})
        }else{
            var paymentPin=JSON.parse(localStorage.getItem('persist:amatakWebsite'));
            if(paymentPin&&paymentPin['user']){
                var payment_user=JSON.parse(paymentPin['user']);
                if(payment_user['login'] && payment_user['login_sms']){
                    var pinHave_ot=payment_user['login']['data']['paymentPin']
                    // console.log('pinHave_ot',pinHave_ot)
                    if(pinHave_ot===false){
                        this.setState({inputPin:true,comfrimPin:false})
                    }else{
                        this.setState({comfrimPin:true,inputPin:false});
                    }
                }
        
            }
        }
    }
    _navBuy(total,colorB,colorL){
        return(
            <div className="navBuyComfirmBuy">
                <Container>
                    <Row>
                        <Col md={12}>
                            <button style={{backgroundColor:colorB,color:colorL}} id="toPay" onClick={()=>{this._buy()}}>
                                {keyword('payment')?keyword('payment'):"Go to pay"}
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
    loopAddresses(){
        var result=[];
        var localsto= localStorage.getItem('@UserAddress-Amatak');
        if(localsto){
            var A=JSON.parse(localsto);
            result.push(
            <div key={A.name} className="address_user_resiept">
                <label>
                    {keyword('name')?keyword('name'):"Name"}៖ {A.name}<br/>
                    {keyword('phone-number')?keyword('phone-number'):"Phone number"}៖​ {A.phone}<br/>
                    {keyword('city-province')?keyword('city-province'):"City/Province"}៖  {A.address}<br/>
                    {keyword('location')?keyword('location'):"Location"}៖​ {A.locationType}<br/>
                    {keyword('house-number,-street-or-address-details')?keyword('house-number,-street-or-address-details'):"Detail location"} ៖<span>{A.addDetail}</span> <br/>
                </label>
                <br/>
                <button id="editAddress" onClick={()=>{this.setState({addAddress:true})}}>{keyword('update')?keyword('update'):"Edit"}</button>
            </div>
          );
          
        }else{
            result.push(
                <button id="btnAddAddress" onClick={()=>{this.setState({addAddress:true})}} key={1}>
                    {keyword('add-new-address')?keyword('add-new-address'):"Add new address"}
                </button>
            )
        }
        return result;
      }
    _verifyCodePromo(){
        if(this.state.valueCodeDis){
            this.props.getDiscoutCart(this.state.valueCodeDis);
        }
    }
    loopData(data){
        var result=[];
        if(data){
          data.map((itm,i)=>{
            result.push(
              <div key={i} >
                <Row className="once_rescept">
                  <Col md={1} >{i+1}</Col>
                    <Col md={2}>
                        <div className="comfirmBuy_image_product">
                            {
                                itm['productDiscount']>0?
                                <p>-{itm['productDiscount']}%</p>:
                                null
                            }
                            <img src={PROUDCT_URL_IMAGE + itm['image']} className="prodImageComfirmBuy"/>
                        </div>
                    </Col>
                  <Col md={2} className="product_name_alias">
                      {itm['name']}
                        <br/>
                        {itm['alias']}
                  </Col>
                  <Col md={3} >$ {itm['price']>0?amount(itm['price']):0}</Col>
                  <Col md={1} >{itm['qty']}</Col>
                  <Col md={3}>$ {itm['total']>0?amount(itm['total']):0}</Col>                 
                </Row>

                <Row className="once_rescept_small" >
                    <Col md={4} sm={4} xs={4} style={{padding:0}}>
                        <div className="comfirmBuy_image_product">
                            {
                                itm['productDiscount']>0?
                                <p>-{itm['productDiscount']}%</p>:
                                null
                            }
                            <img src={PROUDCT_URL_IMAGE + itm['image']} className="prodImageComfirmBuy"/>
                        </div>
                    </Col>
                    <Col md={8} sm={8} xs={8} className="product_name_alias">
                          {itm['name']}
                            <br/>
                            {itm['alias']}
                            <br/>
                           $ {itm['price']>0?amount(itm['price']):0} x {itm['qty']}
                            <br/>
                            <span>
                               $ {itm['total']>0?amount(itm['total']):0}
                            </span>
                          
                    </Col>
                  </Row>
              </div>
            )
          })
        }
        return result;
      }
    _handle_close_buy(){
        this.setState({
            ui:{
                ...this.state.ui,
                alreadyBuy:false
            }
        });
        window.location.assign("/");
    }
    render() {
        const {onShow,onHide,dataCart,total}=this.props;
        let bgP=setColor('primaryBackground');
        let colP=setColor('primaryColor');
        let colL=setColor('lightColor');
        let bgL=setColor('lightBackground');
        
        return (
            <div> 
                <Modal
                    show={onShow} 
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="class_comfirm_buy_product"
                    >
                     <Modal.Header className="hear__"> 
                     <CloseOutlined id="iconCloseComfirmBuy" onClick={onHide}/>
                     <div className="hearderReceipt">
                        <h3>{keyword('confirm-order')?keyword('confirm-order'):"Confirm order!"}</h3>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                       
                        <div className="insertAddressReceipt" >
                            {this.loopAddresses()}
                        </div>
                        <div className="tableProductsReceiptcomfirmBuy">
                            <Row className="header_resipt" style={{backgroundColor:bgL,color:colL}}>
                                <Col md={1}>
                                    {keyword('word-for-comfirm-buy-no')?keyword('word-for-comfirm-buy-no'):"No"}
                                </Col>
                                <Col md={2}>
                                    {keyword('product')?keyword('product'):"Product"}
                                </Col>
                                <Col md={2}>
                                    {keyword('details')?keyword('details'):"Details"}
                                </Col>
                                <Col md={3}>
                                    {keyword('price')?keyword('price'):"price"}
                                </Col>
                                <Col md={1}>
                                    {keyword('amount')?keyword('amount'):"Amount"}
                                </Col>
                                <Col md={3}>
                                    {keyword('money-amount')?keyword('money-amount'):"Total amount"}
                                </Col>
                            </Row>
                            {this.loopData(dataCart)} 
                           
                         
                        </div>
                    
                    <Row className="div_caculate" >
                                <Col lg={6} md={12} sm={12} xs={12} >
                                    <div className="promoCode">
                                        <button id="btnInputCode" onClick={()=>{this.setState({ui:{...this.state.ui,inputCodePromo:true}})}}>
                                            <img src={GIF.present}/>
                                            {keyword('enter-discount-card-number')?keyword('enter-discount-card-number'):"Input discount card"}
                                        </button>
                                    </div>
                                </Col>
                                <Col  lg={6} md={12} sm={12} xs={12} className="showAllTotal">
                                <h5>
                                        {keyword('amount-price')?keyword('amount-price'):"Total"}៖ <span>$ {total>0?amount(total):0} </span>
                                    </h5>
                                    <h5>
                                        {keyword('shipping')?keyword('shipping'):"Delivery"}៖ <span>$ {this.state.addressShippingPrice ? this.state.addressShippingPrice>0?amount(this.state.addressShippingPrice):"0":'----'}</span>​ 
                                    </h5>
                                    {
                                        this.state.disCount.varDis>0 &&
                                        <h6>{keyword('there-is-a-discount')?keyword('there-is-a-discount'):"Discount"}៖
                                            {
                                                this.state.disCount.type===1?
                                                <span>- {this.state.disCount.varDis}%</span>:
                                                <span>- $ {this.state.disCount.varDis>0?amount(this.state.disCount.varDis):"0"} </span>
                                            }
                                        </h6>
                                    }
                                    <h5>
                                        {keyword('amount-price')?keyword('amount-price'):"Total"}៖ 
                                            {
                                                this.state.addressShippingPrice && this.state.Provice==="" ?
                                                    <span id="bad_this_total">{keyword('choose-an-address')?keyword('choose-an-address'):"Choose an address"}</span>
                                                :
                                                this.state.disCount.type===1?
                                                    <span>
                                                        <span id="bad_this_total">$ { (total+this.state.addressShippingPrice)>0?amount(total+this.state.addressShippingPrice):"0"}</span>
                                                        <br/>
                                                        $ {((total+this.state.addressShippingPrice)-(((total+this.state.addressShippingPrice)*this.state.disCount.varDis)/100))>0?amount(((total+this.state.addressShippingPrice)-(((total+this.state.addressShippingPrice)*this.state.disCount.varDis)/100))):"0"} 
                                                    </span>
                                                :
                                                this.state.disCount.type===2?
                                                    <span>
                                                        <span id="bad_this_total">$ {(total+this.state.addressShippingPrice)?amount((total+this.state.addressShippingPrice)):"0"} </span>
                                                        <br/>
                                                       $ {((total+this.state.addressShippingPrice)-(this.state.disCount.varDis))?amount(((total+this.state.addressShippingPrice)-(this.state.disCount.varDis))):"0"} 
                                                    </span>
                                                :
                                                    <span>
                                                        $ {(total+this.state.addressShippingPrice)?amount((total+this.state.addressShippingPrice)):"0"}
                                                    </span>
                                            }
                                                
                                    </h5>
                                </Col>
                            </Row>
                        </Modal.Body>
                    <div>
                        {
                            this.state.addressShippingPrice && this.state.Provice==="" ?
                            this._navBuy(total,bgP,colP)
                            :
                            this.state.disCount.type===1?
                            this._navBuy((total)-(((total+this.state.addressShippingPrice)*this.state.disCount.varDis)/100),bgP,colP)
                            :
                            this.state.disCount.type===2?
                            this._navBuy((total+this.state.addressShippingPrice)-(this.state.disCount.varDis),bgP,colP)
                            :
                            this._navBuy(total,bgL,colL)
                        }
                        
                    </div>
                </Modal>
                
                <ComfirmPin
                    dataCheckOut={dataCart}
                    total={ 
                            this.state.addressShippingPrice && this.state.Provice!=""?
                            this.state.disCount.type===1 ?
                            (total+this.state.addressShippingPrice)-(((total+this.state.addressShippingPrice)*this.state.disCount.varDis)/100):
                            this.state.disCount.type===2?
                            (total+this.state.addressShippingPrice)-(this.state.disCount.varDis)
                            :
                            total+this.state.addressShippingPrice:total}
                    show={this.state.comfrimPin}
                    onHide={()=>{this.setState({comfrimPin:false})}}
                />
                <AddAddress 
                    onShow={this.state.addAddress} 
                    onHide={()=>{this.setState({addAddress:false})}} 
                />
                <NewPin
                    onShow={this.state.inputPin} 
                    onHide={()=>{this.setState({inputPin:false})}} 
                />
                <Dailog 
                show={this.state.ui.errorPhoneNumber} 
                onHide={() => {this.setState({ui:{...this.state.ui,errorPhoneNumber:false},comfrimPin:false})}}
                label={this.state.ui.messageErrorPhoneNumser}
                /> 
                <Modal
                    animation={true}
                    show={this.state.ui.inputCodePromo} 
                    size="md"
                    style={{backgroundColor:'gray'}}
                    className="modal_personal_role"
                    centered
                    >
                    <Modal.Header >
                    <h1 className="h1_showTitle_code_promote">{keyword('enter-discount-card-number')?keyword('enter-discount-card-number'):"Input discount cart"}</h1>
                    <CloseOutlined id="icon_close_promote_code" onClick={()=>{this._handleUi('inputCodePromo',false)}}/>
                    </Modal.Header>
                    <Modal.Body style={{textAlign:'center'}}>
                        <img src={GIF.present} className="img_present_input_promote_code"/>
                        <br/>
                        <input placeholder="code" id="input_code_promote_" 
                            onChange={(value, index) => {
                                this.setState({
                                      valueCodeDis:value.target.value
                                })
                            }} 
                        ></input>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        <button id="btnHide" onClick={()=>{this._verifyCodePromo()}}> {keyword('ok')?keyword('ok'):"ok"} </button>
                    </Modal.Footer>
                </Modal>
               
                <Modal
                    show={this.state.ui.alreadyBuy} 
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="modal_buyAready"
                    >
                    <Modal.Body style={{textAlign:'center'}}>
                    <img src={GIF.thank} id="image_gif_done_added_tocart"/>
                    <br/>
                    <h1>{keyword('word-for-comfirm-buy-successfully-purchased')?keyword('word-for-comfirm-buy-successfully-purchased'):"Order Successfully"}</h1>
                    <h1>Thank you!</h1>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        <button id="closeBuyAlread" onClick={()=>{this._handle_close_buy()}}>
                            <span>{keyword('ok')?keyword('ok'):"Ok"}</span>
                        </button>
                    
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
