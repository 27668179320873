import React, { Component } from 'react';

export default class Helper extends Component {
  render() {
    return (
      <div style={{textAlign:'center'}}> 
          <h1>Heplers</h1>
      </div>
    );
  }
}
