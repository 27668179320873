import { call, put, takeLatest } from "redux-saga/effects";
import { error, success } from "redux-saga-requests";
import {
    TEST,
    GET_NAME,
    APP_REFRESH,
    APP_WILL_REFRESH,
    CHANGE_LANGUAGE,
    CHENGE_TITLE
} from "./reducer";
import axiosDefault from "axios";
import {API_URL} from "./config";
import {normalize} from "./../../utils/normalize"
import {store} from '../../store'
const createHistory = require("history").createBrowserHistory
const baseURL = API_URL;
export const axios = axiosDefault.create({
    baseURL,
    headers:{
      'Content-Type': 'application/json; charset=utf-8'
    }
  });

axios.interceptors.request.use(function (config) {
    var cookie = store.getState(); 
    if(config.data){
        if(config.headers['Content-Type']=='multipart/form-data'){
            config.data.append('lang' ,cookie.app.lang)
        }
        else{
        config.data = {...config.data,lang : cookie.app.lang}
        config.params = {lang : cookie.app.lang}
        }  
    }
    else{
        // config.data = {lang : cookie.app.lang}
        config.params = {lang : cookie.app.lang}
    }
    return config;
  }, function (error) {
    // Do something with request error
    console.log(error)
    return Promise.reject(error);
  });

axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        try {
        if (
            error.response &&
            error.response.status === 401 
        ) {
            window.location.assign('/login');
        }     
        return Promise.reject(error.response);
        } catch (e) {
        return Promise.reject(e);
        }
    }
);

export function* fetchTestData() {
    try {
        const testData = yield call(axios.get, "/metadata/countries");
        yield put({
            type: success(TEST),
            payload: {
                testData: normalize(testData)
            }
        });
    } catch (e) {
        yield put({
            type: error(TEST),
            payload:{
                errorMessage: 'Server error'
            }
        });
    }
}

export function* getNameWorker(payload) {
    try {
        yield put({
            type: success(GET_NAME),
            payload: {
                user:payload.payload
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_NAME)
        });
    }
}

export function* appRefreshWorker() {
    try {
        
        var cookie = store.getState();
        if(cookie.user.login && cookie.user.login.token){
            yield (axios.defaults.headers.common = {
                    Authorization: `Bearer ${cookie.user.login.token}`,
                });
        }
        yield put({
            type: success(APP_REFRESH),
        });
    } catch (e) {
        yield put({
            type: error(APP_REFRESH)
        });
    }
}
export function* changeLanguage({payload}) {
    try {
        yield (axios.defaults.headers.common = {
            lang: payload,
        });
        yield put({
            type: success(CHANGE_LANGUAGE),
            payload:{lang:payload}
        });
        setTimeout(() => {
            window.location.reload()
          }, 1000);
    } catch (e) {
        yield put({
            type: error(CHANGE_LANGUAGE),
            payload:{lang:'kh'}
        });
    }
}
export function* appWillRefreshWorker() {
    try {
        yield put({
            type: success(APP_WILL_REFRESH),
        });
    } catch (e) {
        yield put({
            type: error(APP_WILL_REFRESH)
        });
    }
}
export function* changeTitle(){
    try {
    var payload=store.getState().app['lang']?store.getState().app['lang']:"en";
    const data_title = yield call(axios.get,"/get_title/"+payload);
    // console.log("-------",normalize(data_title))
    yield put({
        type: success(CHENGE_TITLE),
        payload: {
        data_title: normalize(data_title),
        },
    });
    } catch (e) {
    yield put({
        type: error(CHENGE_TITLE),
        payload: {
        errorGetTitleKeyword: e,
        },
    });
    }

}

export function* appSaga() {
    yield takeLatest(TEST, fetchTestData);
    yield takeLatest(GET_NAME, getNameWorker);
    yield takeLatest(APP_REFRESH, appRefreshWorker);
    yield takeLatest(APP_WILL_REFRESH, appWillRefreshWorker);
    yield takeLatest(CHANGE_LANGUAGE, changeLanguage);
    yield takeLatest(CHENGE_TITLE, changeTitle);
}