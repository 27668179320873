import React, { Component } from 'react'
import Navigations from '../containers/navigationContainer'
import {Row,Container} from 'react-bootstrap'
import {IMAGES,NOTIMAGE} from '../Assets/images'
import {LOGO_IMAGE_URL} from '../modules/app/config'
import {store} from '../store/index'
import '../Style/about.css'
export default class AboutUs extends Component {
    render() {
        var info=store.getState().productData;
        return (
            <div>
                <Navigations/>
                <Container>
                    <div className="about_page">
                        <img src={info['sitedescription'] && info['sitedescription']['results'] && info['sitedescription']['results'][0]?LOGO_IMAGE_URL+info['sitedescription']['results'][0]['logo']:NOTIMAGE } id="logo_about_page"/>
                        <p>
                        Kuanghsien is the leading online shopping app in Cambodia.
                              kuanghsien is committed to doing his best for the enterprise
                              Small and medium business
                              Access to leading online technology
                              And even lower costs, higher revenues.
                              kuanghsien loves Khmer, wants Khmer to prosper
                              I hope that all young Cambodians participate in building and developing the online sector in our country to grow as in neighboring countries.
                        </p>
                        <h6>
                            <span>
                                Kuanghsien
                            </span>
                        </h6>
                    </div>
                </Container>
            </div>
        )
    }
}
