import React, { Component } from 'react'
import Navigations from '../containers/navigationContainer'
import {Row,Container,Modal, Col} from 'react-bootstrap';
import { RemoveCircleOutline,ErrorOutline, ImageRounded} from '@material-ui/icons';
import ComfirmBuy from '../containers/compornentComfirmBuy';
import { Link } from 'react-router-dom';
import '../Style/AddToCart.css'
import '../Style/Component/globleContainer.css'
import '../Assets/color.css'
import {GIF} from '../Assets/images'
import Loading from '../Component/loading'
import {PROUDCT_THUMBNAIL_URL_IMAGE,STORE_URL_IMAGE} from '../modules/app/config';
import {setColor} from '../utils/colors';
import {keyword} from '../utils/keywords';
import amount from '../utils/fomartAmount'
let total = 0;
export default class AddToCard extends Component {
    constructor(props){
        super(props);
        this.state={
            number:1,
            noProductBuy:false,
            _dataGetCart:[],
            loadingDelete:false,
            dataAddress:null,
            dataLogin:null,
            Cart:{dataCart:[]},
            _cart:[],
            noDataProduct:false,
            allItemCart: [],
            modalDelete:false,
            dataDelete:null,
            modalOverStock:false,
            emtyCarts:false,
            comfirmbuy:false,
            count:1,
            ui:{
                alreadyBuy:false
            },
            Loading:false
        }
       
    }   

    componentDidMount(){
        this._loadingLegleg(); 
        this.props.getCartList();
        
        }
    
    componentWillUnmount(){
        var all=this.state.allItemCart;
        for(let i=0;i<all.length;i++){
            let data1={
                    "amount":all[i].qty,
                    "id":all[i].ID
                }
            this.props.getAmountDiscount(data1);
        }
    }
    componentWillReceiveProps(nextProps){
        const {cartList,buyAlready,delete_Cart,err_cartList,dataAddCart,get_amountDiscount} = this.props;
        if(nextProps.cartList && nextProps.cartList != cartList){
            this.setState({
                loadingDelete:false,
                noDataProduct:false, 
                allItemCart:nextProps.cartList.results,
            })
            
            if(nextProps.cartList.results.length>0){
                for(let i=0;i<nextProps.cartList.results.length;i++){
                    this._handleDiscount(nextProps.cartList.results[i]['ID'],nextProps.cartList.results[i]['qty'])
                }
            }
            const groupBy= (array,key)=>{
                return array.reduce((result,currentValue) => {
                    (result[currentValue[key]] = result[currentValue[key]] || []).push(
                        currentValue
                    );
                     // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                    return result;
                },{});
            };
            var data = nextProps.cartList.results;
            data.sort((a,b)=>(a.dateTime>b.dateTime)?-1:1)
            const storeGroupedBystoreAlias = groupBy(data, 'storeAlias');
            var store = Object.keys(storeGroupedBystoreAlias);
            var dataStore = store.map(function(i) {
                return [i, storeGroupedBystoreAlias[i]];
            });
           
            this.setState({
                _dataGetCart:dataStore,
                emtyCarts:true
            });
        }
        
        if(nextProps.delete_Cart && nextProps.delete_Cart!= delete_Cart){
            if(nextProps.delete_Cart.message === 'success'){
                this.props.getCartList();
                this.setState({loadingDelete:false, _cart:[]})
                total = 0
            }
        }
        if(nextProps.err_cartList && nextProps.err_cartList != err_cartList){
            if(nextProps.err_cartList.data.message==='cart_is_empty'){
                this.setState({_dataGetCart:[]})
            }
        }
        if(nextProps.dataAddCart && nextProps.dataAddCart!=dataAddCart){
            if(nextProps.dataAddCart.message === 'success'){
                this.setState({ _cart:[]})
                total = 0;
                this.props.getCartList();
            }
        }
        if(nextProps.buyAlready && nextProps.buyAlready!=buyAlready){
            if(nextProps.buyAlready.message==='success'){
                this.props.getCartList();
                this.setState({
                    comfrimPin:false,
                    ui:{...this.state.ui,alreadyBuy:true}
                });
                this.state._cart.map((storeAlais)=>{
                    const data_send_notification = {
                        user:storeAlais['storeUserName'],
                        type: "seller",
                        data: { type: 'user_like' },
                        message: "Your item has been ordered, please release your item!"
                    }
                    this.props.setNotification(data_send_notification);
                })
            }
        }
        
    }
    _loadingLegleg(){
        this.setState({
          Loading:true
        })
        setTimeout(() => {
          this.setState({
            Loading:false
          })
        }, 5000);
    }
    select(e){  
        if(this.state._cart.length == 0){
            this.setState({_cart:[...this.state._cart, e]})
            total += e.total
         }else{
             var dataCart = this.state._cart;
             const index = dataCart.findIndex(obj => obj.alias === e.alias && obj.ID === e.ID)
             if(index >= 0){
                 // uncheck splice
                 var newCart = [];
                 dataCart.map((item, i) => {
                     if(i != index){
                         newCart.push(item)
                     }
                 })
                 this.setState({
                     _cart: newCart
                 })
                 total -= e.qty * e.price
             }else{
                 // check add
                 this.setState({_cart:[...this.state._cart,e]})
                 total += e.qty * e.price 
             }
        }
    }
    selectAll(){
        const {_cart, allItemCart} = this.state;
        if(_cart.length == allItemCart.length){
            //unchecked
            total = 0
            this.setState({
                _cart:[]
            })
        }else{
            const value = allItemCart.filter((item)=>item.currenStock>0);
            this.setState({
                _cart:value
            })   
            total = 0
            value.map((e,i) => {
                total += e.total
            })
        }
    }

    _handleGateData(key,value){
        this.setState({
            gateData:{...this.state.gateData,[key]:value}
        })
    }
    _deleteItem=(id,alias)=>{
        const data = {
            skuAlias:alias,
            ID:id
        }
        this.setState({modalDelete:true,dataDelete:data})
        
    }
    _comfirmDeleteCart(){
        this.props.deleteCart(this.state.dataDelete);
        this.setState({modalDelete:false})
        
    }
    plus = (id,alias,q,stock) => {
        var Qty = q
        let data={
            "amount":Qty,
            "id":id
        }
        this.props.getAmountDiscount(data);
        if(Qty <= stock){
            const dataCart ={"dataCart":[
                {ID:id,
                qty:Qty,
                skuAlias:alias}
            ]}
            this.props.addCart(dataCart);
            
        }
        else{
            this.setState({modalOverStock:true})
        }
        
    }


    minus = (id,alias,q) => {
        let Qty = q;
        let data={
            "amount":Qty,
            "id":id
        }
        this.props.getAmountDiscount(data);
        if(Qty>=1){
            const dataCart ={"dataCart":[
                {ID:id,
                qty:Qty,
                skuAlias:alias}
            ]}
            this.props.addCart(dataCart);
        }
    }
    
    _buynow(){
        if(this.state._cart.length > 0){
            try{
                this.setState({comfirmbuy:true});
            }
            catch(e){
            
            }
           
        }else{
            this.setState({noProductBuy:true})
        }
    }
    
    _navBuy(){
        let bgP=setColor('darkBackground');
        let colP=setColor('darkColor');
        return(
           
                <div className="navBuyTotal">
                     <Container>
                    <div className="navBuyTotal_1">
                        <div className="checkAll" onChange={()=> this.selectAll()}>
                            <input type="checkbox"  defaultChecked={this.state._cart.length === this.state.allItemCart.length ? true:false}/>
                            <span>{keyword('select-all')?keyword('select-all'):"Select all"}</span>
                        </div>
                        <div className="showTotal">
                        <h1>
                                {keyword('amount-price')?keyword('amount-price'):"Amount Price"}៖
                                $
                                <span className="thistotal">
                                    {total>0?amount(total):0}
                                </span>
                                
                        </h1>
                        </div>
                        <div className="control_topay">
                        <button style={{backgroundColor:bgP,color:colP}} id="toPay" onClick={()=>{this._buynow()}}>
                            {keyword('buy')?keyword('buy'):"Buy"}
                        </button>
                    </div>
                    </div>
                    </Container>
                </div>
        )
    }
    _handle_close_buy(){
        this.setState({
            modalDelete:false,
            comfirmbuy:false,
            ui:{
                ...this.state.ui,
                alreadyBuy:false
            }
        })
    }
    _handleDiscount(ID,qty){
        var data={
            "amount": qty,
            "id": ID
        }
        this.props.getAmountDiscount(data)
    }
    render() {
        let bgP=setColor('darkBackground');
        let colP=setColor('darkColor');
        let bgL=setColor('lightBackground');
        let colL=setColor('lightColor')
        var dataCartlist= this.state._dataGetCart.map((Onestore,i)=>{      
           var store = Onestore[1].map((e,j)=>{
              var checkedOt = false; 
               const check = this.state._cart.findIndex(obj => obj.alias === e.alias && obj.ID === e.ID)
                if(check >= 0){
                    checkedOt = true;
                }   
                return(
                    <div key={j} className={e['currenStock']===0 ? "oneCart_disable":""}>
                        <Row id="oneProdInStore">
                            <input type="checkbox" style={{color:bgP}} className="checkboxProd" checked={checkedOt} onChange={()=>this.select(e)}></input>
                            <Col lg={2} md={2} sm={6} style={{padding:0}}>
                                <div className="image_prod_add_to_cart">
                                    {
                                        e['productDiscount']>0?
                                        <p>-{e['productDiscount']}%</p>
                                        :
                                        null
                                    }
                                    <img src={PROUDCT_THUMBNAIL_URL_IMAGE + e['image']} className="imagePro"/>
                                    
                                </div>
                                {
                                        e['currenStock']===0 && <div className="lable_cart_no_store">{keyword('not-in-stock')?keyword('not-in-stock'):"Product out stock!"}</div>
                                }
                            </Col>
                            <Col lg={4} md={4} sm={2}>
                                <div className="detailProd">
                                    <h1>{e['name']}</h1>
                                    <h2>{keyword('price')?keyword('price'):"Price"}៖ $ {e['price']>0?amount(e['price']):"0"}</h2>
                                    <h3>{e['alias']}</h3>
                                </div>
                            </Col>
                            <Col lg={3} md={3} sm={2} className="count">
                                <Row>
                                    {
                                        e.qty===1? <button disabled className="button_dis_count"></button> :
                                        <button style={{backgroundColor:bgP,color:colP}} onClick={()=>this.minus(e.ID,e.alias,e.qty-1)}>-</button>
                                    }
                                        <input id="Countinput" value={e.qty} readOnly="readOnly"></input>
                                    {
                                        e.qty>=e.currenStock?
                                        <button disabled className="button_dis_count"></button> :
                                        <button  style={{backgroundColor:bgP,color:colP}} onClick={()=>this.plus(e.ID,e.alias,e.qty+1,e.currenStock)}>+</button>
                                    }
                                </Row>
                            </Col>
                            <Col lg={2} md={2} sm={2} className="totalPriceProduct">
                                <div className="total_amout_and_dis_amount_persent">
                                    <h1>{keyword('amount-price')?keyword('amount-price'):"Amount Price"}</h1>
                                    {
                                        e['persentDis']>0?
                                        <div>
                                            <h2>$ {e['total']>0?amount(e['total']):"0"}</h2>
                                        <p>$ {e['defualTotal']>0?amount(e['defualTotal']):"0"} <span>បញ្ចុះលើចំនួន -{e['persentDis']}%</span></p>
                                            
                                        </div>
                                        :
                                        <div>
                                             <h2>$ {e['total']>0? amount(e['total']):"0"}</h2>
                                        </div>
                                    }
                                    
                                </div>
                            </Col>
                            <div>
                                <Row>
                                    {
                                        <button id="btnRemove" onClick={()=>{this._deleteItem(e['ID'],e['alias'])}}>
                                            <RemoveCircleOutline id="RemoveCircleOutline"/>
                                        </button>
                                    }
                                </Row>
                            </div>
                        </Row>
                        <Row className="oneCartSHOW_Small">
                        <input type="checkbox" className="checkboxProd" checked={checkedOt} onChange={()=>this.select(e)}></input>
                            <Col md={3} sm={4} xs={4} style={{padding:0}}>
                                <div className="image_prod_add_to_cart">
                                    {
                                        e['productDiscount']>0?
                                        <p>-{e['productDiscount']}%</p>
                                        :
                                        null
                                    }
                                    <img src={PROUDCT_THUMBNAIL_URL_IMAGE + e['image']} className="imagePro"/>
                                    
                                </div>
                                {
                                        e['currenStock']===0 && <div className="lable_cart_no_store">{keyword('not-in-stock')?keyword('not-in-stock'):"Product out stock!"}</div>
                                }
                            </Col>
                            <Col md={9} sm={8} xs={8}>
                                <div className="detailProd">
                                    <h1>{e['name']}</h1>
                                    <h2>តម្លៃ៖$ {e['price']>0?amount(e['price']):"0"} </h2>
                                    <h3>{e['alias']}</h3>
                                </div>
                            </Col>
                            <Col  sm={8} xs={7} className="totalPriceProduct">
                                <div className="total_amout_and_dis_amount_persent">
                                    {
                                        e['persentDis']>0?
                                        <div>
                                            <h1>{keyword('price')?keyword('price'):"Price"}៖</h1>
                                            <h2 id="dis_small_show">$ {e['defualTotal']>0?amount(e['defualTotal']):"0"} <span>បញ្ចុះលើចំនួន -{e['persentDis']}%</span></h2>
                                        </div>:
                                        null
                                    }
                                    <h1>{keyword('amount-price')?keyword('amount-price'):"Amount price"}៖ </h1>
                                    {
                                        <div>
                                             <h2>$ {e['total']>0?amount(e['total']):"0"}</h2>
                                        </div>
                                    }
                                    
                                </div>
                            </Col>
                            <Col  sm={4} xs={5} className="count" >
                                <Row>
                                    {
                                        e.qty===1? <button disabled className="button_dis_count"></button> :
                                        <button style={{backgroundColor:bgP,color:colP}} onClick={()=>this.minus(e.ID,e.alias,e.qty-1)}>-</button>
                                    }
                                        <input id="Countinput" value={e.qty} readOnly="readOnly"></input>
                                    {
                                        e.qty>=e.currenStock?
                                        <button disabled className="button_dis_count"></button> :
                                        <button style={{backgroundColor:bgP,color:colP}} onClick={()=>this.plus(e.ID,e.alias,e.qty+1,e.currenStock)}>+</button>
                                    }
                                </Row>
                            </Col>
                           
                            <div>
                                <Row>
                                    {
                                        <button id="btnRemove" onClick={()=>{this._deleteItem(e['ID'],e['alias'])}}>
                                            <RemoveCircleOutline id="RemoveCircleOutline"/>
                                        </button>
                                    }
                                </Row>
                            </div>
                        </Row>
                    </div>
                )
            });

            return(
             
                <div className="oneCart" key={i} >
                   <Link to={{pathname: "/detailShop/"+Onestore['0']}}>
                        <Row className="headerShop" >
                            <img src={STORE_URL_IMAGE+Onestore[1][0].storeImage} id="logoStore"/>
                            <h1>{Onestore[1][0].storeName }</h1>
                        </Row>
                    </Link>
                    <div className="detailProdImage">
                        {store}
                    </div>
                   
                </div>
            )
            
        })


        return (
            <div className="cart">
                {
                    this.state.Loading===true &&<Loading/>
                }
                <Navigations/>
                <Container>
                    <div className="div_show_cart_ss">
                        {dataCartlist}  
                    </div>
                </Container>
               <Container>
                   {
                       this.state._dataGetCart.length!=0?
                       this._navBuy()
                       :
                       <div className="emtyCart___">
                           <img src={GIF.cartEmty}/>
                           <br/>
                           <h1>
                                {keyword("dear-you-don't-have-product")?keyword("dear-you-don't-have-product"):"Favorite Not Available"}
                           </h1>
                           <p>
                              {keyword('no-matter-how-busy-you-are,-you-have-to-shop-for-yourself')?keyword('no-matter-how-busy-you-are,-you-have-to-shop-for-yourself'):"Even if you have to buy things for yourself"}
                           </p>
                       </div>
                   }
                  
               </Container>
                <ComfirmBuy 
                    onShow={this.state.comfirmbuy} 
                    onHide={()=>{this.setState({comfirmbuy:false})}} 
                    dataCart={this.state._cart}
                    total={total}
                />
                <Modal
                    show={this.state.modalDelete} 
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="modelDeleteCart"
                    >
                    <Modal.Body style={{textAlign:'center'}}>
                    <ErrorOutline style={{fontSize:100}}/>
                    <br/>
                    <h1>{keyword('do-you-really-want-to-delete-this-item')?keyword('do-you-really-want-to-delete-this-item'):"Do you really want to delete this item?"}</h1>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        <button id="btnHide" onClick={()=>{this._comfirmDeleteCart()}} >{keyword('ok')?keyword('ok'):"OK"}</button>
                        <button id="btnHide" onClick={()=>{this.setState({modalDelete:false})}}><span>{keyword('cancel')?keyword('cancel'):"Cancel"}</span></button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.modalOverStock} 
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="modelDeleteCart"
                    >
                    <Modal.Body style={{textAlign:'center'}}>
                    <ErrorOutline style={{fontSize:100}}/>
                    <br/>
                    <h1>{keyword('not-in-stock')?keyword('not-in-stock'):"Product out stock"}</h1>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        <button id="btnHide" onClick={()=>{this.setState({modalOverStock:false})}}> {keyword('ok')?keyword('ok'):"OK"} </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.noProductBuy} 
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="modelDeleteCart"
                    >
                    <Modal.Body style={{textAlign:'center'}}>
                    <ErrorOutline style={{fontSize:100}}/>
                    <br/>
                    <h1>{keyword('there-are-no-items-to-choose-from')?keyword('there-are-no-items-to-choose-from'):"The item you selected is not available."} </h1>
                    <h1>{keyword('please-select-the-item-you-want-to-buy')?keyword('please-select-the-item-you-want-to-buy'):"Please select the item you want to buy"}</h1>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        <button id="btnHide" onClick={()=>{this.setState({noProductBuy:false})}}> {keyword('ok')?keyword('ok'):"OK"} </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.ui.alreadyBuy} 
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="modal_buyAready"
                    >
                    <Modal.Body style={{textAlign:'center'}}>
                    <img src={GIF.thank} id="image_gif_done_added_tocart"/>
                    <br/>
                    <h1>{keyword('word-for-comfirm-buy-successfully-purchased')?keyword('word-for-comfirm-buy-successfully-purchased'):"Buy success"}</h1>
                    <h1>Thank you!</h1>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        <button id="closeBuyAlread" onClick={()=>{this._handle_close_buy()}}>
                            <span>{keyword('ok')?keyword('ok'):"OK"} </span>
                        </button>
                    
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
          