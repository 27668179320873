import io from 'socket.io-client';
import {CHAT_URL} from '../modules/app/config'
import {
    onReceiveMessage, getOwnSocket, getUserMessageList, getOldMessages, updatePartnerSeen
} from '../modules/chat/reducer'

const setupSocket = (dispatch, payload) => {
    // let socket = false;
    // console.log('setupSocket', payload);
    // if(payload !== false){
    //     socket = io(CHAT_URL, { query: "id="+payload+"&type=member" })

    //     socket.on('server_send', result => {
    //         console.log('server_send', result);
    //         dispatch(onReceiveMessage(result));
    //     })

    //     socket.on('connect_success', result => {
    //         console.log('connect_success', result);
    //         dispatch(getOwnSocket(result))
    //     })

    //     socket.on('connect_fail', result => {
    //         console.log('connect_fail', result);
    //     })

    //     socket.on('user_list', result => {
    //         console.log('user_list', result);
    //         dispatch(getUserMessageList(result))
    //     })

    //     socket.on('old_message', result => {
    //         console.log('old_message', result);
    //         dispatch(getOldMessages(result))
    //     })
    //     socket.on('seen', result => {
    //         console.log('seen', result);
    //         dispatch(updatePartnerSeen(result))
    //     })
    // }
	// return socket
}

export default setupSocket