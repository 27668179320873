import { connect } from "react-redux";
import OrderHistory from "../Component/order_history"
import {get_orderHistory,comfirmOrderHistoryRecevice,setNotification} from '../modules/user/reducer'
const mapStateToProps = state => ({
  ...state,
  error_get_orderHistory:state.user.error_get_orderHistory
});

const mapDispatchToProps = {
    get_orderHistory,
    comfirmOrderHistoryRecevice,
    setNotification
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderHistory);
