import React, { Component } from 'react'
import '../Style/Component/order_history.css'
import '../Assets/color.css'
import {Row,Col,Container, Nav,Modal, Table} from 'react-bootstrap';
import {STORE_URL_IMAGE,PROUDCT_THUMBNAIL_URL_IMAGE} from '../modules/app/config'
import moment from 'moment';
import 'moment/locale/km';
import {GIF,LOGO} from '../Assets/images'
import { Link} from 'react-router-dom';
import {Digital} from 'react-activity';
import { keyword } from '../utils/keywords';

export default class order_history extends Component {
    constructor(props){
        super(props);
        this.state={
            dataOrdered:[],
            fullName:'',
            oderIDComfirm:'',
            data:{
                userFullName:''
            }
           
        }
    }
    componentDidMount(){
        try{
            var dataUser=JSON.parse(localStorage.getItem('persist:amatakWebsite'));
            var JsonDATA=JSON.parse(dataUser['user'])['login']['data'];
            this.setState({
                data:{...this.state.data,
                    userFullName:JsonDATA['fullName']
                }
            });
        }catch(e){

        }
    }
    componentWillReceiveProps(nextProps){
        const {user,error_get_orderHistory}=this.props;
        if(nextProps.error_get_orderHistory && nextProps.error_get_orderHistory !=error_get_orderHistory){
            this.setState({dataOrdered:[]})
        }
        if(nextProps.user.get_orderHistory && nextProps.user.get_orderHistory !=user.get_orderHistory){
            if(nextProps.user.get_orderHistory.message==='success'){
                const data = nextProps.user.get_orderHistory.results;
               
                data.sort((a, b) => (a.dateTime > b.dateTime) ? -1 : 1);
                var mainFilter = "groupID";
                var secFilter = "storeAlias";
                var result = data.reduce((map, obj) => {

                    if (!map[obj[mainFilter]]) map[obj[mainFilter]] = {};

                    [].concat(obj[secFilter]).forEach(subEl => {

                        if (!map[obj[mainFilter]][subEl]) map[obj[mainFilter]][subEl] = [];

                        map[obj[mainFilter]][subEl].push(obj);

                    });

                    return map;

                }, {});
                
                const data_ = Object.keys(result);
                const store_ = data_.map(function (i) {
                    return [i, result[i]]
                })
                var r = store_.map((a) => {
                    const key = Object.keys(a[1])
                    const data = key.map(function (i) {
                        return [i, a[1][i]]
                    })
                    return data
                })
                let dataHistories = []
                data_.map((a, i) => {
                    dataHistories.push([a, r[i]])
                })
                this.setState({ dataOrdered: dataHistories})
            }
        }
        if(nextProps.user.order_history_receive && nextProps.user.order_history_receive!=user.order_history_receive ){
            if(nextProps.user.order_history_receive.message==='success'){
                this.props.get_orderHistory();
            }
        }

    }
    _userRecieveProduct = (a,keyIndex) => {
        var a = keyword('word-for-message-customer-recieved-product')?keyword('word-for-message-customer-recieved-product'):"_name_ ecieved-product";
        var newa=a.replace("_name_", this.state.data.userFullName);
        const send_notification = {
            user:a.storeUserName,
            type:"seller",
            data:{type:"order_list"},
            message:newa
        }
        this.props.setNotification(send_notification)
        const data = {
            orderID: a.orderID,
            storeAlias:a.storeAlias,
            groupID:a.groupID,
            fullName:this.state.fullName
        }
        this.props.comfirmOrderHistoryRecevice(data);
        this.setState({
            oderIDComfirm:keyIndex
        })
     
    }
    render() {
        const {onShow,onHide} =this.props;
        

        let productOrdered = this.state.dataOrdered.map((a, i) => {
            let group_store = a[1].map((e, j) => {
                let product = e[1].map((v, k) => {
                    return (
                        <div key={k}  className="one_product_instore">
                                {
                                    v.status === 0?
                                    (
                                        <div className="status_waiting">
                                            {keyword('ordering')?keyword('ordering'):"Ordering"}<img id="git_waiting" src={GIF.waiting}/>
                                        </div>
                                    )
                                    : v.status === 1 ? 
                                    (
                                        <div className="status_waiting">
                                           {keyword('product-in-transit')?keyword('product-in-transit'):"Delivering"}<img id="git_delivery" src={GIF.delivery}/>
                                        </div>    
                                    ) 
                                    :
                                    v.status === 2 ? 
                                    (
                                        <div className="status_waiting">
                                            {keyword('order-completed')?keyword('order-completed'):"Order completed"}<img id="git_already" src={GIF.already}/>
                                        </div>    
                                    ) 
                                    : null
                                }
                            <div className="text_ledd">
                            <h5 id="h5_code_product">{keyword('product-id')?keyword('product-id'):"Product ID"}៖ {v.orderID}</h5>
                            <h6 id="h6_date_buyer">{moment(v.dateTime).format('dddd,DD/MMMM/YYYY')}</h6>
                            
                            </div>
                            <div className="detail_product_history">
                                <div >
                                    <img id="image_product_in_history" src={ PROUDCT_THUMBNAIL_URL_IMAGE + v.dataProduct.image } />
                                </div>
                                <div className="writen_detail_product">
                                    <h5>{v.dataProduct.name}</h5>
                                    <h5 >{v.dataProduct.SKUAlias}</h5>
                                    <h5 >{keyword('amount-product')?keyword('amount-product'):"QTY"}៖ <span style={{ color: 'black' }}>{v.qty}</span></h5>
                                    <h5 >{keyword('price')?keyword('price'):"Price"}៖ <span style={{ color: 'red' }}>$ {(parseInt(v.dataProduct.price))}</span></h5>
                                </div>
                            </div>
                            <div className="control_button_3_status">
                                {v.status === 1 ? (
                                    <button onClick={() => this._userRecieveProduct(v,k)} id="btn_get_done_porduct">
                                        <h5 style={{ color: 'blue' }}>{keyword('received-product')?keyword('received-product'):"Received"}</h5>
                                    </button>) : v.status === 2 ? (
                                        <div className="row_tow_btn">
                                            <Link to="/homepage#prd_foryou">
                                                <button id="btn_buy_agile">
                                                    <p>{keyword('ok')?keyword('ok'):"Ok"}</p>
                                                </button>
                                            </Link>
                                        </div>
                                    ) : null}
                            </div>
                        </div>
                    )
                })
                return (
                    
                <div key={j}  className="div_control_profile_store">
                    <div className="background_profile_store">
                        <img id="image_one_store_in_history" src={STORE_URL_IMAGE+e[1][0].storeImage} />
                        <h5 id="h5_name_store">{e[1][0].storeName}</h5>
                    </div>
                    {product}
                    
                </div>

                )
            })
            return (
                <Col  lg={4} md={6} sm={6} xs={12} key={i} className="one_history_prof">
                    <div className="hearder__order_history">
                            <h5 id="h5_code_buyer"> {keyword('order-id')?keyword('order-id'):"Order ID"}: {a[0]}</h5>
                            <h6 id="h6_date_buyer">{moment(a[1][0][1][0].dateTime).format('dddd,DD/MMMM/YYYY H:mma')}</h6>
                        </div>
                        {group_store}
                </Col>
            )
        })
        return (
            <div >
                {
                    this.props.user.loading ?
                    <div className="loadingSmall_order_history">
                        <Digital color="#727981" size={20} speed={1} animating={true}  />
                    </div>
                    :
                    // >
                    <Row>
                    {
                        this.state.dataOrdered.length===0?null :productOrdered
                    }
                    </Row>
                    
                // </Col>
                }
            </div>
                
                  
        )
    }
}
