import { connect } from "react-redux";
import Oneprouct from "../Component/oneProduct"
import {
    productSKU,
    getProductPriceSKU,
    userLikeShare
    
  } from '../modules/product/reducer'

const mapStateToProps = state => ({
  ...state,
  
});

const mapDispatchToProps = {
    productSKU,
    getProductPriceSKU,
    userLikeShare
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Oneprouct);
