import { connect } from "react-redux";
import Navigataion from "../Component/navigationsNew"
import {getCartList} from '../modules/user/reducer'
import {appChangeLanguage} from '../modules/app/reducer'
import {getPorductCategory,getColor,getSiteDescription} from '../modules/product/reducer'
const mapStateToProps = state => ({
  ...state,
  cartList:state.user.cartList,
  err_cartList:state.user.err_cartList
});

const mapDispatchToProps = {
  getCartList,
  getPorductCategory,
  getSiteDescription,
  getColor,
  appChangeLanguage
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigataion);
