import React, { Component } from 'react'
import '../Style/filterCartegory.css'
import OneProduct from '../containers/compornentOneProduct'
import {Tab,Row,Col,Nav,Tooltip,OverlayTrigger,Container,Modal} from 'react-bootstrap'
import { Link,Redirect } from 'react-router-dom';
import Footer from '../containers/footerContainer'
import {Store,Search,FavoriteBorder,Loyalty,Clear,Edit} from '@material-ui/icons'
import {CATEGORIES_URL_IMAGE} from '../modules/app/config'
import Dailog_addTocart from '../containers/compornentDailogAddTocart'
import Navigations from '../containers/navigationContainer'
import Loading from '../Component/loading'
import {GIF} from '../Assets/images'
import {Digital} from 'react-activity';
import {setColor} from '../utils/colors'
import { keyword } from '../utils/keywords';
export default class FilterCategory extends Component {
    constructor(props){
        super(props);
        this.state={
            CategoryPage:1,
            CategoryProductFilter:[],
            CategoryProductFilterNoMore:false,
            ui:{
                showDialogAddTocart:false,
            },
            data:{
                cartegory:[],
                dataDialog:{},
                tab_active:null,
                old_tab:null,
                prodsSKU:[],
                countSKU:'',
                total:'',
                indxUserLike:''
            }
        }
    } 
    componentDidMount(){
        var CategoryID=this.props.match.params.idCategory;
        if(CategoryID && CategoryID!=null){
            const param={
                "page":this.state.CategoryPage,
                "CategoryID":CategoryID
            }
           
            this.props.getPorductFilterCategory(param);
            // console.log('---',CategoryID)
            this.setState({
                data:{...this.state.data,tab_active:CategoryID}
            })
        }
    }

    componentWillReceiveProps(nextProps){
        var CategoryID=this.props.match.params.CategoryID;
         const {products_category_filter,productData,user}=this.props;
         if(nextProps.productData.products_category && nextProps.productData.products_category!=productData.products_category){
             if(nextProps.productData.products_category.results.length>0){
                this.setState({
                    data:{...this.state.data,cartegory:nextProps.productData.products_category.results}
                })
             }
         }
         if(nextProps.CategoryID &&nextProps.CategoryID!=CategoryID){
            const param={
                "page":this.state.CategoryPage,
                "CategoryID":CategoryID
            }
            this.props.getPorductFilterCategory(param);
         }
         if(nextProps.products_category_filter && nextProps.products_category_filter !=products_category_filter){
            if(nextProps.products_category_filter.message==='success'){
                var data=nextProps.products_category_filter.results;
                
                //the same category
                if(this.state.data.old_tab===this.state.data.tab_active){
                    if(this.state.CategoryProductFilter.length===0){
                        if(data.length>0){
                            this.setState({
                                    CategoryProductFilter:data
                                })
                        }else{
                            this.setState({
                                CategoryPage:1,
                                CategoryProductFilterNoMore:true,
                                data:{
                                    ...this.state.data,
                                    old_tab:this.state.data.tab_active,
                                    
                                }
                            })
                        }
                    }else{
                        if(data.length>0){
                            var oldData=this.state.CategoryProductFilter;
                            data.map((dat)=>{
                                oldData.push(dat);
                            });
                            this.setState({CategoryProductFilter:oldData})
                        }else{
                            this.setState({
                                CategoryPage:1,
                                CategoryProductFilterNoMore:true,
                                data:{...this.state.data,old_tab:this.state.data.tab_active}
                            })
                        }
                    }
                }else{
                    this.setState({
                        CategoryProductFilter:data
                    })
                }
            }
         }
         if(nextProps.productData.product_sku && nextProps.productData.product_sku!=productData.product_sku ){
            if(nextProps.productData.product_sku.message==="success"){
                this.setState({
                    data:{
                        ...this.state.data,prodsSKU:nextProps.productData.product_sku.results
                    }
                })
            }
        }
        if(nextProps.productData.priceSKU && nextProps.productData.priceSKU!=productData.priceSKU){
            if(nextProps.productData.priceSKU['message']==='success'){
                var data=nextProps.productData.priceSKU['results'];
                this.setState({
                    data:{...this.state.data,countSKU:data['stock'],total:data['price']}
                })
            }
        }
        if(nextProps.productData.user_likied && nextProps.productData.user_likied!=productData.user_likied){
            if(nextProps.productData.user_likied.message==='success'){
                let newData = this.state.CategoryProductFilter;
                let row=newData[this.state.data.indxUserLike];
                row = { ...row,liked: row.liked + 1, youLiked: 1 };
                newData[this.state.data.indxUserLike]=row;
                this.setState({ CategoryProductFilter:newData});
                const data_send_notification = {
                    user: row.storeUserName,
                    type: "seller",
                    data: { type: 'user_like' },
                    message: "Product " + row.name + " gets a favorite from customer " + this.state.data.userFullName
                }
                this.props.setNotification(data_send_notification)
            }
        }
        if(nextProps.user.add_cart && nextProps.user.add_cart!=user.add_cart){
            if(nextProps.user.add_cart.message==='success'){
                this.setState({
                    ui:{
                        ...this.state.ui,
                        showDialogAddTocart:false,
                        showDialogAddedTocart:true,
                    }
                })
            }
        }
        if(nextProps.productData.products_filter && nextProps.productData.products_filter!=productData.products_filter){
            if(nextProps.productData.products_filter.message==='success'){
                var data=nextProps.productData.products_filter.results
                this.setState({
                    CategoryProductFilter:data
                })
            }
        }
    }
    _handleUi(key,value){
        this.setState({
            ui:{...this.state.ui,[key]:value}
        })
    }
    _handleData(key,value){
        this.setState({
            data:{...this.state.data,[key]:value}
        })
    }
    _userOnClickLiked(index,product){
        var Data={
            "productAlias":product['alias'],
            "shareTo":"",
            "type": "like"  
        }
        this.props.userLikeShare(Data);
        this.setState({data:{...this.state.data,indxUserLike:index}})
    }
    _loopProducts(Data){
        var result=[];
        if(Data){
            Data.map((oneProd,index)=>{
                result.push(
                    <OneProduct
                        showBtnCart={true}
                         fun_clickLike={()=>{this._userOnClickLiked(index,oneProd)}}
                         key={index} dataProduct={oneProd} 
                         functionbtn={()=>{
                        this._handleData('dataDialog',oneProd);
                        this._handleUi('showDialogAddTocart',true);
                        this.props.productSKU(oneProd['ID']);
                    }}
                    />
                )
            })
        }
        return(result)
    }
    _handleTap(CategoryID){
        if(CategoryID!=this.state.data.old_tab){
            const param={
                "page":this.state.CategoryPage,
                "CategoryID":CategoryID
            }
            this.setState({
                CategoryPage:1,
                CategoryProductFilterNoMore:false,
                CategoryProductFilter:[],
                data:{...this.state.data,tab_active:CategoryID,old_tab:CategoryID}
            })
            this.props.getPorductFilterCategory(param);
           
        }else{
            const param={
                "page":this.state.CategoryPage,
                "CategoryID":CategoryID
            }
            this.props.getPorductFilterCategory(param);
            this.setState({
                // CategoryProductFilterNoMore:false,
                data:{...this.state.data,tab_active:CategoryID}
            })
        }
        
    }
    _loopCategory_left(category){
        var bgP=setColor('primaryBackground');
        var rs=[];
        if(category){
            category.map((one,inx)=>{
                rs.push(
                    <Nav.Item className="one_category_left" key={inx} style={this.state.data.tab_active===one['categoryID']?{borderTopColor:bgP,borderTopStyle:"solid",borderTopWidth:1}:null} active={this.state.data.tab_active===one['categoryID']?true:false}>
                        <Nav.Link eventKey={one['categoryID']}   onClick={()=>{this._handleTap(one['categoryID'])}}>
                        <OverlayTrigger
                            key={'right'}
                            placement={'right'}
                            overlay={
                                (<Tooltip id='tooltip-right' className="class_col">
                                    <p>{one['name']}</p>
                                </Tooltip>)
                            }
                        >
                            <img src={CATEGORIES_URL_IMAGE+one['image']} className="image_category_left"/>
                           </OverlayTrigger> 
                        </Nav.Link>
                    </Nav.Item>
                )
            })
        }
        return rs;          
    }
    _handleAddPage(){
        const param={
            "page":this.state.CategoryPage+1,
            "CategoryID":this.state.data.tab_active
        }
        this.props.getPorductFilterCategory(param);
        this.setState({
            CategoryPage:this.state.CategoryPage+1
        })
    }
    _loopTap(data){
        var bgP=setColor('primaryBackground');
        var colP=setColor('primaryColor');
        var rs=[];
        if(data){
            data.map((one,inx)=>{
                rs.push(
                    <Tab.Pane eventKey={one['categoryID']} key={inx}>
                        {
                            
                            this.state.CategoryProductFilter.length>0?
                            <div className="contorl_btn_get_more_or_not">
                                 <div className="title_tap">
                                     <p>
                                         {one['name']}
                                     </p>
                                 </div>
                                <Row className="control_shops_edit">
                                    {this._loopProducts(this.state.CategoryProductFilter)}
                                </Row>
                                {
                                    this.props.productData.loading ?
                                    <div className="loadingSmall_home">
                                        <Digital color={bgP} size={20} speed={1} animating={true} />
                                    </div>:
                                    this.state.CategoryProductFilterNoMore===false?
                                    <button style={{backgroundColor:bgP,color:colP}} id="btn_more_product_filter_category" onClick={()=>{this._handleAddPage()}}>{keyword('see-more-products')?keyword('see-more-products'):"See more . . ."}</button>
                                    :
                                    <button id="btn_more_product_filter_category" disabled>{keyword('data-revealed')?keyword('data-revealed'):"No more . . ."}</button>
                                }
                            </div>
                            :
                            <div className="not_data_filter_category">
                                 <div className="title_tap">
                                     <p>
                                         {one['name']}
                                     </p>
                                 </div>
                                 {
                                     this.props.productData.loading ?
                                     <div className="loadingSmall_Category">
                                         <Digital color={bgP} size={20} speed={1} animating={true} />
                                     </div>:<h1>{keyword('data-revealed')?keyword('data-revealed'):"No more . . ."}</h1> 
                                 }
                                 
                            </div>
                           
                        }
                        
                    </Tab.Pane>
                )
            })
        }
        return rs;
    }
    render() {
        var CategoryID=this.props.match.params.idCategory;
        const {products_category} =this.props;
        return (
            <div className="product_filter_cartegory">
                <Navigations/>
                {
                    !CategoryID &&
                    <Redirect to="/oneCategory/1"></Redirect>
                }
               {
                   this.state.data.cartegory.length===0 && <Loading/>
               }
                <div className="Container_custome">
                    <Tab.Container defaultActiveKey={CategoryID?CategoryID:products_category['results'][0].categoryID}>
                        <div className="left_tab_category">
                            <Nav variant="pills" className="yaya">
                                {this._loopCategory_left(this.state.data.cartegory)}
                            </Nav>
                        </div>
                            <Tab.Content>
                                {
                                    this._loopTap(this.state.data.cartegory)
                                }
                            </Tab.Content>
                        
                    </Tab.Container>
                    </div>
                    <Dailog_addTocart 
                    ID={this.state.data.dataDialog['ID']}
                    dataSKU={this.state.data.prodsSKU}
                    data={this.state.data.dataDialog}
                    show={this.state.ui.showDialogAddTocart}
                    total={this.state.data.total}
                    countSKU={this.state.data.countSKU}
                    onHide={()=>{this.setState({ui:{...this.state.ui,showDialogAddTocart:false}})}}
                    />
                    <Modal
                        show={this.state.ui.showDialogAddedTocart} 
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="modal_added_to_cart"
                        >
                        <Modal.Body style={{textAlign:'center'}}>
                        <img src={GIF.done_add_tocart} id="image_gif_done_added_tocart"/>
                        <br/>
                        <h1>{keyword('product-successfully-carted')?keyword('product-successfully-carted'):"Product add to cart successfully"}</h1>
                        </Modal.Body>
                        <Modal.Footer style={{justifyContent:'center'}}>
                            <button id="btn_contenue_shopping_home" onClick={()=>{this.setState({ui:{...this.state.ui,showDialogAddedTocart:false}})}}>{keyword('word-for-continue-buying')?keyword('word-for-continue-buying'):"Continue Buying"}</button>
                        
                            <button id="btn_contenue_shopping_home">
                            <Link to="/addtocart">
                                <span>{keyword('go-to-cart')?keyword('go-to-cart'):"Go to cart"}</span>
                            </Link> 
                            </button>
                        
                        </Modal.Footer>
                    </Modal>
            </div>
        )
    }
}
