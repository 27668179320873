import React, { Component } from 'react'
import {Row,Col,Container, Nav,Modal, Table} from 'react-bootstrap';
import {Visibility,VisibilityOff} from '@material-ui/icons'
import {ErrorOutline,CloseOutlined} from '@material-ui/icons'
import {NOTIMAGE,LOGO} from '../Assets/images'
import {PROUDCT_URL_IMAGE} from '../modules/app/config'
import {DailogAddress} from './dailog'
import AddAddress from '../containers/addAddress';
import PinInput from 'react-pin-input'
import '../Style/Component/newPin.css'
import {setColor} from '../utils/colors';
import {keyword} from '../utils/keywords'
export default class NewPin extends Component {
    constructor(props){
        super(props);
        this.state={
            secret:true,

            falseVerifyPin:false,
            setPinSuccess:false,

            arrayPin:'',
            verifyPin:'',

            closeInput:false
        }
    }
    componentDidMount(){
    }
    componentWillReceiveProps(nextProps){
   
    }
    _submitPin(){
         var Pin =this.state.arrayPin;
         var verifyPin=this.state.verifyPin;
         if(Pin === verifyPin && (Pin.length===6 && verifyPin.length===6) ){
            var data={
                "newPin":verifyPin
            }
            this.props.addNew_pin(data);
            this.setState({setPinSuccess:true});
            
        }else{
             this.setState({falseVerifyPin:true})
         }
    }
    
    render() {
        var bgP=setColor('primaryBackground');
        let colP=setColor('primaryColor')
        const{onHide,onShow}=this.props
        return (
            <div >
                <Modal
                    className="newPincode"
                    show={onShow}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >
                    <div className="modelDeleteCart">
                        <CloseOutlined id="iconCloseChangePassword" onClick={onHide}/>
                        <h5 style={{textAlign:'center'}}>{keyword('set-pin-code')?keyword('set-pin-code'):"Set pin code"}</h5>
                        <Row style={{padding:'0px 10%'}}>
                            <PinInput 
                            length={6} 
                            initialValue=""
                            secret={this.state.secret}
                            onChange={(value, index) => {
                                this.setState({arrayPin:value})
                            }} 
                            type="numeric" 
                            inputMode="number"
                            style={{padding:'5px',textAlign:'center'}}  
                            inputStyle={{borderColor: '#4f5b66',borderRadius:'20px',textAlign:'center',margin:'5px',fontSize:'30px'}}
                            inputFocusStyle={{borderColor: 'blue',borderRadius:'20px',textAlign:'center',fontSize:'30px'}}
                            />
                        </Row>
                        <h5 style={{textAlign:'center'}}>{keyword('set-pin-code')?keyword('set-pin-code'):"Set pin code"}</h5>
                        <Row style={{padding:'0px 10%'}}>
                            <PinInput 
                            length={6} 
                            initialValue=""
                            secret={this.state.secret}
                            onChange={(value, index) => {
                                this.setState({verifyPin:value})
                            }} 
                            type="numeric" 
                            inputMode="number"
                            style={{textAlign:'center'}}  
                            inputStyle={{borderColor: '#4f5b66',borderRadius:'20px',textAlign:'center',margin:'5px',fontSize:'30px'}}
                            inputFocusStyle={{borderColor: 'blue',borderRadius:'20px',textAlign:'center',fontSize:'30px'}}
                           
                            />
                        </Row>
                        <div style={{widht:'100%',textAlign:'right'}}>
                            <button id="showPassword_newPin" onClick={()=>{this.state.secret?this.setState({secret:false}):this.setState({secret:true})}}>
                                {
                                    this.state.secret?
                                    <Visibility id="icon_Visibility"/>
                                    :
                                    <VisibilityOff id="icon_Visibility"/>
                                }
                                
                                <h6 style={{display:'inline'}}>{keyword('word-show-password')?keyword('word-show-password'):"Show pin code"}</h6>
                            </button>
                           
                        </div>
                    </div>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        {
                            this.state.arrayPin.length&&this.state.verifyPin.length===6?
                            <button id="Yes_newPin" style={{backgroundColor:bgP,color:colP}} onFocus={ this.state.closeInput&& setTimeout(onHide,500)} onClick={()=>{this._submitPin()}}>{keyword('ok')?keyword('ok'):"OK"}</button>:
                            <button id="Yes_newPinDisable" disabled>{keyword('ok')?keyword('ok'):"OK"}</button>
                        }
                    </Modal.Footer>
                </Modal>
                <Modal
                    className="falseVerify"
                    show={this.state.falseVerifyPin} 
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    className="modelDeleteCart"
                    >
                    <Modal.Body style={{textAlign:'center'}}>
                    <ErrorOutline style={{fontSize:100}}/>
                    <br/>
                    <h1>{keyword('confirm-new-password-is-incorrect')?keyword('confirm-new-password-is-incorrect'):"Invalid new pin"}</h1>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        <button id="btnHide" onClick={()=>{this.setState({falseVerifyPin:false})}}> {keyword('ok')?keyword('ok'):"OK"} </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    className="falseVerify"
                    show={this.state.setPinSuccess} 
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    className="modelDeleteCart"
                    >
                    <Modal.Body style={{textAlign:'center'}}>
                    <ErrorOutline style={{fontSize:100}}/>
                    <br/>
                    <h1>{keyword('please-keep-your-password-correct-to-pay-for-all-order')?keyword('please-keep-your-password-correct-to-pay-for-all-order'):"Please keep your password correct to pay for all purchases."}</h1>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        <button id="btnHide" onClick={()=>{this.setState({setPinSuccess:false,closeInput:true})}}> {keyword('ok')?keyword('ok'):"OK"} </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
