import React, { Component } from 'react'

export default class NotFountPage extends Component {
    render() {
        return (
            <div style={{textAlign:'center'}}>
                <h1>Sorry!</h1>
                <h2>Not Found Page</h2>
            </div>
        )
    }
}
