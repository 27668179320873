import React, { Component ,Image} from 'react';
import {Row,Col,Container,OverlayTrigger,Tooltip,Dropdown,DropdownButton} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {LOGO,ICONMESSAGER,IMAGES,NOTIMAGE, ICONS} from '../Assets/images'
import {Badge,Drawer,Input,Menu,MenuItem,Button} from '@material-ui/core'
import {Search,Close,LocalMallOutlined,PersonOutlineOutlined,Clear,MenuOpen} from '@material-ui/icons';
import {MEMBER_URL_IMAGE,LOGO_IMAGE_URL} from '../modules/app/config'
import '../Style/Component/NavigationNew.css'
import '../Assets/color.css'
import { axios } from "../modules/app";
import {store} from '../store/index'
import {setColor,numberCountCart,setLayout} from '../utils/colors'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {keyword} from '../utils/keywords'
export default class NavigationNew extends Component {
  constructor(props){
      super(props);
      this.state={
          ui:{
              showSearch:false,
              showSubMenus:false,
              server_color:{},
              server_site:false
          },
          data:{
              nameuser:'',
              stringSearch:'',
              categoriesID:'',
              categories:[],
              profileUser:""
          },
          lang:"EN",
      }
      if(localStorage.getItem('persist:amatakWebsite')){
        const user_sms = JSON.parse(localStorage.getItem('persist:amatakWebsite'));
        const login_sms=JSON.parse(user_sms.user);
        if(login_sms.login && login_sms.login.message==="success"){
            if(login_sms.login && login_sms.login.token && login_sms.login.token!=''){
                 window.onload = function() {
                    if(!window.location.hash &&window.location.pathname!="/message" ) {
                      
                        window.location = window.location + '#prd_foryou?';
                        window.location.reload();
                    }
                  
                }
            }
        }
    }
    
  }
    componentDidMount(){
        this.props.getPorductCategory();
        this.props.getColor();
        this.props.getSiteDescription();
    }
    componentDidUpdate(nextProps){
        try{
            var dataUser=JSON.parse(localStorage.getItem('persist:amatakWebsite'));
            var JsonDATA=JSON.parse(dataUser['user'])['login']['data'];
            if(JsonDATA['image']){
                this.setState({
                    data:{...this.state.data,
                        profileUser:MEMBER_URL_IMAGE+JsonDATA['image'],
                        nameuser:JsonDATA['fullName']
                    }
                    })
                   
            }else{
                this.setState({
                    data:{...this.state.data,
                        profileUser:IMAGES.me,
                        nameuser:JsonDATA['fullName']
                    }
                    })
            }
           
        }catch(e){
         
        }
    }
    

componentWillReceiveProps(nextProps){
    const {cartList,productData,err_cartList,chatData} = this.props;
    if(nextProps.productData.products_category && nextProps.productData.products_category!=productData.products_category){
        var data=nextProps.productData.products_category.results;
         this.setState({
              data:{...this.state.data,categories:data}
          });
          if(localStorage.getItem('persist:amatakWebsite')){
            const user = JSON.parse(localStorage.getItem('persist:amatakWebsite'));
            if(user){
                const login=JSON.parse(user.user);
                if(login&&login['login']){
                    var login_=login['login']
                    if(login_['data'] && login_['message']==="success" && login_['token']){
                         if(axios.defaults&& axios.defaults.headers.common && axios.defaults.headers.common['Authorization']){
                            setTimeout(() => {
                                this.props.getCartList()
                            }, 2000);
                        }
                    }else{
                       return
                    }
                }else{
                    return
                }
            }else{
                return
            }
          }else{
            return
        }

    }
    if(nextProps.productData.color && nextProps.productData.color !=productData.color){
        if(nextProps.productData.color.results && nextProps.productData.color.results.length>0){
           var storeData = store.getState();
            if(storeData && storeData['productData'] && storeData['productData']['color'] &&  storeData['productData']['color'].results &&  storeData['productData']['color'].results.length>0){
                this._handleUi('server_color',storeData['productData']['color'].results[0])
           }
        }
    }
    if(nextProps.productData.sitedescription && nextProps.productData.sitedescription !=productData.sitedescription){
        if(nextProps.productData.sitedescription.message==='success'){
            this.setState({
                server_site:nextProps.productData.sitedescription.results
            })
        }
    }
}
_handleData(key,value){
    this.setState({
        data:{...this.state.data,[key]:value}
    })
}

handleClick(lagne){
    this.setState({ lang:lagne});
    this.props.appChangeLanguage(lagne)
};
_handleUi(key,value){
    this.setState({
        ui:{
            ...this.state.ui,[key]:value
        }
    })
}
_loopShowCategory(data,__color){
    const rs=[];
    if(data){
        data.map((title,index)=>{
          if(index){
            rs.push(
              <div key={index} 
                    style={{color:__color}}
                    onClick={()=>{this._handleData('stringSearch',title['name'])}} 
                    className={this.state.data.stringSearch===title['name']?"search":"oneLink_search"} >
                  <p>{title['name']}</p>
              </div> 
            )
          }
        })
      }
      return rs;
}
  _handleUi(key,value){
      this.setState({
          ui:{...this.state.ui,[key]:value}
      })
  }
  render() {
      let bgP=setColor('primaryBackground');
      let colP=setColor('primaryColor');
      let bgD=setColor('darkBackground');
      let imageLogo=setLayout('logo');
      var number=numberCountCart();
      var lang=store.getState().app['lang']?store.getState().app['lang']:'en';
    return (
        <div>
            <div className="NavigationNew" style={{backgroundColor:bgP}}> 
                <Container >
                    <Row >
                        <Col md={1} sm={6} xs={6} style={{textAlign:'start',alignItems:'flex-start'}}>
                            <Link to={'/'}>
                                <img src={imageLogo?LOGO_IMAGE_URL+imageLogo:NOTIMAGE} id="logoAMATAK"/>
                            </Link>
                        </Col>
                        <Col md={8} sm={0} className="none_smallScren" >
                            <Link to={'/'} className="links" style={{color:colP}}>{keyword('home')?keyword('home'):'Home'}</Link>
                            <Link to={'/shop'} className="links" style={{color:colP}}>{keyword('shop')?keyword('shop'):'Shop'}</Link>
                            <Link to={'/onecategory'} className="links" style={{color:colP}}>{keyword('categories-product')?keyword('categories-product'):'Category'}</Link>
                            <Link to={'/profile'} className="links" style={{color:colP}}>{keyword('history')?keyword('history'):'History'}</Link>
                            <Link to={'/about'} className="links" style={{color:colP}}>{keyword('about')?keyword('about'):'About'}</Link>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="icon_3_screen" >
                            <div className="dons_t_show_icon_3">
                                <OverlayTrigger
                                    placement={'bottom'}
                                    overlay={
                                        <Tooltip id='tooltip-bottom' className="class_show_title_navigation danger">
                                            <p>{keyword('search')?keyword('search'):'Search'}</p>
                                        </Tooltip>
                                    }
                                    >
                                        <Search id="icon_Search" style={{color:colP}} onClick={()=>{this._handleUi('showSearch',true)}}/>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement={'bottom'}
                                    overlay={
                                        <Tooltip id='tooltip-bottom' className="class_show_title_navigation danger">
                                            <p>{keyword('cart')?keyword('cart'):'Cart'}</p>
                                        </Tooltip>
                                    }
                                    >
                                        <Link to={'/AddToCart'} className="addTocart">
                                        <Badge badgeContent={number} color="primary">
                                            <LocalMallOutlined id="icon_LocalMallOutlined" style={{color:colP}}/>
                                        </Badge>
                                    </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement={'bottom'}
                                    overlay={
                                        <Tooltip id='tooltip-bottom' className="class_show_title_navigation danger">
                                            <p>{keyword('account-name')?keyword('account-name'):'Me'}</p>
                                        </Tooltip>
                                    }
                                    >
                                    <Link to={'/profile'} className="addTocart">
                                        <PersonOutlineOutlined id="icon_PersonOutlineOutlined" style={{color:colP}}/>
                                    </Link>
                                </OverlayTrigger>
                                <PopupState variant="popover" popupId="demo-popup-menu">
                                {(popupState) => (
                                    <React.Fragment>
                                    <Button variant="contained" style={{backgroundColor:colP,marginBottom:10,padding:'0px',color:bgP}} {...bindTrigger(popupState)}>
                                        {lang?
                                            lang==='en'?"EN"
                                            :
                                            lang==='kh'?"KH"
                                            :
                                            lang==='cn'?"CN"
                                            :"EN"
                                        :"EN"}
                                        <img src={lang==="en"?ICONS['lg-en']:lang==='kh'?ICONS['lg-kh']:lang==='cn'?ICONS['lg-cn']:ICONS['lg-en']} className="image-lang-icon"/>
                                    </Button>
                                    <Menu {...bindMenu(popupState)}>
                                        <MenuItem onClick={()=>this.handleClick("en")} onClickCapture={popupState.close} >EN <img src={ICONS['lg-en']} className="image-lang-icon"/></MenuItem>
                                        <MenuItem onClick={()=>this.handleClick("kh")} onClickCapture={popupState.close} >KH <img src={ICONS['lg-kh']} className="image-lang-icon"/></MenuItem>
                                        <MenuItem onClick={()=>this.handleClick("cn")} onClickCapture={popupState.close} >CN <img src={ICONS['lg-cn']} className="image-lang-icon"/></MenuItem>
                                    </Menu>
                                    </React.Fragment>
                                )}
                                </PopupState>
                            </div>
                            <div className="hide_menu_screen_big">
                                <OverlayTrigger
                                    placement={'bottom'}
                                    overlay={
                                        <Tooltip id='tooltip-bottom' className="class_show_title_navigation danger">
                                            <p>{keyword('cart')?keyword('cart'):"Cart"}</p>
                                        </Tooltip>
                                    }
                                    >
                                        <Link to={'/AddToCart'} className="addTocart">
                                        <Badge badgeContent={number} color="primary">
                                            <LocalMallOutlined id="icon_LocalMallOutlined" style={{color:colP}}/>
                                        </Badge>
                                    </Link>
                                </OverlayTrigger>
                                <button id="button_menus_show_small_screen" onClick={()=>{this._handleUi("showSubMenus",true)}}>
                                    <MenuOpen id="icon_button_submenuse" style={{color:colP}}/>
                                </button>
                            </div>
                        </Col>
                    </Row>
                    
                </Container>
                    <Drawer anchor="top" open={this.state.ui.showSearch} onClose={()=>{this._handleUi('showSearch',false)}} style={{width:'50%'}} className="bigSearch">
                        <div>
                            <div className="boxSearch" style={{backgroundColor:bgP}}>
                                <Row style={{marginBottom:20}}>
                                    <div style={{width:'90%',textAlign:'start',color:colP}} >
                                        {keyword('search')?keyword('search'):'Search'}
                                    </div>
                                    <Clear id="icon_Clear" style={{color:colP}}onClick={()=>{this._handleUi('showSearch',false)}}/>
                                </Row>
                                <Row style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                    <Input
                                        id="searchProd"
                                        style={{width:'90%'}}
                                        placeholder={keyword('search')?keyword('search'):'Search'}
                                        onChange={(value, index) => {
                                            this.setState({
                                                data:{
                                                  ...this.state.data,
                                                  stringSearch:value.target.value
                                                }
                                            })
                                        }} 
                                        value={this.state.data.stringSearch}
                                    />
                                      <Link 
                                        to={{pathname:"/search/"+this.state.data.stringSearch}}
                                        >
                                            <Search id="icon_Search" onClick={()=>{this._handleUi('showSearch',false)}} style={{color:colP}}/>
                                        </Link> 
                                   
                                </Row>
                                <div className="ShowallCategory">
                                    {
                                        this._loopShowCategory(this.state.data.categories,colP)
                                    }
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </div>
                <div className="navHaveSize">
                    a
                </div>
                {
                    window.location.pathname === '/message' ?null :
                    <div className="fixedChat">
                        <a href="https://www.messenger.com/t/260908657647821/?messaging_source=source%3Apages%3Amessage_shortlink" target={"_blank"}>
                            <button>
                                <img src={ICONMESSAGER} id="icon"/>
                            </button>
                        </a>
                    </div> 
                }
               
                <Drawer anchor={'right'} open={this.state.ui.showSubMenus} id="submenus" >
                    <div className="menu_Small" style={{backgroundColor:bgD}}>
                        <Row style={{padding:0,margin:0}}>
                            <Col xs={6} style={{padding:0,margin:0}}>
                                <button id='btnCloseMenuSamll' onClick={()=>{this._handleUi("showSubMenus",false)}} style={{color:colP}}><Close id="icon_close" /></button>
                            </Col>
                            <Col xs={6} style={{padding:0,margin:0,float:'revert'}}>
                                <PopupState variant="popover" popupId="demo-popup-menu">
                                    {(popupState) => (
                                        <React.Fragment>
                                        <Button id="btn-lang-small" variant="contained" style={{backgroundColor:colP,marginBottom:10,padding:'0px',color:bgP}} {...bindTrigger(popupState)}>
                                            {this.state.lang?this.state.lang:"EN"}
                                            <img src={this.state.lang==="en"?ICONS['lg-en']:this.state.lang==='kh'?ICONS['lg-kh']:this.state.lang==='cn'?ICONS['lg-cn']:ICONS['lg-en']} className="image-lang-icon"/>
                                        </Button>
                                        <Menu {...bindMenu(popupState)}>
                                            <MenuItem onClick={()=>this.handleClick("en")} onClickCapture={popupState.close} >EN <img src={ICONS['lg-en']} className="image-lang-icon"/></MenuItem>
                                            <MenuItem onClick={()=>this.handleClick("kh")} onClickCapture={popupState.close} >KH <img src={ICONS['lg-kh']} className="image-lang-icon"/></MenuItem>
                                            <MenuItem onClick={()=>this.handleClick("cn")} onClickCapture={popupState.close} >CN <img src={ICONS['lg-cn']} className="image-lang-icon"/></MenuItem>
                                        </Menu>
                                        </React.Fragment>
                                    )}
                                </PopupState>
                            </Col>
                        </Row>
                        <div className="search_product_sub_menus" >
                            <Row style={{margin:0,padding:0,paddingRight:20}}>
                                <Input
                                    id="searchProd"
                                    style={{width:'100%',color:colP}}
                                    placeholder={keyword('search')?keyword('search'):'Search'}
                                    onChange={(value, index) => {
                                        this.setState({
                                            data:{
                                                ...this.state.data,
                                                stringSearch:value.target.value
                                            }
                                        })
                                    }} 
                                    value={this.state.data.stringSearch}
                                />
                                    <Link 
                                        to={{pathname:"/search/"+this.state.data.stringSearch}}
                                        id="button_search_small">
                                        <Search id="icon_Search" onClick={()=>{this._handleUi('showSearch',false)}} style={{color:colP}}/>
                                    </Link> 
                                
                            </Row>
                        </div>
                            {
                                this.state.data.nameuser ? 
                                    <Link to={'/profile'} onClick={()=>{this._handleUi('showSubMenus',false)}}>
                                        <div className="user_account_sub_menus">
                                            <img  src={this.state.data.profileUser} id="image_profile_user_sub_menuse"></img>
                                            <p style={{color:colP}}>{this.state.data.nameuser}</p>
                                        </div>
                                    </Link>
                                    :
                                    <Link to={'/login'} onClick={()=>{this._handleUi('showSubMenus',false)}}>
                                        <h3 id="status_got_to_acc" style={{color:colP,backgroundColor:bgP}}>
                                            {keyword('register')?keyword('register'):'Register'}
                                        </h3>
                                    </Link>
                            }
                        <Link to={'/'} className="linK_small_sub_menus" onClick={()=>{this._handleUi('showSubMenus',false)}} style={{color:colP}}>{keyword('home')?keyword('home'):'Home'}</Link>
                        <Link to={'/shop'} className="linK_small_sub_menus" onClick={()=>{this._handleUi('showSubMenus',false)}} style={{color:colP}}>{keyword('shop')?keyword('shop'):'Shop'}</Link>
                        <Link to={'/oneCategory'} className="linK_small_sub_menus" onClick={()=>{this._handleUi('showSubMenus',false)}} style={{color:colP}}>{keyword('categories-product')?keyword('categories-product'):'Category'}</Link>
                        <Link to={'/profile'} className="linK_small_sub_menus" onClick={()=>{this._handleUi('showSubMenus',false)}} style={{color:colP}}>{keyword('history')?keyword('history'):'History'}</Link>
                        <Link to={'/about'} className="linK_small_sub_menus" onClick={()=>{this._handleUi('showSubMenus',false)}} style={{color:colP}}>{keyword('about')?keyword('about'):'About'}</Link>
                    </div>
                </Drawer>
        </div>
    );
  }
}
