import React, { Component } from 'react';
import {Col,Row,Container} from 'react-bootstrap'
import {Facebook,Twitter,Email,Instagram,Pinterest} from '@material-ui/icons'
import '../Style/Component/footer.css'
import '../Assets/color.css'
import { Link } from 'react-router-dom';
import {LOGO,ICONMESSAGER,IMAGES,NOTIMAGE, ICONS} from '../Assets/images'
import Privacy from '../Component/privacy'
import TermOfServer from '../Component/termOfService'
import {store} from '../store/index'
import {setColor} from '../utils/colors'
import {keyword} from '../utils/keywords'
import {LOGO_IMAGE_URL} from '../modules/app/config'
export default class Foolter extends Component {
  constructor(props){
    super(props);
    this.state={
        data:{
            categories:[],
        },
        urlHowUsed:[],
        ui:{
          privatcy:false,
          termOfService:false,
          server_color:{}
        }
        
    }
}
  componentDidMount(){
    var storeData = store.getState();
    if(storeData && storeData['productData'] && storeData['productData']['color'] &&  storeData['productData']['color'].results &&  storeData['productData']['color'].results.length>0){
          this._handleUi('server_color',storeData['productData']['color'].results[0])
    }
  }
  componentWillReceiveProps(nextProps){
    const {productData}=this.props;
    if(nextProps.productData.products_category && nextProps.productData.products_category!=productData.products_category){
      var data=nextProps.productData.products_category.results;
        this.setState({
            data:{...this.state.data,categories:data}
        })
    };
    if(nextProps.productData.sitedescription && nextProps.productData.sitedescription !=productData.sitedescription){
      var data=nextProps.productData.sitedescription?nextProps.productData.sitedescription.results:false;
      var rs=[]
      if(data){
        rs.push({
          title:data[0]['cashinTitle'],
          link:data[0]['cashinLink']
        });
        this.setState({urlHowUsed:rs})
      }
    }
  }
  _loopContain(nav){
    var result =[];
    if(nav){
      nav.map((title,index)=>{
        if(index<6){
          result.push(
            <Link key={index} to={{
                  pathname: "/oneCategory",
                  CategoryID:title['categoryID']
                  }} id="linkInFooter">
                <h1>{title['name']}</h1>
            </Link> 
          )
        }
      })
    }
    return result
  }
  _loopPayment(paymentCom){
    var result =[];
    if(paymentCom){
      paymentCom.map((title,index)=>{
        result.push(
          <img src={title} key={index} className="imagePaymentCom"/>
        )
      })
    }
    return result
  }
  _aboutLink(ab){
    var rs=[];
    if(ab){
      ab.map((title,index)=>{
        rs.push(
          <Link key={index} to={{
              pathname:title['link']
              }} id="linkInFooter" target="_blank">
            <h1>{title['title']}</h1>
        </Link> 
        )
      })
    }
    return rs;
  }
  _handleUi(key,value){
      this.setState({
        ui:{
          ...this.state.ui,[key]:value
        }
      })
  }
  _HowUser(HowUser){
    var result =[];
    if(HowUser){
      HowUser.map((title,index)=>{
        if(index<6){
          result.push(
            <Link key={index} to={{
                  pathname:title['link']
                  }} id="linkInFooter" target="_blank">
                <h1>{title['title']}</h1>
            </Link> 
          )
        }
      })
    }
    return result
  }
  render() {
      let bgL=setColor('lightBackground');
      let colL=setColor('lightColor');
      const navbar=this.state.data.categories;
      var info=store.getState().productData;
      const HowUser=[
        // {"title":"របៀបដាក់លុយ","link":"/"},
        // {"title":"របៀបទិញទំនិញ","link":"/"},
        // {"title":"របៀបទទួលទំនិញ","link":"/"}
      ];
      const about=[
        {'title':keyword('about')?keyword('about'):"About",'link':'/about'}
      ]
    return (
      <div className="footer"> 
        <Row>
            <div className="divContainEmail" style={{backgroundColor:bgL}}>
                <Container>
                    <Row > 
                      <Col md={9} sm={9} xs={12} className="rowTest" >
                          <a href={"https://www.apple.com/app-store/"} className="link_app" target="_blank" style={{color:colL}}>
                              {keyword("word-for-ios")?keyword("word-for-ios"):"Apps for IOS"} <img src={IMAGES.appStore}/>
                          </a>
                          <a href={"https://www.apple.com/app-store/"} className="link_app" target="_blank" style={{color:colL}}>
                              {keyword("word-for-footer-android")?keyword("word-for-footer-android"):"Apps for Android"}  <img src={IMAGES.playStore}/>
                          </a>
                      </Col>
                      <Col md={3} sm={3} xs={12} className="footerSocai">
                            {/* <Row className="footerSocai">
                              <a href="https://m.facebook.com/amatakApp/" target="_blank"  style={{color:colL}}>
                                <Facebook id="iconsSocai"/>
                              </a>
                              <a href="#"  style={{color:colL}}>
                                <Twitter id="iconsSocai"/>
                              </a>
                              <a href="#"  style={{color:colL}}>
                                <Email id="iconsSocai"/>
                              </a>
                              <a href="#"  style={{color:colL}}>
                                <Instagram id="iconsSocai"/>
                              </a>
                              <a href="#"  style={{color:colL}}>
                                <Pinterest id="iconsSocai"/>
                              </a>
                            </Row> */}
                      </Col>
                    </Row>
                </Container>
            </div>
        </Row>
        <Container className="navFooter">
            <Row style={{ padding:0,margin:0}}>
              <Col sm={3} className="div_group_category">
                <h2>{keyword('categories-product')?keyword('categories-product'):"Category"}</h2>
                {this._loopContain(navbar)}
              </Col>
              <Col sm={3} className="div_how_to_use_this">
                <h2>{keyword('use')?keyword('use'):"How to use"}</h2>
                {this._HowUser(this.state.urlHowUsed)}
              </Col>
              <Col sm={3} className="div_about_this">
                <h2>{keyword('about')?keyword('about'):"About"}</h2>
                <div id="linkInFooter">
                  <h1 onClick={()=>{this._handleUi('privatcy',true)}}>{keyword('word-privacy-policy')?keyword('word-privacy-policy'):"Privacy Policy"}</h1>
                  <h1 onClick={()=>{this._handleUi('termOfService',true)}}>{keyword('word-terms-of-use')?keyword('word-terms-of-use'):"Terms of use"}</h1>
                </div>
                {this._aboutLink(about)}
              </Col>
              <Col sm={3} className="div_contant_this">
                  <h2>{keyword('word-contact')?keyword('word-contact'):"Contact"}</h2>
                  <h3>{keyword('location')?keyword('location'):"Address"}: <span>{info['sitedescription'] && info['sitedescription']['results'] && info['sitedescription']['results'][0]?info['sitedescription']['results'][0]['address']:""}</span></h3>
                  <h3>{keyword('phone-number')?keyword('phone-number'):'Phone'}:<span>{info['sitedescription'] && info['sitedescription']['results'] && info['sitedescription']['results'][0]?info['sitedescription']['results'][0]['phone']:""}</span></h3>
                  <h3>{keyword('word-email')?keyword('word-email'):"Email"}:<span>{info['sitedescription'] && info['sitedescription']['results'] && info['sitedescription']['results'][0]?info['sitedescription']['results'][0]['receiveEmail']:""}</span></h3>
                </Col>
            </Row>
        </Container>
        <Row className="footest" style={{backgroundColor:bgL}}>
          <Container>
            <div className="divLeft"  >
              <img src={info['sitedescription'] && info['sitedescription']['results'] && info['sitedescription']['results'][0]?LOGO_IMAGE_URL+info['sitedescription']['results'][0]['logo']:NOTIMAGE } id="logoFooter"/>
              <h1 style={{color:colL}} >{info['sitedescription'] && info['sitedescription']['results'] && info['sitedescription']['results'][0]?"  "+info['sitedescription']['results'][0]['copyRight']:""}</h1>
            </div>
          </Container>
        </Row>
        <Privacy
            show={this.state.ui.privatcy} 
            onHide={()=>{this._handleUi('privatcy',false)}}
        />
        <TermOfServer 
            show={this.state.ui.termOfService}
            onHide={()=>{this._handleUi('termOfService',false)}}
            />
      </div>
    );
  }
}
