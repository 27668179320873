import React, { Component } from 'react';
import Navigations from '../containers/navigationContainer'
import StoreStories from '../Component/storeStories' 
import {Row,Col,Container,Modal,Spinner} from 'react-bootstrap'
import Categories from '../Component/groupCategory'
import '../Style/Component/globleContainer.css'
import OneProduct from '../containers/compornentOneProduct'
import {NewTitle} from '../Component/title'
import "../Style/HomepageNew.css"
import Footer from '../containers/footerContainer'
import { Link } from 'react-router-dom';
import {Digital} from 'react-activity';
import Dailog_addTocart from '../containers/compornentDailogAddTocart'
import {GIF} from '../Assets/images'
import Loading from '../Component/loading'
import {setColor} from '../utils/colors'
import {keyword} from '../utils/keywords'
export default class HomepageNew extends Component {
    constructor(props){
        super(props);
        this.state={
            ui:{
                showDialogAddTocart:false,
                showDialogAddedTocart:false,
                started:true
            },
            data:{
                nullData:false,
                addMorePage:false,
                page_home:1,
                homeProds:[],
                prodsSKU:[],
                dataDialog:{},
                categories:[],

                indxUserLike:'',
                storeStories:[],
                countSKU:'',
                total:'',
                userFullName:''
            }
        }
    }
    componentDidMount(){
        this.props.homeProduct(this.state.data.page_home);
        this.props.storeStories();
        try{
            var dataUser=JSON.parse(localStorage.getItem('persist:amatakWebsite'));
            var JsonDATA=JSON.parse(dataUser['user'])['login']['data'];
            this.setState({
                data:{...this.state.data,
                    userFullName:JsonDATA['fullName']
                }
            });
        }catch(e){

        }
    }
    componentDidUpdate(){
        if(this.state.data.addMorePage===true){
            this.props.homeProduct(this.state.data.page_home);
            this.setState({
                data:{...this.state.data,addMorePage:false}
            })
        }
       
    }
    componentWillReceiveProps(nextProps){
        const {productData,user,shopData}=this.props;
        try{
            if(nextProps.productData.home_product && nextProps.productData.home_product != productData.home_product)
            {
                if(nextProps.productData.home_product.message==="success"){
                    var data = nextProps.productData.home_product['results'];
                    this._handleUi('started',false)
                    if(data.length>0){
                        if(this.state.data.homeProds.length===0){
                            this.setState({
                                data:{...this.state.data,homeProds:data}
                            });
                        }else{
                            var dataOld=this.state.data.homeProds;
                            data.map((dat)=>{
                                dataOld.push(dat);
                            });
                            this.setState({
                                data:{...this.state.data,homeProds:dataOld}
                            });
                        }
                    }else{
                        this._handleData('nullData',true)
                    }
                }
            }
            if(nextProps.productData.product_sku && nextProps.productData.product_sku!=productData.product_sku ){
                if(nextProps.productData.product_sku.message==="success"){
                    this.setState({
                        data:{
                            ...this.state.data,prodsSKU:nextProps.productData.product_sku.results
                        }
                    })
                }
            }
            
            if(nextProps.productData.user_likied && nextProps.productData.user_likied!=productData.user_likied){
                if(nextProps.productData.user_likied.message==='success'){
                    let newData = this.state.data.homeProds;
                    let row=newData[this.state.data.indxUserLike];
                    row = { ...row,liked: row.liked + 1, youLiked: 1 };
                    newData[this.state.data.indxUserLike]=row;
                    this.setState({ data:{...this.state.data,homeProds:newData} });
                    const data_send_notification = {
                        user: row.storeUserName,
                        type: "seller",
                        data: { type: 'user_like' },
                        message: "Product " + row.name + " gets a favorite from customer " + this.state.data.userFullName
                    }
                    this.props.setNotification(data_send_notification)
                }
            }
            if(nextProps.productData.products_category && nextProps.productData.products_category!=productData.products_category){
              var data=nextProps.productData.products_category.results;
                this.setState({
                    data:{...this.state.data,categories:data}
                })
            }
           
            if(nextProps.user.add_cart && nextProps.user.add_cart!=user.add_cart){
                if(nextProps.user.add_cart.message==='success'){
                    this.setState({
                        ui:{
                            ...this.state.ui,
                            showDialogAddTocart:false,
                            showDialogAddedTocart:true,
                        }
                    });
                    // this.props.getCartList();
                }
            }
            if(nextProps.shopData.storeStories && nextProps.shopData.storeStories !=shopData.storeStories){
                 if(nextProps.shopData.storeStories.results.length>0){
                   this.setState({
                        data:{...this.state.data,storeStories:nextProps.shopData.storeStories.results}
                   })
               }
            }
             if(nextProps.productData.priceSKU && nextProps.productData.priceSKU!=productData.priceSKU){
                if(nextProps.productData.priceSKU['message']==='success'){
                    var data=nextProps.productData.priceSKU['results'];
                    this.setState({
                        data:{...this.state.data,countSKU:data['stock'],total:data['price']}
                    })
                }
            }
        }catch(e){
            alert("error")
        }
    }

    _userOnClickLiked(index,product){
        var Data={
            "productAlias":product['alias'],
            "shareTo":"",
            "type": "like"  
        }
        this.props.userLikeShare(Data);
        this.setState({data:{...this.state.data,indxUserLike:index}})
    }

    _loopProducts(Data){
        var result=[];
        if(Data){
            Data.map((oneProd,index)=>{
                result.push(
                    <OneProduct
                        showBtnCart={true}
                        fun_clickLike={()=>{this._userOnClickLiked(index,oneProd)}}
                        key={index} dataProduct={oneProd} prodsSKU={this.state.data.prodsSKU} 
                        functionbtn={()=>{
                            this._handleData('dataDialog',oneProd);
                            this._handleUi('showDialogAddTocart',true);
                            this.props.productSKU(oneProd['ID']);
                        }} />
                )
            })
        }
        return(result)
    }
    
    _handleUi(key,value){
        this.setState({
            ui:{...this.state.ui,[key]:value}
        })
    }
    _handleData(key,value){
        this.setState({
            data:{...this.state.data,[key]:value}
        })
    }
    
  render() {
    let colP=setColor('primaryColor');
    let bgP=setColor('primaryBackground');
    return (
        <div>  
            <Navigations/>
            {
                (
                    (this.props.productData.loading ||
                    this.props.shopData.loading) && this.state.ui.started
                ) && <Loading/>
            }
            <div id="prd_foryou">
                <div className="storeStois" >
                    <StoreStories storeStories={this.state.data.storeStories}/>
                </div>
            </div>
             <div className="controlCategories">
                <Categories DataCategories={this.state.data.categories}/>
            </div>
          
            <div className="control_homeProduct" id="prd_foryou">
                <div className="container"  >
                    {NewTitle(keyword('for-you')?keyword('for-you'):"Product for you")}
                    <Row >
                        {this._loopProducts(this.state.data.homeProds)}
                    </Row>
                    <div className="div_get_product_more">
                        
                        {
                           this.props.productData.loading ?
                                <div className="loadingSmall_home">
                                    <Digital color="#727981" size={30} speed={1} animating={true}  />
                                </div>
                                :
                                this.state.data.nullData===false? 
                                <button id="btn_get_more_product" 
                                style={{backgroundColor:bgP,color:colP}}
                                onClick={()=>{
                                        this.setState({
                                                data:{...this.state.data,
                                                    page_home:this.state.data.page_home+1,
                                                    addMorePage:true
                                                }})
                               }}>{keyword('see-more-products')?keyword('see-more-products'):"See more . . ."}</button>:
                               <button id="btn_get_more_product" 
                               disabled>{keyword('data-revealed')?keyword('data-revealed'):"No more . . ."}</button>
                        }
                    </div>
                </div>
            </div>            
        <Footer/>
        <Dailog_addTocart 
        ID={this.state.data.dataDialog['ID']}
        dataSKU={this.state.data.prodsSKU}
        data={this.state.data.dataDialog}
        show={this.state.ui.showDialogAddTocart}
        total={this.state.data.total}
        countSKU={this.state.data.countSKU}
        onHide={()=>{this.setState({ui:{...this.state.ui,showDialogAddTocart:false}})}}
        />
       <Modal
            onHide={()=>{this.setState({ui:{...this.state.ui,showDialogAddedTocart:false}})}}
            show={this.state.ui.showDialogAddedTocart} 
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal_added_to_cart"
            >
            <Modal.Body style={{textAlign:'center'}}>
            <img src={GIF.done_add_tocart} id="image_gif_done_added_tocart"/>
            <br/>
            <h1>{keyword('product-successfully-carted')?keyword('product-successfully-carted'):"Goods successfully carted"}</h1>
            </Modal.Body>
            <Modal.Footer style={{justifyContent:'center'}}>
                <button id="btn_contenue_shopping_home" onClick={()=>{this.setState({ui:{...this.state.ui,showDialogAddedTocart:false}})}}>បន្តការទិញទំនិញ</button>
            
                <button id="btn_contenue_shopping_home">
                <Link to="/addtocart">
                    <span>{keyword('go-to-cart')?keyword('go-to-cart'):"Go to cart"}</span>
                </Link> 
                </button>
            </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
