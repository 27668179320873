import { connect } from "react-redux";
import AddToCartComponent from "../Screen/AddToCart"
import {get_address} from '../modules/address/reducer'
import {
  getCartList
  ,deleteCart,
  addCart,
  checkOut_user,
  setNotification,
  
} from '../modules/user/reducer'
import {
  getAmountDiscount
} 
from '../modules/product/reducer'
const mapStateToProps = state => ({
  
  cartList:state.user.cartList,
  delete_Cart:state.user.delete_Cart,
  err_cartList:state.user.err_cartList,
  dataAddCart:state.user.add_cart,
  buyAlready:state.user.check_out_user,
  get_amountDiscount:state.productData.get_amountDiscount
  // deleteCart:state.user.deleteCart
});

const mapDispatchToProps = {
  getAmountDiscount,
  getCartList,
  deleteCart,
  addCart,
  checkOut_user,
  setNotification,
  get_address,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddToCartComponent);
