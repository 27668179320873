import React, { Component } from 'react'
import '../Style/Component/changePassword.css'
import {Row,Modal} from 'react-bootstrap';
import PinInput from 'react-pin-input'
import {LOGO_IMAGE_URL} from '../modules/app/config'
import {NOTIMAGE,LOGO} from '../Assets/images'
import {CloseOutlined,Visibility,VisibilityOff,ErrorOutline, KeyboardArrowUp} from '@material-ui/icons'
import {GIF} from '../Assets/images'
import {setColor,setLayout} from '../utils/colors';
import {keyword} from '../utils/keywords'
export default class changPassword extends Component {
    constructor(props){
        super(props);
        this.state={
            oldPin:'',
            newPin:'',
            comNewPin:'',
            secret:true,
            writeMode:'',
            alert:{
                wrong_oldPin:false,
                wrong_new:false,
                changeSuccess:false
            }
        }
    }
    _handleChangePassword(){
        let payload={
            "paymentPin":this.state.oldPin
        }
        this.props.confirm_pin(payload);
    }

    componentWillReceiveProps(nextProps){
        const {user,err_CONFIRM_PIN} =this.props;

        if(nextProps.user.err_CONFIRM_PIN && nextProps.user.err_CONFIRM_PIN !=user.err_CONFIRM_PIN){
             if(nextProps.user.err_CONFIRM_PIN.data && nextProps.user.err_CONFIRM_PIN.data.message==="invalid_pin"){
                this.setState({
                    alert:{...this.state.alert,wrong_oldPin:true},
                    writeMode:keyword('password-is-incorrect')?keyword('password-is-incorrect'):"Invalid password"
                });
            }else{
                
               
            }
        }
        if(nextProps.user.confirm_PIN &&nextProps.user.confirm_PIN !=user.confirm_PIN  ){
            if(nextProps.user.confirm_PIN.message==='success' ){
                if(this.state.newPin!=this.state.comNewPin){
                    this.setState({
                        alert:{...this.state.alert,wrong_oldPin:true},
                        writeMode:keyword('confirm-new-password-is-incorrect')?keyword('confirm-new-password-is-incorrect'):"Invalid new password"
                    });
                }else{
                    let payload={
                        "newPin":this.state.newPin
                    }
                    this.props.addNew_pin(payload);
                }
            }
        }
        if(nextProps.user.add_new_pin && nextProps.user.add_new_pin!=user.add_new_pin){
            if(nextProps.user.add_new_pin.message==="success"){
                this.setState({
                    alert:{...this.state.alert,changeSuccess:true},
                    writeMode:keyword('code-reset-successful')?keyword('code-reset-successful'):"Reset successful"
                });
            }
        }
    }
    render() {
        const { show,onHide}=this.props;
        var bgP=setColor('primaryBackground');
        var colP=setColor('primaryColor');
        var imageLogo=setLayout('logo');
        return (
            <div>
            <Modal
                className="changepasword"
                show={show}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="control_changePassword">

                
                <div className="header_changePassword">
                    <img src={imageLogo?LOGO_IMAGE_URL+imageLogo:NOTIMAGE} id="img_logo_changePassword"/>
                    <CloseOutlined id="iconCloseChangePassword" onClick={onHide}/>
                </div>
                <div className="changepasword_div">
                    <h1>{keyword('enter-current-password')?keyword('enter-current-password'):"Enter current password"}</h1>
                    <Row style={{padding:'0px 10%'}}>
                            <PinInput 
                            length={6} 
                            type="numeric"
                            onChange={(value, index) => {
                                this.setState({oldPin:value})
                            }} 
                            secret={this.state.secret}
                            inputMode="number"
                           
                            />
                        </Row>
                    <h1>{keyword('enter-new-password')?keyword('enter-new-password'):"Enter new password"}</h1>
                    <Row style={{padding:'0px 10%'}}>
                            <PinInput 
                            length={6} 
                            secret={this.state.secret}
                            onChange={(value, index) => {
                                this.setState({newPin:value})
                            }} 
                            type="numeric" 
                            />
                        </Row>
                    <h1>{keyword('enter-confirm-new-password')?keyword('enter-confirm-new-password'):"Enter confirm new password"}</h1>
                    <Row style={{padding:'0px 10%'}}>
                            <PinInput 
                            length={6} 
                            onChange={(value, index) => {
                                this.setState({comNewPin:value})
                            }} 
                            type="numeric" 
                            secret={this.state.secret}
                            />
                        </Row>
                </div>
                <div style={{widht:'100%',textAlign:'right'}}>
                            <button id="showPassword" onClick={()=>{this.state.secret?this.setState({secret:false}):this.setState({secret:true})}}>
                                {
                                    this.state.secret?
                                    <Visibility id="icon_Visibility"/>
                                    :
                                    <VisibilityOff id="icon_Visibility"/>
                                }
                                
                                <h6 style={{display:'inline',color:bgP}}>{keyword('word-show-password')?keyword('word-show-password'):"Show password"}</h6>
                        </button>
                        
                    </div>
                <div className="div_btn_submit_chage_password">
                    {
                        this.state.oldPin.length===6 
                        &&
                        this.state.newPin.length===6
                        &&
                        this.state.comNewPin.length===6 
                        ?
                        <button id="btn_submit_chage_password" style={{backgroundColor:bgP,color:colP}} onClick={()=>{this._handleChangePassword()}}>
                           {keyword('ok')?keyword('ok'):"OK"}
                        </button>
                        :
                        <button id="btn_submit_chage_password_disable" style={{backgroundColor:"GrayText"}} disabled>
                            {keyword('ok')?keyword('ok'):"OK"}
                        </button>
                    }
                   
                </div>
                </div>
            </Modal>
            <Modal
                    className="falseVerify"
                    show={this.state.alert.wrong_oldPin} 
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    className="modelDeleteCart"
                    >
                    <Modal.Body style={{textAlign:'center'}}>
                    <ErrorOutline style={{fontSize:100,color:'red'}}/>
                    <br/>
                    <h1>{this.state.writeMode} !</h1>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        <button id="btnHide_change_password_error" 
                            style={{backgroundColor:bgP,color:colP}}
                            onClick={ 
                                this.state.alert.changeSuccess===false?
                                ()=>{
                                    this.setState({
                                        alert:{...this.state.alert,wrong_oldPin:false}
                                    })
                                }
                               :
                                onHide
                            }
                        > {keyword('ok')?keyword('ok'):"OK"} </button>
                    </Modal.Footer>
            </Modal>
            <Modal
                    show={this.state.alert.changeSuccess} 
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    className="modelDeleteCart"
                    style={{backgroundColor:'gray'}}
                    centered
                    >
                    <Modal.Body style={{textAlign:'center'}}>
                    <img src={GIF.done_add_tocart} id="image_gif_done_added_tocart"/>
                    <br/>
                    <h1>{this.state.writeMode} !</h1>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        <button id="btnHide"
                            style={{color:"black"}}
                            onFocus={onHide}
                            onClick={ 
                                ()=>{
                                    this.setState({
                                        alert:{...this.state.alert,changeSuccess:false}
                                    })
                                }
                                
                            }
                        > {keyword('ok')?keyword('ok'):"OK"} </button>
                    </Modal.Footer>
            </Modal>
            </div>
        )
    }
}
