import React from 'react';
import ReactDOM from "react-dom";
import App from './App';
import store from "./store";
import { Provider } from "react-redux";
import {Router} from 'react-router'
import {persistor} from './store'
import * as serviceWorker from './serviceWorker';
import {PersistGate} from 'redux-persist/integration/react'

const createHistory = require("history").createBrowserHistory;
const history = createHistory();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <div>
            <App />
          </div>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();