import { connect } from "react-redux";
import About from "../Screen/About"

const mapStateToProps = state => ({
  ...state,
 
});

const mapDispatchToProps = {

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(About);
