import React, { Component } from 'react'
import NavigationContainer from '../containers/navigationContainer'
import '../Style/chat.css'
import {Link} from 'react-router-dom'
import {Store,Search,FavoriteBorder,Loyalty,Clear,Edit,Close, ViewDay} from '@material-ui/icons'
import {Tab,Row,Col,Nav,Tooltip,OverlayTrigger,Container,Modal} from 'react-bootstrap'
import {Digital} from 'react-activity';
import {socket} from './../store';
import moment from 'moment';
import 'moment/locale/km';
import $ from 'jquery'
import {STORE_URL_IMAGE} from '../modules/app/config'
import {store} from '../store/index'

export default class AddToCard extends Component {
    constructor(props){
        super(props);
        this.state={
            ui:{
                show_all_chat:false,
            },
            idShopSeleted:0,
            Not_seen:false,
            userListMessage: [],
            messages:[],
            textChat:"",
            valueUserSeleted:"",
            dataShop:{},
        }
    }
   
    componentDidMount(){
        const {chat} = this.props;
        var userListMessage=store.getState();
        const { state } = this.props.location;
        if(this.props.location.state){
            if(userListMessage){
                let idshopAlready=userListMessage.chat.userListMessage.find((number)=> {if(number['_id']===state['IDshop'])return number});
                if(idshopAlready['_id']===state['IDshop']){
                    var data=JSON.stringify(idshopAlready);
                    this.handleSelectUser(data);
                    this.setState({
                        userListMessage:chat.userListMessage,
                        idShopSeleted:state['IDshop']
                    });
                }else{
                        var data={
                            datetime: new Date(),
                            image: state['ImageShop'],
                            message: JSON.stringify(
                                [{
                                    "text": "",
                                    "user":{
                                        "name":state['NameShop'],
                                        "_id":state['IDshop'],
                                        "image":state['ImageShop'],
                                        "socket": null
                                        },
                                    "createdAt":new Date()}]),
                            name: state['NameShop'],
                            socket: null,
                            _id:state['IDshop']
                        }
                        var aa=chat.userListMessage;
                            var ab=[];
                            ab.push(data);
                            aa.map((item)=>{
                                ab.push(item);
                            });
                        this.setState({"userListMessage":ab});
                }
                
            }
        }
        else{
            if(userListMessage.chat.selected_user){
                var cc=JSON.parse(userListMessage.chat.selected_user)._id;
                if(cc){
                    let idshopAlready=userListMessage.chat.userListMessage.find((number)=> {if(number['_id']===cc)return number});
                    var data=JSON.stringify(idshopAlready);
                    this.handleSelectUser(data);
                }
            }
            this.setState({
                userListMessage:chat.userListMessage,
                idShopSeleted:0
            });
        }
        //get No seen from stote bc it not change where refresh
        var allState=store.getState();
        // var aa =JSON.parse(allState.chat['selected_user'])._id;
        // this.setState({
        //     Not_seen:allState.chat.iduser_new_chat,
        //     // idShopSeleted:aa
        // });
        console.log(allState)
    }
    componentWillReceiveProps(nextProps){
        const {chat} = this.props;
        const { state } = this.props.location;
        if(nextProps.chat.selected_user && nextProps.chat.selected_user!=chat.selected_user){
            this.setState({
                idShopSeleted:JSON.parse(nextProps.chat.selected_user)._id
            })
        }
        if(nextProps.chat.userListMessage && nextProps.chat.userListMessage != chat.userListMessage){
        console.log('userLis',nextProps.chat.userListMessage)
           
            this.setState({
                userListMessage:nextProps.chat.userListMessage
            });
        }
        if(nextProps.chat.messages && nextProps.chat.messages != chat.messages){
            if(nextProps.chat.iduser_new_chat && nextProps.chat.iduser_new_chat===this.state.idShopSeleted){
               this.setState({
                    textChat:"",
                    messages:nextProps.chat.messages,
                });
            }
            else if(nextProps.chat.messages && nextProps.chat.messages.length>0){
                this.setState({
                    textChat:"",
                    messages:nextProps.chat.messages,
                });
            }
            else
            {
                   this.setState({
                    textChat:"",
                    messages:[],
                });
            }
        }
        
        if(nextProps.chat.iduser_new_chat && nextProps.chat.iduser_new_chat !=this.state.Not_seen){
            // var allState=store.getState();
            console.log(nextProps.chat.iduser_new_chat,'-----',chat.iduser_new_chat)
            this.setState({
                Not_seen:nextProps.chat.iduser_new_chat
            })
        }
        $("#content-chat").animate({ scrollTop: $("#content-chat").height() }, 500);
        $("body").animate({ scrollTop: $(document).height() }, 500);
    }

    handleSelectUser(value){
        console.log('vae',value)
        var idSelectShop=JSON.parse(value);
        this.setState({valueUserSeleted:value,idSelectShop:idSelectShop._id});
        if(value){
            this.props.selectUserChat(value);
        }
        setTimeout(()=>{ window.scrollTo(0,document.body.scrollHeight);},3000)
    }
 
    _loopChat(data){
        var rs=[];
        if(data && data.length > 0 ){
            data.map((item,indx)=>{
               rs.push( 
                    <div data-id={item['_id']}  key={indx} onClick={()=>{this.handleSelectUser(JSON.stringify(item))}}>
                         <div style={this.state.idShopSeleted===item['_id']?{backgroundColor:'#eceff1'}:
                            (item['not_seen']===true)?
                            {
                                fontWeight:'bold'
                            }:null
                         } class="list-group-item  rounded-0 one_say_message" >
                            <div class="media">
                                <img src={STORE_URL_IMAGE+item.image} alt="user" width="50" class="rounded-circle"/>
                                <div class="media-body ">
                                <div class="d-flex align-items-center justify-content-between mb-1" >
                                    <h6 class="mb-0"
                                     style={ (item['not_seen']===true)?
                                     {
                                        color:'red',
                                        fontWeight:'bolder'
                                     }:null}
                                    >{item.name}</h6><small class="small font-weight-bold class_date_shop_chat">{moment(item.datatime).format("YYYY/MM/DD")}</small>
                                </div>
                                <p class="mb-0 text-small">{JSON.parse(item.message)[0].text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )   
            })
        }
        else {
            rs.push(<h1>មិនមានសារ</h1>)
        }
        return rs;
    }
    _loopOneOneMessage(data){
        var rs=[];
        if(data && data.length > 0){
            rs.push(<div class="hearder_oneMessage"></div>)
            data.map((item,idx)=>{
                if(this.props.chat.dataSocket && item.user._id != this.props.chat.dataSocket._id){
                   //shop
                    rs.push(
                        <div class="row no-gutters" >
                            <div class="col-xl-5 col-lg-8 col-md-8 col-sm-10 col-xs-10 ">
                            <div class="chat-bubble chat-bubble--left">
                                {
                                    item.text
                                }
                                <div className="display_statur">
                                    <p>{item.createdAt}
                                    </p>
                                </div>
                            </div>
                            </div>
                        </div>
                           
                    )
                    
                }else{
                    
                     //user
                    rs.push(
                        <div class="row no-gutters">
                            <div class="col-xl-5 offset-xl-7 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-10 offset-sm-2 col-xs-10 offset-xs-10" onClick={()=>{this._handleUi('show_all_chat',false)}}>
                                <div class="chat-bubble chat-bubble--right">
                                    {
                                        item.text
                                    }
                                <br/>
                                <div className="display_statur">
                                    <p>{moment(item.datatime).format("YYYY/MM/DD")} 
                                        {item.received &&
                                             <span>មើល<i class='fas fa-check'/><i class='fas fa-check'></i></span>
                                        }
                                    </p>
                                </div>
                             
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        }else{
            rs.push(<div>មិនមានសារ</div>)
        }
        return rs;
    }
    _handleSend(){
        const {messages,valueUserSeleted,userListMessage,textChat}= this.state
        var dataToSend=[{
            createdAt: new Date(),
            text: textChat,
            user:this.props.chat.dataSocket,
        }]
        if(textChat!=""){
            this.props.onSendMessage(dataToSend);
        }
        $("#content-chat").animate({ scrollTop: $("#content-chat").height() }, 500);
        $("body").animate({ scrollTop: $(document).height() }, 500);
    }
    _handleUi(key,value){
        this.setState({
            ui:{...this.state.ui,[key]:value}
        })
    }
    create_new_chat(){
       
    }
    
    render(){
        const {userListMessage, messages} = this.state;
        return(
            <div>
                <NavigationContainer/>
                <Row className="big_chat">
                    <Col xl={4} lg={4} md={4} sm={5} className="groun_shOw_chat" >
                       <div className="test_ling">
                            {this._loopChat(userListMessage)}
                       </div>
                       
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={7}  className="_lala">
                        <Row className="header_chating">
                            <Col xl={4} lg={4} md={4} sm={5}  className="header_all_user_chated">
                                
                            </Col>
                            <Col xl={8} lg={8} md={8}  sm={7} className="header_chating_ing">
                                <div className="g_pro_shop">
                                 <img src="https:\\res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg" alt="user" class="rounded-circle im_pf_sh"/>
                                 <p>HELLO</p>
                             </div>
                            </Col>
                        </Row>
                        <div className="hello_chatint">
                            {
                                userListMessage.length===0 &&<Digital color="#727981" size={20} speed={1} animating={true} />
                            }
                            {
                                 this.state.idShopSeleted!=0 &&this._loopOneOneMessage(messages)
                            }
                        </div>
                        {
                            this.state.idShopSeleted!=0 &&
                            <div class="fix_inpunt">
                            <div class="input-group ">
                                <input type="text" placeholder="Type a message" aria-describedby="button-addon2" 
                                      value={this.state.textChat}
                                      onChange={(value, index) => {
                                          this.setState({
                                              textChat:value.target.value
                                          })
                                      }} 
                                      onKeyDown={(e)=>{
                                        if(e.keyCode === 13){
                                            this._handleSend()
                                        }
                                     }}
                                      />
                                      <button id="button-addon2" type="button" onClick={()=>{this._handleSend()}}> ផ្ញើរសារ<i class="fa fa-paper-plane"></i></button>
                                  </div>
                              </div>
                        }
                       
                    </Col>
                </Row>

                {/* <div className="small_chatning">
                     <Tab.Container defaultActiveKey="shops">
                     <div className="left_tab_chating">
                     <Nav variant="pills" className="lala_hehe">
                             <Nav.Item id="nav_shop">
                                 <Nav.Link eventKey="shops" >
                                        <i class='fas fa-users'></i>
                                 </Nav.Link>
                             </Nav.Item> 
                         </Nav>
                     </div>
                 </Tab.Container>
                   </div> */}
            </div>
        )
    }


}