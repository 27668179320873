import { connect } from "react-redux";
import Shops from "../Screen/shops"
import {getStores,followedStores,followStore} from '../modules/shop/reducer'
const mapStateToProps = state => ({
  ...state,
  error_getStores:state.shopData.error_getStores,
  error_getFollowedStores:state.shopData.error_getFollowedStores
});

const mapDispatchToProps = {
  getStores,
  followedStores,
  followStore
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Shops);
