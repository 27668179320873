import React, { Component } from 'react';
import {Row,Col} from 'react-bootstrap'
import '../Style/Component/productRecommend.css'
import {ChevronLeft,ChevronRight} from '@material-ui/icons'
import RecommandProds from '../Data/recommandProd.json'
import {PROUDCT_URL_IMAGE} from '../modules/app/config'
import transitions from '@material-ui/core/styles/transitions';
import Slider from "react-slick";
import {NOTIMAGE} from '../Assets/images'
export default class ProductRecommand extends Component {
    constructor(props){
        super(props);
        this.state={
            index:0,
            data:[]
        }
    }
    componentDidMount(){
        const product = this.props;
 
    }
    _loopSlider=()=>{
    const slids=this.props.products;
    var rs=[];
    if(slids){
        slids.map((sl,indx)=>{
            rs.push(
                <div key={indx} className="div_control_image">
                    <img className="imge_product_story" src={sl['image']?PROUDCT_URL_IMAGE+sl['image']:NOTIMAGE}  width='100%' height='100%'/>
                </div>
            )
        })
    }
    return rs;
    }
  render() {
    const {products} =this.props;
    const settings = {
        dots:true,
        lazyLoad: 'ondemand',
        slidesToShow:2,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:(<button type="button"  id="prev_store_stories">Previous</button>),
        nextArrow: (<button  type="button" id="next_store_stories">Previous</button>),
        infinite: false,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                  slidesToShow:2,
                  slidesToScroll: 1
                }
              },
            {
              breakpoint: 991,
              settings: {
                slidesToShow:1,
                slidesToScroll: 1
              }
            },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow:2,
                  slidesToScroll: 1
                }
              },
        ]
    };
    return (
        <div>
        
             <Slider {...settings}>
             {
                 this._loopSlider()
             }
            </Slider>
        
        </div>
        
    );
  }
}
