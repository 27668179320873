import { routerReducer } from "react-router-redux";
import appReducer from "../modules/app/reducer";
import userReducer from "../modules/user/reducer";
import chatReducer from '../modules/chat/reducer'


import AddressData from '../modules/address/reducer'
import productData from '../modules/product/reducer'
import shopData from '../modules/shop/reducer'
export default {
  app: appReducer,
  user: userReducer,
  router: routerReducer,

  AddressData:AddressData,
  productData:productData,
  shopData:shopData,
  chat:chatReducer
};
