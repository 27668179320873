import { connect } from "react-redux";
import LoginFormComponent from "../Screen/login"
import {appWillRefresh} from '../modules/app/reducer'
import {
  userLoginSMS,userLogin
} from '../modules/user/reducer'
import{
  getColor,
  getSiteDescription
}from '../modules/product/reducer'
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = {
  userLoginSMS,
  userLogin,
  appWillRefresh,
  getColor,
  getSiteDescription
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormComponent);
