import store from '../store/index'
export function setColor(type){
    var storeData = store.getState();
    if(storeData && storeData['productData'] && storeData['productData']['color'] &&  storeData['productData']['color'].results &&  storeData['productData']['color'].results.length>0){
        if(type==="primaryBackground"){
            return storeData['productData']['color'].results[0]['primaryBackground'];
        }else if(type==="primaryColor"){
            return storeData['productData']['color'].results[0]['primaryColor'];
        }else if(type==="lightBackground"){
            return storeData['productData']['color'].results[0]['lightBackground'];
        }else if(type==="lightColor"){
            return storeData['productData']['color'].results[0]['lightColor'];
        }else if(type==="darkBackground"){
            return storeData['productData']['color'].results[0]['darkBackground'];
        }else if(type==="darkColor") {
            return storeData['productData']['color'].results[0]['darkColor'];
        }else{
            return 
        }
    }
}


export function setLayout(type){
    var storeData = store.getState();
    if(storeData && storeData['productData'] && storeData['productData']['sitedescription'] && storeData['productData']['color'].results && storeData['productData']['color'].results.length>0){
        if(type==="logo"){
            if(storeData['productData']['sitedescription']['results'].length>0){
                return (storeData['productData']['sitedescription']['results'][0]['logo']?storeData['productData']['sitedescription']['results'][0]['logo']:false)
            }else{
                return false
            }
        }else if(type==="copyRight"){
            return (storeData['productData']['sitedescription'].results[0]['copyRight'])
        }else if(type==="address"){
            return (storeData['productData']['sitedescription'].results[0]['address'])
        }else if(type==="email"){
            return (storeData['productData']['sitedescription'].results[0]['email'])
        }else if(type==="phone"){
            return (storeData['productData']['sitedescription'].results[0]['phone'])
        }else if(type==="layout"){
            return (storeData['productData']['sitedescription'].results[0]['layout'])
        }
    }else{
        return
    }
}

export function numberCountCart(){
    var storeData = store.getState();
    if(storeData && storeData['user'] && storeData['user']['cartList'] && storeData['user']['cartList'] && storeData['user']['cartList']['results'].length>0){
        return storeData['user']['cartList']['results'].length
    }else{
        return 0
    }
}

//  primaryBackground
//  primaryColor
//  lightBackground

// lightColor
// darkBackground
// darkColor