import { connect } from "react-redux";
import Home from "../Screen/HomepageNew"
import {
    homeProduct,
    productSKU,
    userLikeShare,
    getPorductCategory,
    
      } from '../modules/product/reducer'
import {
  addCart,setNotification,
  getCartList
} from '../modules/user/reducer'
import {
  storeStories
} from '../modules/shop/reducer'
const mapStateToProps = state => ({
  ...state,
});
const mapDispatchToProps = {
  homeProduct,
  productSKU,
  userLikeShare,
  setNotification,
  getPorductCategory,
  addCart,
  storeStories,
  getCartList
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
