import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

export default class Metatages extends Component {
     constructor(props){
        super(props);
        this.state = { 
            main_page: "",
            main_id: "",
            main_cate: "",
            site_title: "Kuanghsien"
        }
    }
    componentDidMount(){
        const location=this.props
        if(location.pathname === "/#prd_foryou?"){
            this.setState({
                main_page:"/homepage"
            })
        }else{
            var aa=location.location.pathname;
            this.setState({
                main_page:aa.replace(/\//g, "-")
            })
        }
    }
    componentWillReceiveProps(nextProps, nextState){
        const {location} = this.props;
        if(nextProps.location && nextProps.location != location){
            if(nextProps.location.pathname === "/#prd_foryou?"){
                this.setState({
                    main_page:"/homepage"
                })
            }else{
                this.setState({
                    main_page:nextProps.location.pathname.replace(/\//g, "-")
                })
            }
        }
    }
    render() {
        const {site_title, main_page} = this.state;
        const {location} = this.props;
        return (
            <MetaTags>
                <title>{site_title+main_page}</title>
                <link rel="icon"/>
     
            </MetaTags> 
        );
    }
}
