import { connect } from "react-redux";
import Dailog_addTocart from "../Component/dailog_addTocart";
import { 
  productSKU ,
  getProductPriceSKU,

} from '../modules/product/reducer';
import {
  addCart
} from '../modules/user/reducer'
const mapStateToProps = state => ({
  ...state,
  skuPrice:state.productData.priceSKU
});

const mapDispatchToProps = {
  productSKU,
  getProductPriceSKU,
  addCart
 }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dailog_addTocart);
