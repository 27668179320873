import React, { Component } from 'react'
import {Modal, Spinner} from 'react-bootstrap'
import 'react-activity/dist/react-activity.css';
import { Bounce ,Levels} from 'react-activity';
import "../Style/Component/loading.css"
import "../Assets/color.css"
export default class Loading extends Component {
    render() {
        return(
            <div className="loadingNig">
                <Bounce 
                    color="grey" 
                    size={20} 
                    speed={1} 
                    // animating={true} 
                    />
            </div>
           );
    }
}
