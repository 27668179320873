import { connect } from "react-redux";
import Chating from "../Screen/Chat"
import {
  selectUserChat,
  onSendMessage,
} from '../modules/chat/reducer'

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  selectUserChat,
  onSendMessage,
  
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Chating);
