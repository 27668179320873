import React, { Component } from 'react'
import {Row, Container} from 'react-bootstrap'
import '../Style/font.css';
import '../Style/Component/title.css';


export function NewTitle(getTitle){
    return(
        <div style={{textAlign:'center'}}>
            <label className="label_titel_font_new">
                <div className="control_label_newTitle">
                   *
                    <label id="lblTitle_new">{getTitle}</label>
                    *
                </div>
            </label>
        </div>
        );
}
