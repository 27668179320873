import React, { Component } from 'react'
import '../Style/Component/cofirmPin.css'
import {Row,Col,Container, Nav,Modal, Table} from 'react-bootstrap';
import PinInput from 'react-pin-input'
import {NOTIMAGE,LOGO} from '../Assets/images'
import {ErrorOutline} from '@material-ui/icons'
import {setColor } from '../utils/colors'
import {keyword} from '../utils/keywords'
import amount from '../utils/fomartAmount'
export default class ComfrimPin extends Component {
    constructor(props){
        super(props);
        this.state={
            arrayPin:'',
            wrongPassword:false,
            writeMode:''
        }
    }
    _comfirmPin(pin){
        if(pin.length===6){
            var data={
                        "paymentPin":pin
                    }
            this.props.confirm_pin(data);
        }
    }
    componentDidMount(){
        
    }
    componentWillReceiveProps(nextProps){
        const {user,err_CONFIRM_PIN,dataCheckOut} =this.props;

        if(nextProps.user.err_CONFIRM_PIN && nextProps.user.err_CONFIRM_PIN !=user.err_CONFIRM_PIN){
            if(nextProps.user.err_CONFIRM_PIN.data && nextProps.user.err_CONFIRM_PIN.data.message==="invalid_pin"){
                this.setState({
                    wrongPassword:true,
                    writeMode:keyword('password-is-incorrect')?keyword('password-is-incorrect'):"Password is invalid"
                });
            }else{
                this.setState({
                    wrongPassword:true,
                    writeMode:keyword('connection-problem')?keyword('connection-problem'):"connection problem"
                });
            }
        }
    }
    render() {
        const {show,total,onHide} =this.props;
        let bgP=setColor('primaryBackground');
        let colP=setColor('primaryColor');
        let colL=setColor('lightColor');
        let bgL=setColor('lightBackground');
        return (
            <div>
                <Modal
                    className="newPincode"
                    show={show}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >
                    <img src={LOGO} id="img_logo_comfirmPin"/>
                    <div className="total_comfirmPin">
                        <h5>{keyword('money-amount')?keyword('money-amount'):"Amount"}</h5>
                        <h2>$ {total>0?amount(total):"0"}</h2>
                    </div>
                    <div className="model_body_verifyPass">
                        <h6 style={{textAlign:'center'}}>{keyword('enter-account-password')?keyword('enter-account-password'):"Input password"}</h6>
                        <Row style={{padding:'0px 10%'}}>
                            <input
                            id="input_comfirm_pay_end"
                            placeholder={keyword('enter-account-password')?keyword('enter-account-password'):"Input password"}
                            type="password"
                            maxlength="6"
                            onChange={(value, index) => {
                                      this.setState({
                                            arrayPin:value.target.value
                                    })
                                  }} 
                            />
                        </Row>
                    </div>
                    <Modal.Footer className="model_foolter_comfirmPin">
                        {
                            this.state.arrayPin.length===6?
                            <button id="yes_ComfirmPin" style={{backgroundColor:bgP,color:colP}} onClick={()=>{this._comfirmPin(this.state.arrayPin)}}>{keyword('ok')?keyword('ok'):"OK"}</button>:
                            <button id="Yes_ComfirmPinDisable" disabled>{keyword('ok')?keyword('ok'):"OK"}</button>
                        }
                            <button id="No_ComfirmPin" onClick={onHide}>{keyword('cancel')?keyword('cancel'):"Cancel"}</button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    animation={false}
                    show={this.state.wrongPassword} 
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    className="mode_wrongPassword_confirmPin"
                    >
                    <Modal.Body style={{textAlign:'center'}}>
                    <ErrorOutline id="anig_icon_error"/>
                    <br/>
                    <h1>{this.state.writeMode} !</h1>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        <button id="btnHide" onClick={()=>{this.setState({wrongPassword:false})}}>{keyword('word-wait-for-review')?keyword('word-wait-for-review'):"Review"}</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
