import React,{Component} from 'react';
import {BrowserRouter as Router, Route, Switch ,Redirect} from 'react-router-dom';
import About from './containers/about'
import Profile from './containers/ProfileContainer';
import AddToCart from './containers/AddToCartContainer';
import DetialProduct from './containers/DetailProductContainer';
import NotFoundPage from './Screen/NotFountPage';
import LoginForm from './containers/LoginFormContainer';
import HelperPage from './Screen/Helper';
import matatage from './Screen/metatages'
import HomepageNew from './containers/HomeContainerNew'
import {APP_REFRESH,CHENGE_TITLE} from './modules/app/reducer'
import store from './store'
import Shop from './containers/shopContainer'
import DetailShop from './containers/detailShopContainer'
import FilterCategory from './containers/filterCategoryContainer'
import Search from './containers/searchContainer'
import Sharing from '../src/Component/shearing'
import {appRefresh} from '../src/modules/app'
import Chating from './containers/chatContainer'
import Primacy from '../src/Screen/PrimacyPolicy'
export var COLOR_SERVER="";


export default class App extends Component {
  componentWillMount(){
    if (window.performance) {
      if (performance.navigation.type == 1 || performance.navigation.type === 0) {
          store.dispatch({ type: CHENGE_TITLE });
          store.dispatch({ type: APP_REFRESH });
        }
    }
    if(!localStorage.getItem('amatakUserAddress')){
      localStorage.setItem('amatakUserAddress',JSON.stringify([]));
    }
  }

  
  
  render() {
    return (
      <div>
        {/* <Sharing></Sharing> */}
        <Router>
          <Route  component={matatage}/>
            {
            !localStorage.getItem('persist:amatakWebsite') 
            && 
            <Redirect to="/"></Redirect>}
            
            <Switch>
                <Route exact path="/about" component={About}/>
                <Route exact path="/login" component={LoginForm}/>
                <Route exact path="/detailproduct/:alias" component={DetialProduct}/>
                <Route exact path="/#prd_foryou?" component={HomepageNew}/>
                <Route exact path="/" component={HomepageNew}/>
                <Route exact path="/addtocart" component={AddToCart}/>
                <Route exact path="/help" component={HelperPage}/>
                <Route exact path="/profile" component={Profile}/>
                <Route exact path="/shop" component={Shop}/>
                <Route exact path="/detailShop/:storeAlias" component={DetailShop}/>
                <Route exact path="/oneCategory/:idCategory?/" component={FilterCategory}/>
                <Route exact path="/search/:stringSearch?" component={Search}/>
                {/* <Route exact path="/message" component={Chating}/> */}
                <Route exact path="/primacypolicy" component={Primacy}/>
                <Route component={NotFoundPage} />
                <Route path='*' exact={true} component={NotFoundPage} />
            </Switch>
        </Router>
      </div>
    )
  }
}






