import React, { Component } from 'react';
import { Container, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { LOGO_APP } from '../Data/fixdata'
import { Link, Redirect } from 'react-router-dom';
import PinInput from 'react-pin-input'
import { ICONS, IMAGES } from '../Assets/images'
import '../Style/loginStyle.css'
import '../Assets/color.css'
import GoogleLogin from 'react-google-login';
import { ErrorOutline } from '@material-ui/icons';
import { Digital } from 'react-activity';
import Loading from '../Component/loading'
import {
  Dailog,
} from '../Component/dailog'
import FacebookLogin from 'react-facebook-login';
import { setLayout, setColor } from '../utils/colors'
import { LOGO_IMAGE_URL } from '../modules/app/config'
import { fileURLToPath } from 'url';
import { keyword } from '../utils/keywords'
export default class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableinput: 0,
      data: {
        phoneNumber: '',
        verifyCode: '',
        pushyToken: '',
        userName: '',
        typeLogin: 'phone',
        userFacebook: '',
        userGoogle: '',
      },
      ui: {
        checkOut_Login: false,
        loginAlready: false,
        newUser: false,
        errorPhoneNumber: false,
        messageErrorPhoneNumser: '',
        loaddingLegleg: false
      }
    }

    if (localStorage.getItem('persist:amatakWebsite')) {
      const user_sms = JSON.parse(localStorage.getItem('persist:amatakWebsite'));
      const login_sms = JSON.parse(user_sms.user);
      if (login_sms.login && login_sms.login.message === "success") {
        if (login_sms.login && login_sms.login.token) {
          window.location.assign('/');
        }
      }
      else {
        return
        // window.location.assign('/login');
      }
    }

  }

  componentDidMount() {
    localStorage.setItem('@UserAddress-Amatak', '');
    this.props.getColor();
    this.props.getSiteDescription();
  }
  componentDidUpdate(NextProps) {
    const { user } = this.props;


  }

  componentWillReceiveProps(NextProps) {
    try {
      const { user } = this.props;
      if (NextProps.user.login_sms && NextProps.user.login_sms != user.login_sms) {
        if (NextProps.user.login_sms.message === 'success') {
          if (this.state.data.typeLogin === 'facebook') {
            var userFacebook = this.state.data.userFacebook;
            var this_ = this
            const proxyurl = "https://cors-anywhere.herokuapp.com/";
            var xhr = new XMLHttpRequest();
            xhr.open("GET", proxyurl + userFacebook.picture.data.url, true);
            xhr.responseType = "blob";
            xhr.onload = function (e) {
              var reader = new FileReader();
              reader.onload = function (event) {
                var res = event.target.result;
                const data = {
                  facebookID: userFacebook.id,
                  newUser: NextProps.user.login_sms.newUser,
                  fullName: userFacebook.name,
                  pushyToken: '',
                  newImageData: res
                }
                this_.props.userLogin(data);
              }
              var file = this.response;
              reader.readAsDataURL(file)
            };
            xhr.send();
          }
          else if (this.state.data.typeLogin === 'google') {
            var userGoogle = this.state.data.userGoogle;
            // console.log(userGoogle)
            var this_ = this
            const proxyurl = "https://cors-anywhere.herokuapp.com/";
            var xhr = new XMLHttpRequest();
            xhr.open("GET", proxyurl + userGoogle.profileObj.imageUrl, true);
            xhr.responseType = "blob";
            xhr.onload = function (e) {
              var reader = new FileReader();
              reader.onload = function (event) {
                var res = event.target.result;
                const data = {
                  googleID: userGoogle.googleId,
                  newUser: NextProps.user.login_sms.newUser,
                  fullName: userGoogle.profileObj.name,
                  pushyToken: '',
                  newImageData: res
                }
                this_.props.userLogin(data);
              }
              var file = this.response;
              reader.readAsDataURL(file)
            };
            xhr.send();
          }
          else {
            this.setState({
              disableinput: 1,
              ui: {
                ...this.state.ui,
                newUser: NextProps.user.login_sms.newUser,
                checkOut_Login: true,
              }
            });
          }
        }

      }
      if (NextProps.user.login && NextProps.user.login != user.login) {
        if (NextProps.user.login.message === 'success') {
          this.setState({
            ui: { ...this.state.ui, loginAlready: true }
          });
          window.history.back();
          //window.location.assign('/#?');
        }
      }
      if (NextProps.user.errorLogin && NextProps.user.errorLogin != user.errorLogin) {
        if (NextProps.user.errorLogin.data.message === 'invalid_sms') {
          this.setState({
            ui: {
              ...this.state.ui,
              errorPhoneNumber: true,
              messageErrorPhoneNumser: keyword('password-is-incorrect') ? keyword('password-is-incorrect') : "Wrong password"
            }
          });
        }
      }

    } catch (e) {
      alert('errorLoginForm')
    }

  }
  _handleData(key, value) {
    this.setState({
      data: { ...this.state.data, [key]: value }
    })
  }
  _verifyLogin() {
    var smsCode = this.state.data.verifyCode;

    if (smsCode.length < 6) {
      this.setState({
        ui: {
          ...this.state.ui,
          errorPhoneNumber: true,
          messageErrorPhoneNumser: keyword('please-check-your-message-for-the-6-digit-code') ? keyword('please-check-your-message-for-the-6-digit-code') : "Please inpute sms code!"
        }
      })
    } else {
      var fullMobileNumber = this.state.data.phoneNumber;
      var smsCode = this.state.data.verifyCode;
      var newUser = this.state.ui.newUser;
      var fullName = this.state.data.userName;
      if (newUser === true) {
        var data = {
          fullMobileNumber,
          smsCode,
          fullName,
          newUser
        }
      } else {
        var data = {
          fullMobileNumber,
          smsCode,
          newUser
        }
      }
      this.props.userLogin(data);
    }
  }

  _checkNumberPhone() {
    var phoneNumber = document.getElementById('txtPhoneNumber').value;
    if (phoneNumber.length < 9) {
      this.setState({
        ui: {
          ...this.state.ui,
          errorPhoneNumber: true,
          messageErrorPhoneNumser: keyword('please-enter-your-phone-number-correctly') ? keyword('please-enter-your-phone-number-correctly') : "Please input your phone number"
        }
      })
    } else {
      if (phoneNumber[0] === '0') {
        const phoneNumberA = phoneNumber.substr(1, phoneNumber.length - 1);
        const dataPhone = "855" + phoneNumberA;
        this.setState({
          data: {
            ...this.state.data, phoneNumber: dataPhone
          }
        });
        const data = {
          phoneNumber: dataPhone,
          loginType: "phoneNumber"
        }
        this.props.userLoginSMS(data);
      } else {
        const dataPhone = "855" + phoneNumber;
        this.setState({
          data: {
            ...this.state.data, phoneNumber: dataPhone
          }
        });
        const data = {
          phoneNumber: dataPhone,
          loginType: "phoneNumber"
        }
        this.props.userLoginSMS(data);
      }
    }
  }

  responseGoogle = (user) => {
    // console.log('---',user)
    if (user) {
      const data = {
        googleID: user.googleId,
        loginType: "google"
      }
      this.setState({
        data: {
          ...this.state.data,
          userGoogle: user,
          typeLogin: 'google'
        }
      })
      this.props.userLoginSMS(data);
    }
  }
  responseFacebook = (user) => {
    if (user) {
      const data = {
        facebookID: user.id,
        loginType: "facebook"
      }
      this.setState({
        data: {
          ...this.state.data,
          userFacebook: user,
          typeLogin: 'facebook'
        }
      })
      this.props.userLoginSMS(data);
    }
  }
  _loadingLegleg() {
    const { user } = this.props;
    if (user.loading && user.loading === true)
      this.setState({
        ui: { ...this.state.ui, loaddingLegleg: true }
      })
    setTimeout(() => {
      this.setState({
        ui: {
          ...this.state.ui, loaddingLegleg: false,
          // errorPhoneNumber:true,
          // messageErrorPhoneNumser:'ការចូលគណនីមានបញ្ហា​​ សូមព្យាយាមម្តងទៀត!'
        }
      })
    }, 10000);
  }
  render() {
    let bgP = setColor('primaryBackground');
    let colP = setColor('primaryColor');
    let colL = setColor('lightColor')
    let bgL = setColor('lightBackground')
    let imageLogo = setLayout('logo')
    return (
      <div className="login">
        {
          this.state.ui.loginAlready === true
          &&
          <Redirect to="/" />
        }
        {/* {
              (  this.props.user.loading
                && <Loading/>
            )
        } */}
        <Container style={{ textAlign: 'center' }}>
          <label className="background_login" style={{ backgroundColor: bgL }}>
            <div className="formLogin">
              <div className="LoginForm">
                <Link to="/">
                  <img src={LOGO_IMAGE_URL + imageLogo} id="image_logo"></img>
                </Link>
                <br />
                <label className="lbl_create_onInput" style={{ color: colP }} >{keyword('phone-number') ? keyword('phone-number') : 'Phone number'}</label>
                <input
                  disabled={this.state.disableinput}
                  type="phone" id="txtPhoneNumber"
                  style={{ borderColor: colP }}
                  placeholder={keyword('phone-number') ? keyword('phone-number') : 'Phone number'} className="txtinput" required></input>
                <br />

                {
                  // this.props.user.loading && this.props.user.loading===true ?
                  this.state.ui.loaddingLegleg ?
                    <div className="loadingSmall_login">
                      <Digital color="#727981" size={20} speed={1} animating={true} />
                    </div>
                    :
                    this.state.data.phoneNumber != '' && this.state.ui.newUser === false && this.state.ui.checkOut_Login
                      ?
                      <div className="rith____">
                        <label className="lbl_create_onInput" style={{ color: colP }}>{keyword('word-sms-code') ? keyword('word-sms-code') : "SMS Code"}</label>
                        <PinInput
                          length={6}
                          initialValue=""
                          secret={this.state.secret}
                          onChange={(value, index) => {
                            this.setState({
                              data: {
                                ...this.state.data,
                                verifyCode: value
                              }
                            })
                          }}
                          type="numeric"
                          inputMode="number"
                          style={{ padding: '0px 5px', textAlign: 'center' }}
                          inputStyle={{ borderColor: colP, borderRadius: '20px', textAlign: 'center', margin: '0px 5px', fontSize: '30px' }}
                          inputFocusStyle={{ borderColor: 'blue', borderRadius: '20px', textAlign: 'center', fontSize: '30px' }}
                        />
                        <button type="submit" style={{ backgroundColor: bgP, color: colP }} className="btn_continue" onClick={() => { this._verifyLogin() }}>{keyword('contiue') ? keyword('contiue') : "Contiue"}</button>
                      </div>
                      :
                      this.state.data.phoneNumber != '' && this.state.ui.newUser === true && this.state.ui.checkOut_Login ?
                        <div className="rith____">
                          <label className="lbl_create_onInput" style={{ color: colP }}>{keyword('enter-name') ? keyword('enter-name') : "Enter your name"}</label>
                          <input
                            style={{ borderColor: colP }}
                            type="text"
                            placeholder={keyword('enter-name') ? keyword('enter-name') : "Enter your name"}
                            id="txtFullName"
                            className="txtinput"
                            onChange={(value, index) => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  userName: value.target.value
                                }
                              })
                            }}
                            value={this.state.data.userName.toString()}></input>
                          <label className="lbl_create_onInput" style={{ color: colP }}>{keyword('word-sms-code') ? keyword('word-sms-code') : "SMS Code"}</label>
                          <PinInput
                            length={6}
                            initialValue=""
                            secret={this.state.secret}
                            onChange={(value, index) => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  verifyCode: value
                                }
                              })
                            }}
                            type="numeric"
                            inputMode="number"
                            style={{ padding: '0px 5px', textAlign: 'center' }}
                            inputStyle={{ borderColor: colP, borderRadius: '20px', textAlign: 'center', margin: '0px 5px', fontSize: '30px' }}
                            inputFocusStyle={{ borderColor: 'blue', borderRadius: '20px', textAlign: 'center', fontSize: '30px' }}
                          />
                          <button style={{ backgroundColor: bgP, color: colP }} type="submit" className="btn_continue" onClick={() => { this._verifyLogin(); }} >
                            {keyword('ok') ? keyword('ok') : "OK"}
                          </button>
                          <br />

                        </div>
                        :
                        <button type="submit" className="btn_continue" style={{ backgroundColor: bgP, color: colP }} onClick={() => { this._checkNumberPhone(); this._loadingLegleg(); }}>{keyword('contiue') ? keyword('contiue') : "Contiue"}</button>
                }
                {
                }
                {/* <div className="Login_with_socai">
                  <div className="div_icone">
                    <FacebookLogin
                      appId="272282387539672"
                      autoLoad={false}
                      fields="name,address,birthday,gender,picture"
                      scope={'public_profile'}
                      cssClass="my-facebook-button-class"
                      textButton={''}
                      icon={<img src={ICONS.facebook} className="login_facebook" />}
                      callback={this.responseFacebook}
                    />

                  </div>
                  <div className="div_icone">
                    <GoogleLogin
                      clientId={'754731221491-u4f034dnvue4bn4bj2hcqqjcvug5958c.apps.googleusercontent.com'}
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                      icon={false}
                      buttonText={''}
                      className={'google_login'}
                      type={"button"}
                    >
                      <img src={ICONS.google} className="login_google" />
                    </GoogleLogin>
                  </div>


                </div> */}
              </div>
            </div>
          </label>
        </Container>
        <Dailog
          icon={<ErrorOutline id="error_login_icon" />}
          show={this.state.ui.errorPhoneNumber}
          onHide={() => { this.setState({ ui: { ...this.state.ui, errorPhoneNumber: false } }) }}
          label={this.state.ui.messageErrorPhoneNumser}
        />
      </div>
    );
  }
}
