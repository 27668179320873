import { call, put, takeLatest } from "redux-saga/effects";
import {error,success} from "redux-saga-requests";
import {axios} from "../app";
import {React } from 'react'
import {Redirect} from 'react-router-dom'
import {
        HOME_PRODUCTS,
        PRODUCTS_SKU,
        PRODUCTS_PRICE_SKU,
        PRODUCT_DETAIL,
        PRODUCT_RELATED,

        USER_LIKE_SHARE,
        PRODUCTS_IN_STORE,

        PRODUCT_CATEGORIES,
        PRODUCTS_LIKED,
        PRODUCT_CATEGORY_FILTER,

        PRODUCT_FILLTER,
        PRODUCT_DISCOONT_CART,

        AMOUNT_DISCOUNT,

        COLOR,
        SITE_DESCRIPTION,
        PRODUCT_DETAIL_DICRIPTION
        } from './reducer'
import {normalize} from "../../utils/normalize"


//-------------------------------------------------Product
export function* getProductSKU({ payload }) {
    try {
        const product_sku =yield call(axios.get,"/get_sku/"+payload );
        const requested = normalize(product_sku)
        yield put({
            type: success(PRODUCTS_SKU),
            payload: {
                product_sku: requested
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(PRODUCTS_SKU),
            payload: {
                errorProduct:"Error PRODUCTS_SKU"
            }
        });
    }
}
export function* getHomeProduct({ payload }) {
    try {
        const home_product = yield call(axios.get,"/products/home/"+payload );
        const requested = normalize(home_product)
        yield put({
            type: success(HOME_PRODUCTS),
            payload: {
                home_product: requested
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(HOME_PRODUCTS),
            payload: {
                errorProduct:"Error HOME_PRODUCTS"
            }
        });

    }
}
export function* getProductDetail({ payload }) {
    try {
        const product_detail = yield call(axios.get,"/products/detail/"+payload );
        const requested = normalize(product_detail)
        yield put({
            type: success(PRODUCT_DETAIL),
            payload: {
                product_detail: requested
            }
        });
        if(requested.token){
            yield (axios.defaults.headers.common = {
                Authorization: `Bearer ${requested.token}`
            });
        }
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(PRODUCT_DETAIL),
            payload: {
                errorProduct:"Error PRODUCT_DETAIL"
            }
        });
    }
}
export function* getProductRelated({ payload }) {
    try {
        const product_related = yield call(axios.get,"/products/related/"+payload['name']+'/'+payload['ID']);
        const requested = normalize(product_related)
        yield put({
            type: success(PRODUCT_RELATED),
            payload: {
                product_related: requested
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(PRODUCT_RELATED),
            payload: {
                errorProduct:"Error PRODUCT_DETAIL"
            }
        });
    }
}

export function* getProductPriceSKU({ payload }) {
    try {
        const priceSKU = yield call(axios.get, "get_sku_price/"+payload["ID"]+"/"+payload["aliasSKU"]);
        const requested = normalize(priceSKU)
        yield put({
            type: success(PRODUCTS_PRICE_SKU),
            payload: {
                priceSKU: requested
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(PRODUCTS_PRICE_SKU),
            payload: {
                errorProduct:"Error PRODUCTS_PRICE_SKU"
            }
        });
    }
}

export function* userLike_share({ payload }) {
    try { 
        
        const user_likied = yield call(axios.post,"/products_like_share",payload);
        const requested = normalize(user_likied)
        yield put({
            type: success(USER_LIKE_SHARE),
            payload: {
                user_likied: requested
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(USER_LIKE_SHARE),
            payload: {
                errorLogin:"error"
            }
        });
    }
}
export function* getProductInStore({ payload }) {
    try {
        const productsInStore = yield call(axios.get,"products/store/"+payload["page"]+"/"+payload["storeAlias"]+"/"+payload["boolean1"]+"/"+payload["boolean2"]);
        const requested = normalize(productsInStore);
        yield put({
            type: success(PRODUCTS_PRICE_SKU),
            payload: {
                productsInStore: requested
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(PRODUCTS_PRICE_SKU),
            payload: {
                error_productsInStore:"Error PRODUCTS_PRICE_SKU"
            }
        });
    }
}
export function* getProductLiked({ payload }) {
    try {
        const productsLiked = yield call(axios.get,"products/liked/"+payload);
        const requested = normalize(productsLiked);
        yield put({
            type: success(PRODUCTS_LIKED),
            payload: {
                productsLiked: requested
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(PRODUCTS_LIKED),
            payload: {
                error_getProductLiked:"Error PRODUCTS_PRICE_SKU"
            }
        });
    }
}

export function* getProductCategory({ payload }) {
    try {
        const products_category = yield call(axios.get,"/categories");
        const requested = normalize(products_category);
        yield put({
            type: success(PRODUCT_CATEGORIES),
            payload: {
                products_category: requested
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(PRODUCT_CATEGORIES),
            payload: {
                error_getProductCategoryFilter:"Error PRODUCTS_PRICE_SKU"
            }
        });
    }
}

export function* getProductCategoryFilter({ payload }) {
    try {
        const products_category_filter = yield call(axios.get,"/products/filterCategory/"+payload['page']+'/'+payload['CategoryID']);
        const requested = normalize(products_category_filter);
       
        yield put({
            type: success(PRODUCT_CATEGORY_FILTER),
            payload: {
                products_category_filter: requested
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(PRODUCT_CATEGORY_FILTER),
            payload: {
                error_getProductCategoryFilter:"Error PRODUCTS_PRICE_SKU"
            }
        });
    }
}
export function* getProductFilter({ payload }) {
    try {
        const products_filter = yield call(axios.get,"/products_filter/"+payload);
        const requested = normalize(products_filter);
     
        yield put({
            type: success(PRODUCT_FILLTER),
            payload: {
                products_filter: requested
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(PRODUCT_FILLTER),
            payload: {
                error_getProductCategoryFilter:"Error PRODUCTS_PRICE_SKU"
            }
        });
    }
}

export function* getDiscoutCart({ payload }) {
    try {
        const discout_cart = yield call(axios.get,"/products_discount/card/"+payload);
        const requested = normalize(discout_cart);
       
        yield put({
            type: success(PRODUCT_DISCOONT_CART),
            payload: {
                discout_cart: requested
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(PRODUCT_DISCOONT_CART),
            payload: {
                error_getProductCategoryFilter:"Error PRODUCTS_PRICE_SKU"
            }
        });
    }
}

export function* getAmountDiscount({ payload }) {
    try {
        const get_amountDiscount =yield call(axios.post, "/products_discount/discount_amount/",payload);
        const requested = normalize(get_amountDiscount)
         yield put({
            type: success(AMOUNT_DISCOUNT),
            payload: {
                get_amountDiscount: requested,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(AMOUNT_DISCOUNT),
            payload: {
                errorGetAmountDiscount:"Internal server error, please try again"
            }
        });
    }
}

export function* getProductDetailDiscription({ payload }) {
    try {
       
        const get_product_detal_discription =yield call(axios.get, "/products/detail/description/"+payload);
        const requested = normalize(get_product_detal_discription);
         yield put({
            type: success(PRODUCT_DETAIL_DICRIPTION),
            payload: {
                get_product_detal_discription: requested,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(PRODUCT_DETAIL_DICRIPTION),
            payload: {
                errorGetProduct_detail_discription:"Internal server error, please try again"
            }
        });
    }
}

export function* getcolor() {
    try {
        const color =yield call(axios.get,"/color");
      
        const requested = normalize(color)
       
         yield put({
            type: success(COLOR),
            payload: {
                color: requested,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(COLOR),
            payload: {
                errorColor:"Internal server error, please try again"
            }
        });
    }
}
export function* getsiteDescription() {
    try {
        const sitedescription =yield call(axios.get,"/site_description");
      
        const requested = normalize(sitedescription)
       
         yield put({
            type: success(SITE_DESCRIPTION),
            payload: {
                sitedescription: requested,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(SITE_DESCRIPTION),
            payload: {
                errorSiteDecription:"errorSiteDecription"
            }
        });
    }
}
export function* productDataSaga() {
    yield takeLatest(HOME_PRODUCTS,getHomeProduct);
    yield takeLatest(PRODUCTS_SKU,getProductSKU);
    yield takeLatest(PRODUCT_DETAIL,getProductDetail);
    yield takeLatest(PRODUCTS_PRICE_SKU,getProductPriceSKU);

    yield takeLatest(USER_LIKE_SHARE,userLike_share);
    yield takeLatest(PRODUCTS_IN_STORE,getProductInStore);
    yield takeLatest(PRODUCTS_LIKED,getProductLiked);

    yield takeLatest(PRODUCT_RELATED,getProductRelated);

    yield takeLatest(PRODUCT_CATEGORIES,getProductCategory);
    yield takeLatest(PRODUCT_CATEGORY_FILTER,getProductCategoryFilter);
    yield takeLatest(PRODUCT_FILLTER,getProductFilter);

    yield takeLatest(PRODUCT_DISCOONT_CART,getDiscoutCart);
    yield takeLatest(AMOUNT_DISCOUNT,getAmountDiscount);
    yield takeLatest(PRODUCT_DETAIL_DICRIPTION,getProductDetailDiscription);

    yield takeLatest(COLOR,getcolor);
    yield takeLatest(SITE_DESCRIPTION,getsiteDescription);
}