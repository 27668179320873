import {success,error,abort} from "redux-saga-requests";

export const STORE_ALL="STORE_ALL";
export const FOLLOWING_STORE="FOLLOWING_STORE";
export const FOLLOWED_STORES="FOLLOWED_STORES";
export const STORE_STORIES="STORE_STORIES";
//----------------------------------------->Product
export const getStores = payload => ({
    type:STORE_ALL,
    payload
  });
  export const followStore = payload => ({
    type:FOLLOWING_STORE,
    payload
  });
  export const followedStores = payload => ({
    type:FOLLOWED_STORES,
    payload
  });
  export const storeStories = () => ({
    type:STORE_STORIES
  });
  
  


const initialState = {
    loading: false,
    error: false,
  };


const getShop = (state = initialState, action) => {
    switch (action.type) {
               
/**
 * STORE_ALL
 */
case STORE_ALL:
  return {
    ...state,
    loading: true,
    error: false,
  };
case success(STORE_ALL):         
  return {
    ...state,
    ...action.payload,
    loading: false,
  };
case error(STORE_ALL):
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: true
  };
case abort(STORE_ALL):
  return { ...state, loading: false };

  /**
 * STORE_STORIES
 */
case STORE_STORIES:
  return {
    ...state,
    loading: true,
    error: false,
  };
case success(STORE_STORIES):         
  return {
    ...state,
    ...action.payload,
    loading: false,
  };
case error(STORE_STORIES):
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: true
  };
case abort(STORE_STORIES):
  return { ...state, loading: false };


/**
* FOLLOWING_STORE
*/
case FOLLOWING_STORE:
return {
...state,
loading: true,
error: false,
};
case success(FOLLOWING_STORE):         
return {
...state,
...action.payload,
loading: false,
};
case error(FOLLOWING_STORE):
return {
...state,
...action.payload,
loading: false,
error: true
};
case abort(FOLLOWING_STORE):
return { ...state, loading: false };


/**
* FOLLOWED_STORES
*/
case FOLLOWED_STORES:
  return {
    ...state,
    loading: true,
    error: false,
  };
case success(FOLLOWED_STORES):         
  return {
    ...state,
    ...action.payload,
    loading: false,
  };
case error(FOLLOWED_STORES):
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: true
  };
case abort(FOLLOWED_STORES):
  return { ...state, loading: false };




      default:
        return state;
    }
  
    
    
    
  }
  
  export default getShop;