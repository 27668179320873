import { call, put,takeLatest} from "redux-saga/effects";
import { error, success } from "redux-saga-requests";
import { axios } from "../app";
import {
        LOGIN, 
        LOGIN_SMS,
        ADD_PIN,
        CONFIRM_PIN,
        CHECK_OUT_USER,
        GET_ORDER_HISTORY,
        GET_CARTLIST,
        DELETE_CART,
        ADD_CART,
        ORDER_HISTORY_RECEVICE,
        TRANSACTION,
        SENDNOTIFICATION,
        USER_WALLET,
        
    } from "./reducer";
import store from '../../store/index'
import {normalize} from "./../../utils/normalize"


//check user or login
export function* userLoginSMSWorker({ payload }) {
    try {
        const data =JSON.stringify(
            {
                "phoneNumber":payload.phoneNumber ?payload.phoneNumber : null,
                "googleID":payload.googleID ?payload.googleID : null,
                "facebookID":payload.facebookID ?payload.facebookID : null,
                "loginType":payload.loginType ?payload.loginType : "",
              }
        )
       
       const login_sms = yield call(axios.get,'/login_sms?data='+data);
      
       const requested = normalize(login_sms);
       if(requested.token){
        yield (axios.defaults.headers.common = {
             Authorization: `Bearer ${requested.token}`
         });
     }
       yield put({
            type: success(LOGIN_SMS),
            payload: {
                login_sms: requested,
            }
        });
       
       
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(LOGIN_SMS),
            payload: {
                errorSMS:"Internal server error, please try again"
            }
        });
    }
}

export function* userLoginWorker({ payload }) {
    try { 
        const login = yield call(axios.post,"/user_login",payload);
        const requested = normalize(login);
        if(requested.token){
            yield (axios.defaults.headers.common = {
                 Authorization: `Bearer ${requested.token}`
             });
         }
        yield put({
            type: success(LOGIN),
            payload: {
                login: requested
            }
        });
        
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(LOGIN),
            payload: {
                errorLogin:message
            }
        });
    }
}

export function* addNew_pin(payload) {
    try {
        const add_new_pin =yield call(axios.post,"/user_payment_pin/updatePin",payload['payload']);
        const requested = normalize(add_new_pin);
        if( requested.message && requested.message==='success'){
            const state =store.getState();
            var login =state.user.login;
            var newLogin={
                message:login.message,
                token:login.token,
                data:{
                    ...login.data, 
                    paymentPin:true
                }
            }
            yield put({
                type: success(ADD_PIN),
                payload: {
                    add_new_pin: requested,
                    login:newLogin
                }
            });
        }
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(ADD_PIN),
            payload: {
                error_add_new_pin:"erroAdd_new_pin"
            }
        });
    }
}

export function* conFirm_pin(payload) {
    try {
        const confirm_PIN =yield call(axios.post,"user_payment_pin/validatePin",payload['payload']);
        const requested = normalize(confirm_PIN)
        yield put({
            type: success(CONFIRM_PIN),
            payload: {
                confirm_PIN: requested
            }
        });
       
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(CONFIRM_PIN),
            payload: {
                err_CONFIRM_PIN:message
            }
        });
    }
}

export function* checkOut_user(payload) {
    try {
        const check_out_user =yield call(axios.post,"/user_check_out",payload.payload);
        const  requested= normalize(check_out_user)
     
        
        yield put({
            type: success(CHECK_OUT_USER),
            payload: {
                check_out_user: requested
            }
        });
        if(requested.token){
            yield (axios.defaults.headers.common = {
                Authorization: `Bearer ${requested.token}`,              
            });
        }
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(CHECK_OUT_USER),
            payload: {
                err_check_out_user:message
            }
        });
    }
}
export function* getOrderHistory() {
    try {
        const get_orderHistory = yield call(axios.get, "/get_order_history/");
        const requested = normalize(get_orderHistory)
        yield put({
            type: success(GET_ORDER_HISTORY),
            payload: {
                get_orderHistory: requested,
            }
        });
       
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(GET_ORDER_HISTORY),
            payload: {
                error_get_orderHistory:message
            }
        });
    }
}

export function* getCarts() {
    try {
        const cartList = yield call(axios.get,"/get_cart_page" );
        const requested = normalize(cartList);
        //Discount all product 10%
        if(requested.results.length>0){
            for(let i=0;i<requested.results.length;i++){
                if(requested.results[i]['productDiscount']>0){
                    var price=requested.results[i]['price']-((requested.results[i]['price']*requested.results[i]['productDiscount'])/100);
                }else{
                    var price=requested.results[i]['price'];
                }
                const get_amountDiscount = yield call(axios.post, "/products_discount/discount_amount/",{"amount":requested.results[i]['qty'],"id":requested.results[i]['ID']});
                if(get_amountDiscount['data']['message']==='success'){
                    if(get_amountDiscount['data']['results']>0){
                        var newData = requested.results;
                        var row=newData[i];
                        row = { 
                            ...row,
                            price: price,
                            "defualTotal":price*requested.results[i]['qty'],
                            "total":
                                (price*requested.results[i]['qty']-((price*requested.results[i]['qty']*get_amountDiscount['data']['results'])/100)),
                            "persentDis":get_amountDiscount['data']['results']
                        };
                        newData[i]=row;
                    }else{
                        var newData = requested.results;
                        var row=newData[i];
                        row = { 
                            ...row,
                            price: price,
                            "defualTotal":price*requested.results[i]['qty'],
                            "total":
                                (price*requested.results[i]['qty']),
                            "persentDis":get_amountDiscount['data']['results']
                        };
                        newData[i]=row;
                    }
                }
                
            }
           
        }
        yield put({
            type: success(GET_CARTLIST),
            payload: {
                cartList: requested
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(GET_CARTLIST),
            payload: {
                err_cartList:message
            }
        });
    }
}

export function* deteleOneCart({ payload }) {
    try {
        const delete_Cart = yield call(axios.post,"user_delete_cart",payload );
        const requested = normalize(delete_Cart)
        yield put({
            type: success(DELETE_CART),
            payload: {
                delete_Cart: requested
            }
        });
        if(requested.token){
            yield (axios.defaults.headers.common = {
                Authorization: `Bearer ${requested.token}`,              
            });
        }
    } 
    catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(DELETE_CART),
            payload: {
                Errdelete_Cart:message
            }
        });
    }
}
export function* synceCart({ payload }) {
     try {
           
            const add_cart = yield call(axios.post,"user_cart/synce",payload );
            const requested = normalize(add_cart)
            yield put({
                type: success(ADD_CART),
                payload: {
                    add_cart: requested
                }
            });
        } 
        catch (e) {
            const parseError = yield JSON.parse(JSON.stringify(e));
            const message = parseError;
            yield put({
                type: error(ADD_CART),
                payload: {
                    errorProduct:"Error POST_F0LLOW STORE"
                }
            });
        }
    }
    
export function* orderHistoryReceive({ payload }) {
    try {
            const order_history_receive = yield call(axios.post,"get_order_history/receive",payload );
            const requested = normalize(order_history_receive)
            yield put({
               type: success(ORDER_HISTORY_RECEVICE),
               payload: {
                    order_history_receive: requested
               }
           });
       } 
       catch (e) {
           const parseError = yield JSON.parse(JSON.stringify(e));
           const message = parseError;
           yield put({
               type: error(ORDER_HISTORY_RECEVICE),
               payload: {
                   errorOrder_history_receive:"Error order_history_receive"
               }
           });
       }
   }
    
   export function* getTransactionUser({ payload }) {
    try {
        const get_transaction_user = yield call(axios.get, "user_wallet/transations/"+payload);
        const requested = normalize(get_transaction_user)
         yield put({
            type: success(TRANSACTION),
            payload: {
                get_transaction_user: requested,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(TRANSACTION),
            payload: {
                errorSMS:"Internal server error, please try again"
            }
        });
    }
}
export function* sendNotification({ payload }) {
    try {
           const sent_trancsaction = yield call(axios.post,"send_notification",payload );
           const requested = normalize(sent_trancsaction)
           yield put({
               type: success(SENDNOTIFICATION),
               payload: {
                    sent_trancsaction: requested
               }
           });
       } 
       catch (e) {
           const parseError = yield JSON.parse(JSON.stringify(e));
           const message = parseError;
           yield put({
               type: error(SENDNOTIFICATION),
               payload: {
                   errorsent_trancsaction:"Error POST_F0LLOW STORE"
               }
           });
       }
   }
export function* get_UserWallet() {
try { 
        
        const user_wallet = yield call(axios.get,"/user_wallet");
        const requested = normalize(user_wallet);
        yield put({
            type: success(USER_WALLET),
            payload: {
                user_wallet:requested
            }
        });
    } 
    catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(USER_WALLET),
            payload: {
                error_add_user_wallet:"Error POST_F0LLOW STORE"
            }
        });
    }
}

export function* userSaga() {
    yield takeLatest(LOGIN_SMS, userLoginSMSWorker);
    yield takeLatest(LOGIN, userLoginWorker);
    yield takeLatest(ADD_PIN, addNew_pin);
    yield takeLatest(CONFIRM_PIN, conFirm_pin);
    yield takeLatest(CHECK_OUT_USER, checkOut_user);
    yield takeLatest(GET_ORDER_HISTORY, getOrderHistory);
    yield takeLatest(GET_CARTLIST,getCarts);
    yield takeLatest(ADD_CART,synceCart);
    yield takeLatest(DELETE_CART,deteleOneCart);
    yield takeLatest(ORDER_HISTORY_RECEVICE,orderHistoryReceive);
    yield takeLatest(TRANSACTION,getTransactionUser);
    yield takeLatest(USER_WALLET,get_UserWallet);

    yield takeLatest(SENDNOTIFICATION, sendNotification);
    
}

  