import React, { Component } from 'react';
import NavigationsNew from '../containers/navigationContainer'
import {Tab,Row,Col,Nav,Tooltip,OverlayTrigger,Container} from 'react-bootstrap'
import {Store,Search,FavoriteBorder,Loyalty,Clear,Edit,Redo} from '@material-ui/icons'
import '../Style/shop.css'
import {Drawer} from '@material-ui/core'
import {STORE_URL_IMAGE} from '../modules/app/config'
import ProductRecommand from '../Component/productsRecommend'
import { Link } from 'react-router-dom';
import Loading from '../Component/loading'
import {Digital} from 'react-activity';
import {setColor} from '../utils/colors';
import { keyword } from '../utils/keywords';
export default class Shop extends Component {
    constructor(props){
        super(props);
        this.state={
            data:{
                stores:[],
                oldStore:[],
                followedStore:[],
                all_storePage:1,
                followedStorePage:1,

                all_store_no_more:false,
                followedStore_no_more:false,
                indxFollowedStore:'',

                tab_active:'nav_shop'
            },
            ui:{
                textSearch:""
            }
        }
    }
    componentDidMount(){
        this.props.getStores(this.state.data.all_storePage);
        this.props.followedStores(this.state.data.followedStorePage);
    }
    componentWillReceiveProps(nextProps){
        const {shopData,error_getStores,error_getFollowedStores}=this.props;
        try{
            if(nextProps.shopData.stores && nextProps.shopData.stores != shopData.stores)
            {
                if(nextProps.shopData.stores.message==="success"){
                    var data = nextProps.shopData.stores['data'];
                    if(data.length>0){
                        if(this.state.data.stores.length===0){
                            this.setState({
                                data:{...this.state.data,stores:data,oldStore:data}
                            });
                        }else{
                            var dataOld=this.state.data.stores;
                            data.map((dat)=>{
                                dataOld.push(dat);
                            });
                            this.setState({
                                data:{...this.state.data,stores:dataOld,oldStore:dataOld}
                            });
                        }
                    }
                    else{
                        this.setState({
                            data:{...this.state.data,all_store_no_more:true}
                        })
                    }
                }
                else{alert("No data more")}
            }

            if(nextProps.shopData.storesFollowed && nextProps.shopData.storesFollowed != shopData.storesFollowed){
                if(nextProps.shopData.storesFollowed.message==="success"){
                        var data=nextProps.shopData.storesFollowed['data'];
                        if(data.length>0){
                            if(this.state.data.followedStore.length===0){
                                this.setState({
                                    data:{...this.state.data,followedStore:data}
                                });
                            }
                            else{
                                var dataOld=this.state.data.followedStore;
                                data.map((dat)=>{
                                    dataOld.push(dat);
                                });
                                this.setState({
                                    data:{...this.state.data,followedStore:dataOld}
                                });
                            }
                        }else{
                        this.setState({
                            data:{...this.state.data,followedStore_no_more:true}
                            })
                        }
                        
                    }
                else{alert("No data more")}
            }
            if(nextProps.shopData.follow_store && nextProps.shopData.follow_store!=shopData.follow_store){
                let newData = this.state.data.stores;
                
                let row=newData[this.state.data.indxFollowedStore].store;
               
                    if(nextProps.shopData.follow_store.message==='success' && nextProps.shopData.follow_store.data==='followed'){
                        let array=this.state.data.stores;
                        let row=array[this.state.data.indxFollowedStore];
                        let stories=row.stories;
                        let store=row.store;
                        store={...store,followers:store.followers+1,youFollowed:1 }
                        
                        let redy={
                            "stories":stories,
                            "store":store
                        }
                        
                        array[this.state.data.indxFollowedStore]=redy;
                    
                        this.setState({
                            data:
                                {...this.state.data,
                                    stores:array,
                                    followedStore:[...this.state.data.followedStore,redy]
                                }
                        })
                        
                    }
                    if(nextProps.shopData.follow_store.message==='success' && nextProps.shopData.follow_store.data==='unfollowed'){
                        let array=this.state.data.stores;
                        let row=array[this.state.data.indxFollowedStore];
                        let stories=row.stories;
                        let store=row.store;
                        store={...store,followers:store.followers-1,youFollowed:0 }
                        
                        let redy={
                            "stories":stories,
                            "store":store
                        }
                        
                        array[this.state.data.indxFollowedStore]=redy;
                    
                        let unfollowed=this.state.data.followedStore;
                    
                        this.setState({
                            data:
                                {...this.state.data,
                                    stores:array,
                                    // followedStore:[...this.state.data.followedStore,redy]
                                }
                        });
                        unfollowed.splice(this.state.data.indxFollowedStore,1);
                    }
            }

            if(nextProps.error_getStores && nextProps.error_getStores!= error_getStores){
                if(nextProps.error_getStores.data.message==="no_store"){
                  this.setState({
                      data:{...this.state.data,all_store_no_more:true}
                  })
                
                }
            }
            if(nextProps.error_getFollowedStores && nextProps.error_getFollowedStores!= error_getFollowedStores){
                if(nextProps.error_getFollowedStores.data.message==="no_store"){
                  this.setState({
                      data:{...this.state.data,followedStore_no_more:true}
                  })
                }
            }
        }catch(e){
            alert("error")
        }
    }
    componentDidUpdate(prevProps){
        const {shopData} = this.props;
            if(prevProps.shopData.error &&prevProps.shopData.error != shopData.error){
                if(prevProps.shopData.errorProduct && shopData.errorProduct.data && shopData.errorProduct.data['message']==='no_store'){
                    this.setState({
                        data:{...this.state.data,followedStore:[]}
                    });
                }
            }
    }
    _loopShops(shop,followed){
        let colP=setColor('primaryColor');
        let bgP=setColor('primaryBackground');
        let bgL=setColor('lightBackground');
        let colL=setColor('lightColor');
        var result =[];
            if(shop.length>0){
                    result.push(
                        shop.map((data,index)=>{
                            return(
                                <Col md={4} sm={6} xs={12} className="oneShop_edit" key={index}>
                                        <div className="div_control_shop">
                                        <div className="div_top_pro" style={{backgroundColor:bgL,color:colL}}>
                                        <Link to={{pathname: "/detailShop/"+data['store']['alias']}}>
                                            <div className="div_Image">
                                                <img src={STORE_URL_IMAGE + data['store']['image']}/>
                                                <h1>
                                                    <span className="nameStore"> {data['store']['name']}</span>
                                                    <br/>
                                                     {keyword('customers')?keyword('customers'):"Customers"}៖<span id="follwer">{data['store']['followers']}</span>នាក់
                                                </h1>
                                            </div>
                                        </Link>
                                            
                                            {
                                                    data['store']['youFollowed']===1? 
                                                        <button onClick={()=>{this._handleFollow(data['store']['alias'],index)}}>
                                                            <FavoriteBorder id="iconFavoritebtnClick"/>
                                                        </button>
                                                    :
                                                    <button onClick={()=>{this._handleFollow(data['store']['alias'],index)}}>
                                                        <FavoriteBorder id="iconFavoritebtn"/>
                                                    </button>
                                            }
                                        </div>
                                        <div className="data_story_shop">
                                            {
                                                data['stories'] ? <ProductRecommand count_show={4} showTitle={false} products={data['stories']} title={""}/>:null
                                            }
                                        </div>
                                        <div className="btn_go_to_shop_die">
                                        <Link to={{pathname: "/detailShop/"+data['store']['alias']}}>
                                            <button style={{backgroundColor:bgP,color:colP}}>
                                                {keyword('go-to-shop')?keyword('go-to-shop'):"Go to shop"}
                                            </button>
                                        </Link>
                                        </div>
                                    </div>
                                    
                                </Col>
                            )
                        })
                ) 
            }
            return result
    }
    _handleFollow(alias,index){
        this.setState({
            data:{...this.state.data,indxFollowedStore:index}
        })
        this.props.followStore({"storeAlias":alias});
    }
    _hadle_store_dataMore(){
        this.props.getStores(this.state.data.all_storePage+1);
        this.setState({
            data:{...this.state.data,all_storePage:this.state.data.all_storePage+1}
        })
    }
    _hadle_followedStore_dataMore(){
        this.props.followedStores(this.state.data.followedStorePage+1);
        this.setState({
            data:{...this.state.data,followedStorePage:this.state.data.followedStorePage+1}
        })
    }
    _hadle_state_ui(key,value){
        this.setState({
            ui:{...this.state.ui,[key]:value}
        })
    }
    _hadle_search(){
        let AA=document.getElementById('txt_search_shop').value;
        
        if(AA.length===0){
            this.setState({
                data:{...this.state.data,stores:this.state.data.oldStore}
            })
        }
        else{
            let argin=this.state.data.oldStore;
            
            const filterStore = argin.filter(item=>item.store.name.toLowerCase().includes(AA.toLowerCase()));
            this.setState({
                data:{...this.state.data,stores:filterStore},
                ui:{...this.state.ui,textSearch:AA}
            });
        }
    }
  render() {
    let colP=setColor('primaryColor');
    let bgP=setColor('primaryBackground');
    return (
      <div className="pageShop">  
          <NavigationsNew/>
            {
                (this.state.data.stores.length===0) && <Loading/>
            }
          <div className="Container_custome">
           <Tab.Container defaultActiveKey="shops">
              <div className="left_tab">
               <Nav variant="pills" className="lala">
                    <Nav.Item id="nav_Favorite">
                        <Nav.Link eventKey="favorite" style={this.state.data.tab_active==="nav_Favorite"?{backgroundColor:bgP}:null} active={this.state.data.tab_active==="nav_Favorite"?true:false}  onClick={()=>{this.setState({data:{...this.state.data,tab_active:"nav_Favorite"}})}}>
                        <OverlayTrigger
                            key={'right'}
                            placement={'right'}
                            overlay={
                                <Tooltip id='tooltip-right' className="class_col">
                                    <p>{keyword('all-shop')?keyword('all-shop'):"All shops"}</p>
                                </Tooltip>
                            }
                        >
                             <Loyalty id="icon_favor_shop" style={this.state.data.tab_active==="nav_Favorite"?{color:colP}:null}/>
                        </OverlayTrigger> 
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item id="nav_shop">
                        <Nav.Link eventKey="shops" style={this.state.data.tab_active==="nav_shop"?{backgroundColor:bgP}:null}  active={this.state.data.tab_active==="nav_shop"?true:false} onClick={()=>{this.setState({data:{...this.state.data,tab_active:"nav_shop"}})}}>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id='tooltip-right' className="class_col danger">
                                        <p>{keyword('favorite-shop')?keyword('favorite-shop'):"Favorite shops"}</p>
                                    </Tooltip>
                                }
                            >
                                <Store id='icon_Shop_s'  style={this.state.data.tab_active==="nav_shop"?{color:colP}:null} />
                            </OverlayTrigger>
                        </Nav.Link>
                    </Nav.Item> 
                </Nav>
              </div>
                <Tab.Content>
                    <Tab.Pane eventKey="shops">
                            <Row className="control_shops_edit">
                                {this._loopShops(this.state.data.stores)}
                                {
                                    this.props.shopData.loading ?
                                    <div className="loadingSmall">
                                        <Digital color={bgP}size={30} speed={1} animating={true} />
                                    </div>
                                   :
                                   (this.state.data.stores.length>0 && this.state.data.all_store_no_more===false)?
                                    <div className="get_shop_more">
                                        <button id="btn_control_shops_more" style={{backgroundColor:bgP}} onClick={()=>{this._hadle_store_dataMore()}}>{keyword('see-more-products')?keyword('see-more-products'):"See more . . ."}</button>
                                    </div>
                                    :
                                    this.state.data.all_store_no_more===false?
                                    null
                                    :
                                    <div className="nodataMore" >
                                        <h1 >{keyword('data-revealed')?keyword('data-revealed'):"No more . . ."}</h1>
                                    </div>
                                }
                                
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="favorite">
                            <Row className="control_shops_edit">
                            {this._loopShops(this.state.data.followedStore)}
                            {
                                this.props.shopData.loading ?
                                <div className="loadingSmall">
                                    <Digital color="#727981" size={32} speed={1} animating={true} />
                                </div>
                                :
                                (this.state.data.followedStore.length>0 && this.state.data.followedStore_no_more===false)?
                                <div className="get_shop_more">
                                    <button id="btn_control_shops_more" onClick={()=>{this._hadle_followedStore_dataMore()}}>{keyword('see-more-products')?keyword('see-more-products'):"See more . . ."}</button>
                                </div>:
                                this.state.data.followedStore_no_more===false?
                                null:
                                <div className="nodataMore">
                                    <h1>{keyword('data-revealed')?keyword('data-revealed'):"No more . . ."}</h1>
                                </div>
                            }
                          
                           
                            </Row>
                        </Tab.Pane>
                </Tab.Content> 
             
          </Tab.Container>
          </div>
      </div>
    );
  }
}
