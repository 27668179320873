import { connect } from "react-redux";
import DetailShop from "../Screen/DetailShop"
import {
  get_products_in_store,
  userLikeShare,
  productSKU} from '../modules/product/reducer'
  import {
    setNotification
  } from '../modules/user/reducer'
const mapStateToProps = state => ({
  ...state,
  error_productsInStore:state.productData.error_productsInStore
});

const mapDispatchToProps = {
  get_products_in_store,
  userLikeShare,
  productSKU,
  setNotification
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailShop);
