import { connect } from "react-redux";
import ProfileComponent from "../Screen/Profile"
import {fetchTest} from "../modules/app/reducer"
import {getProductsLike} from '../modules/product/reducer';
import {followedStores,followStore} from '../modules/shop/reducer'
import {get_address} from '../modules/address/reducer'
import {getTransaction,getUserWallet,get_orderHistory,a} from '../modules/user/reducer'
const mapStateToProps = state => ({
  ...state,
  error_getFollowedStores:state.shopData.error_getFollowedStores
});

const mapDispatchToProps = {
  getProductsLike,
  followedStores,
  followStore,
  getTransaction,
  getUserWallet,
  get_orderHistory,
  get_address
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileComponent);
