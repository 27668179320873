import {success,error,abort} from "redux-saga-requests";

export const HOME_PRODUCTS="HOME_PRODUCTS";
export const PRODUCTS_SKU="PRODUCTS_SKU";
export const PRODUCT_DETAIL ="PRODUCT_DETAIL";
export const PRODUCTS_PRICE_SKU="PRODUCTS_PRICE_SKU";
export const USER_LIKE_SHARE="USER_LIKE_SHARE";
export const PRODUCTS_IN_STORE ="PRODUCTS_IN_STORE";
export const PRODUCTS_LIKED="PRODUCTS_LIKED";
export const PRODUCT_CATEGORY_FILTER="PRODUCT_CATEGORY_FILTER";
export const PRODUCT_CATEGORIES="PRODUCT_CATEGORIES";
export const PRODUCT_RELATED="PRODUCT_RELATED";
export const PRODUCT_FILLTER="PRODUCT_FILLTER";
export const PRODUCT_DISCOONT_CART="PRODUCT_DISCOONT_CART"
export const AMOUNT_DISCOUNT="AMOUNT_DISCOUNT";
export const PRODUCT_DETAIL_DICRIPTION="PRODUCT_DETAIL_DICRIPTION";
export const COLOR="COLOR"
export const SITE_DESCRIPTION="SITE_DESCRIPTION"
//----------------------------------------->Product
export const homeProduct = payload=>({
    type:HOME_PRODUCTS,
    payload
  });
  export const productSKU = payload=>({
    type:PRODUCTS_SKU,
    payload
  });
  export const getProductDetail = payload => ({
    type:PRODUCT_DETAIL,
    payload
  });
  export const getProductPriceSKU = payload => ({
    type:PRODUCTS_PRICE_SKU,
    payload
  });
  export const userLikeShare = payload => ({
    type:USER_LIKE_SHARE,
    payload
  });
  export const get_products_in_store = payload => ({
    type:PRODUCTS_IN_STORE,
    payload
  });
  export const getProductsLike = payload => ({
    type:PRODUCTS_LIKED,
    payload
  });
  export const getPorductRelated = payload => ({
    type:PRODUCT_RELATED,
    payload
  });
  export const getPorductCategory = () => ({
    type:PRODUCT_CATEGORIES
  });
  export const getPorductFilterCategory = payload => ({
    type:PRODUCT_CATEGORY_FILTER,
    payload
  });
  export const getPorductFilter = payload => ({
    type:PRODUCT_FILLTER,
    payload
  });
  export const getDiscoutCart = payload => ({
    type:PRODUCT_DISCOONT_CART,
    payload
  });
  export const getAmountDiscount=payload=>({
    type:AMOUNT_DISCOUNT,
    payload
  });
  export const getProductDetailDiscription=payload=>({
    type:PRODUCT_DETAIL_DICRIPTION,
    payload
  });
  export const getColor=()=>({
    type:COLOR
  })
  export const getSiteDescription=()=>({
    type:SITE_DESCRIPTION
  })
const initialState = {
    loading: false,
    error: false,
  };


const productData = (state = initialState, action) => {
    switch (action.type) {
/**
 * HomeProduct
 */
      case HOME_PRODUCTS:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case success(HOME_PRODUCTS):         
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(HOME_PRODUCTS):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
        };
      case abort(HOME_PRODUCTS):
        return { ...state, loading: false };

/**
 * PRODUCTS_SKU
 */
      case PRODUCTS_SKU:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case success(PRODUCTS_SKU):         
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(PRODUCTS_SKU):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
        };
      case abort(PRODUCTS_SKU):
        return { ...state, loading: false };
   
/**
 * PRODUCT_DETAIL
 */
      case PRODUCT_DETAIL:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case success(PRODUCT_DETAIL):         
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(PRODUCT_DETAIL):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
        };
      case abort(PRODUCT_DETAIL):
        return { ...state, loading: false };

  /**
 * PRODUCTS_PRICE_SKU
 */
      case PRODUCTS_PRICE_SKU:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case success(PRODUCTS_PRICE_SKU):         
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(PRODUCTS_PRICE_SKU):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
        };
      case abort(PRODUCTS_PRICE_SKU):
        return { ...state, loading: false };
        /**
 * USER_LIKE_SHARE
 */
      case USER_LIKE_SHARE:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case success(USER_LIKE_SHARE):         
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(USER_LIKE_SHARE):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
        };
      case abort(USER_LIKE_SHARE):
        return { ...state, loading: false };
/**
 * PRODUCTS_IN_STORE
 */
      case PRODUCTS_IN_STORE:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case success(PRODUCTS_IN_STORE):         
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(PRODUCTS_IN_STORE):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
        };
      case abort(PRODUCTS_IN_STORE):
        return { ...state, loading: false };
/**
 * PRODUCTS_LIKED
 */
case PRODUCTS_LIKED:
  return {
    ...state,
    loading: true,
    error: false,
  };
case success(PRODUCTS_LIKED):         
  return {
    ...state,
    ...action.payload,
    loading: false,
  };
case error(PRODUCTS_LIKED):
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: true
  };
case abort(PRODUCTS_LIKED):
  return { ...state, loading: false };
/**
 * PRODUCT_RELATED
 */
case PRODUCT_RELATED:
  return {
    ...state,
    loading: true,
    error: false,
  };
case success(PRODUCT_RELATED):         
  return {
    ...state,
    ...action.payload,
    loading: false,
  };
case error(PRODUCT_RELATED):
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: true
  };
case abort(PRODUCT_RELATED):
  return { ...state, loading: false };
  
  /**
 * PRODUCT_CATEGORIES
 */
case PRODUCT_CATEGORIES:
  return {
    ...state,
    loading: true,
    error: false,
  };
case success(PRODUCT_CATEGORIES):         
  return {
    ...state,
    ...action.payload,
    loading: false,
  };
case error(PRODUCT_CATEGORIES):
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: true
  };
case abort(PRODUCT_CATEGORIES):
  return { ...state, loading: false };

  /**
 * PRODUCT_CATEGORY_FILTER
 */
case PRODUCT_CATEGORY_FILTER:
  return {
    ...state,
    loading: true,
    error: false,
  };
case success(PRODUCT_CATEGORY_FILTER):         
  return {
    ...state,
    ...action.payload,
    loading: false,
  };
case error(PRODUCT_CATEGORY_FILTER):
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: true
  };
case abort(PRODUCT_CATEGORY_FILTER):
  return { ...state, loading: false };

  /**
 * PRODUCT_FILLTER
 */
case PRODUCT_FILLTER:
  return {
    ...state,
    loading: true,
    error: false,
  };
case success(PRODUCT_FILLTER):         
  return {
    ...state,
    ...action.payload,
    loading: false,
  };
case error(PRODUCT_FILLTER):
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: true
  };
case abort(PRODUCT_FILLTER):
  return { ...state, loading: false };

  /**
 * PRODUCT_DISCOONT_CART
 */
case PRODUCT_DISCOONT_CART:
  return {
    ...state,
    loading: true,
    error: false,
  };
case success(PRODUCT_DISCOONT_CART):         
  return {
    ...state,
    ...action.payload,
    loading: false,
  };
case error(PRODUCT_DISCOONT_CART):
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: true
  };
case abort(PRODUCT_DISCOONT_CART):
  return { ...state, loading: false };
    /**
     * AMOUNT_DISCOUNT
     */
    case AMOUNT_DISCOUNT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(AMOUNT_DISCOUNT):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(AMOUNT_DISCOUNT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(AMOUNT_DISCOUNT):
      return { ...state, loading: false };
    /**
     * COLOR
     */
    case COLOR:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(COLOR):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(COLOR):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(COLOR):
      return { ...state, loading: false };
        /**
     * SITE_DESCRIPTION
     */
    case SITE_DESCRIPTION:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(SITE_DESCRIPTION):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SITE_DESCRIPTION):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SITE_DESCRIPTION):
      return { ...state, loading: false };
    /**
     *  PRODUCT_DETAIL_DICRIPTION
     */
    case PRODUCT_DETAIL_DICRIPTION:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(PRODUCT_DETAIL_DICRIPTION):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(PRODUCT_DETAIL_DICRIPTION):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(PRODUCT_DETAIL_DICRIPTION):
      return { ...state, loading: false };


      default:
        return state;
    }
  }
  
  export default productData;