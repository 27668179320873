import { connect } from "react-redux";
import FilterCategory from "../Screen/FilterCategory"
import {
  getPorductFilterCategory,
  getPorductCategory,
  productSKU,
  userLikeShare,
  getPorductFilter
} from '../modules/product/reducer'
import {
  addCart,setNotification
} from '../modules/user/reducer'
const mapStateToProps = state => ({
  ...state,
  products_category:state.productData.products_category,
  products_category_filter:state.productData.products_category_filter
});

const mapDispatchToProps = {
  userLikeShare,
  productSKU,
  getPorductFilterCategory,
  getPorductCategory,
  addCart,
  setNotification,
  getPorductFilter
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterCategory);
