import React, { Component } from 'react';
import '../Style/Component/oneProduct.css'
import {Row,Col} from 'react-bootstrap'
import {FavoriteBorderOutlined,ThumbUpAlt,Visibility,Compare,LocalMallOutlined} from '@material-ui/icons'
import {Tooltip} from '@material-ui/core'
import Rating from '@material-ui/lab/Rating';
import {PROUDCT_URL_IMAGE,PRODUCT_MORE_URL_IMAGE} from '../modules/app/config'
import {FacebookShareButton} from 'react-share'
import { Link } from 'react-router-dom';
import Shearing from '../Component/shearing'
import  { Route } from 'react-router-dom'
import {NOTIMAGE} from '../Assets/images'
import {setColor,setLayout} from '../utils/colors';
import { keyword } from '../utils/keywords';
export default class OneProduct extends Component {
    constructor(props){
        super(props);
        this.state={
            getData:{
                userClickLikd:false
            },
        }
    }
  render() {
    var backg=setColor('lightBackground');
    var colL=setColor('lightColor');
    var backgB=setColor('primaryBackground');
    var colB=setColor('primaryColor');
    var bgD=setColor('darkBackground');
    var coD=setColor('darkColor');
    let layout=setLayout('layout');
    const {dataProduct,functionbtn,fun_clickLike, showBtnCart}=this.props;
    
    return (
      <Col md={3} sm={4} xs={layout===1?12:6} className="col_controler_oneProd" > 
       
        <div className="layer_2 col-12" >
        {
           dataProduct.productDiscount>0 ?
           <div className="have_discount_product">
                <h1>{keyword('discount')?keyword('discount')+" ":"Discount"} {dataProduct.productDiscount}%</h1>
            </div>
           :null
        }
            <Link to={{pathname: "/detailproduct/"+dataProduct['alias']}} >
                <img src={dataProduct['image']? PROUDCT_URL_IMAGE+dataProduct['image']:NOTIMAGE} id="imageProd"/>
            </Link>
            </div>
            
            <div className="menu_sku_prod col-12" >
            {/* <Link to={{pathname: "/DetailProduct/"+dataProduct['alias']}} > */}
                <div className="show_btn_add_to_cart">
                    <Link to={{pathname: "/detailproduct/"+dataProduct['alias']}} >
                        <h5 style={{color:colL}}>{dataProduct['name']}</h5>
                    </Link>
                    <Rating name="read-only" value={dataProduct['rated']} readOnly id="RatingStar"/>
                    {
                        dataProduct.productDiscount>0 ?
                        <div className="show_discount_one_product" >
                            <h3>$ { dataProduct['minPrice']-((dataProduct['minPrice']*dataProduct.productDiscount)/100)}</h3>
                            <h4>$ {dataProduct['minPrice']}</h4>
                            
                        </div>
                        :
                        <div className="show_discount_one_product" >
                            <h3 >$ {dataProduct['minPrice']}</h3>
                            <h4 style={{height:20,color:colL}}> </h4>
                        </div>      
                    }
                    {
                        showBtnCart===true ?<button id="btnCart" onClick={functionbtn} style={{backgroundColor:backgB,color:colB}}><LocalMallOutlined className="iconbtn3"/>{keyword('add-to-cart')?keyword('add-to-cart'):"Add to cart"}</button>:null
                    }
                </div>
            </div>
            
            <div className="like_prod col-12">
                <button id="btn_share" onClick={()=>{this._shareToSocail()}} >
                    {
                        dataProduct['rated']
                    }
                    <i className="fas fa-star" id='icon_share'></i>
            
                </button>
                {   
                    (dataProduct['youLiked']===1)?
                     <button id="btn_liked" style={{backgroundColor:bgD,color:coD}}>
                        {
                           dataProduct['liked']
                        }
                        <ThumbUpAlt id="icon_like"/>
                    </button>:
                     <button  id="btn_like" onClick={fun_clickLike}>
                        {
                            dataProduct['liked']
                        }
                        <ThumbUpAlt id="icon_like"/>
                    </button>
                }
            </div>
      </Col>
    );
  }
}
