import React, { Component } from 'react';
import '../Style/DetailProduct.css';
import '../Assets/color.css'
import Navigation from '../containers/navigationContainer'
import { Link } from 'react-router-dom';
import {Row,Col, Container,Modal} from 'react-bootstrap'
import OneProduct from '../containers/compornentOneProduct'
import {ThumbUpAlt} from '@material-ui/icons'
import Slider from "react-slick";
import ComfirmBuy from '../containers/compornentComfirmBuy';
import { PROUDCT_URL_IMAGE,PRODUCT_MORE_URL_IMAGE, PRODUCT_THUMBNAIL_MORE_URL_IMAGE,PROUDCT_THUMBNAIL_URL_IMAGE} from '../modules/app/config'
import ButtonBuyFooter from '../Component/btnBuyFooter'
import Rating from '@material-ui/lab/Rating'
import {GIF} from '../Assets/images'
import Loading from '../Component/loading'
import { FacebookButton } from "react-social";
import {Dailog} from '../Component/dailog'
import {NOTIMAGE} from '../Assets/images'
import {setColor} from '../utils/colors'
import { keyword } from '../utils/keywords';
import amount from '../utils/fomartAmount'
export default class DetailProduct extends Component {
    constructor(props){
        super(props);
        this.state={
            ui:{
                showDailog:false,
                addTocart:false,
                receipt:false,
                index:0,
                message:'',
                buyNowOrNot:false,
                showDialogAddedTocart:false,
            },
            data:{
                prodsSKU:[],
                indxUserLike:'',
            },
            getData:{
                IDprod:'',
                total:0,
                countSKU:0,

                count:1,
                prodSKU:[],
                pord:[],
                imageMain:'',
                mainImageDefult:'',
                showingImage:'',
                prodImage:[],
                dataDialog:[],
                _stateSKU:[],

                productRelated:[],

                skuIndexCheck: [],

                dataBuyNow:[],
                discoutCout:[],
                orgPrice:0,
                productDetail_discription:''
            },
            allTotal:0,
            calculateTotol:0,
            Loading:false,
            subtriNing:false
        }
        // if(localStorage.getItem('persist:amatakWebsite')){
        //     const user_sms = JSON.parse(localStorage.getItem('persist:amatakWebsite'));
        //     const login_sms=JSON.parse(user_sms.user);
        //     if(login_sms.login && login_sms.login.message==="success"){
        //         if(login_sms.login && login_sms.login.token){
        //             window.onload = function() {
        //                 if(!window.location.hash) {
        //                     window.location = window.location + '#prd_foryou';
        //                     window.location.reload();
        //                 }
        //             }
        //         }
        //     }
        // }
    }
    scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
     };
    componentDidMount(){
            var alais_url=this.props.match.params.alias;
            this._loadingLegleg(); 
            this.props.getProductDetail(alais_url); 
            this.props.getProductDetailDiscription(alais_url);
            this.scrollTop();   
        }
           
    componentWillReceiveProps(nextProps){
        const {productData,user,match} = this.props;
        const {count}=this.state.getData.count
        try{
            if(nextProps.match && nextProps.match!=match){
                if(nextProps.match.params.alias){
                    var alais_url=nextProps.match.params.alias;
                    this.setState({getData:{...this.state.getData,prodSKU:[]}})
                    this.props.getProductDetail(alais_url); 
                }
            }
            if(nextProps.productData.get_product_detal_discription && nextProps.productData.get_product_detal_discription !=productData.get_product_detal_discription ){
                if(nextProps.productData.get_product_detal_discription.message==="success"){
                    this._handleGetData('productDetail_discription',nextProps.productData.get_product_detal_discription['results'][0]['detail']);
                }
            }
            if(nextProps.productData.product_detail && nextProps.productData.product_detail != productData.product_detail)
            {
                if(nextProps.productData.product_detail.message==="success"){
                    var str=Number(nextProps.productData.product_detail['results']['productDetail'][0]['storeUserName'].substr(4,nextProps.productData.product_detail['results']['productDetail'][0]['storeUserName'].length-4))
                    this.setState({
                        subtriNing:str
                    })
                    var emptyArray=[];
                    var data = nextProps.productData.product_detail.results;
                    if(data){
                        if(data['productImage'] && data['productImage'].length>0){
                            data['productImage'].map((item)=>{
                                emptyArray.push( PRODUCT_MORE_URL_IMAGE + item['image'])
                            })
                        }else if(data.productImage==="" || data['productImage'].length===0 && data['productDetail'] && data['productDetail'][0] && data['productDetail'][0]['image']){
                            emptyArray.push(PROUDCT_THUMBNAIL_URL_IMAGE+data['productDetail'][0]['image'])
                        }else{
                            emptyArray.push(NOTIMAGE)
                        }
                      
                        if( data['productDetail']['0']['productDiscount']>0){
                            this.setState({
                                getData:{
                                    ...this.state.getData,
                                    // mainImageDefult:data['productDetail']['0']['image'],
                                    imageMain:data['productDetail']['0']['image']?PROUDCT_URL_IMAGE + data['productDetail']['0']['image']:PROUDCT_URL_IMAGE + data['productDetail']['0']['image'],
                                    pord:{...data['productDetail']['0'],minPrice:data['productDetail']['0']['minPrice']-((data['productDetail']['0']['minPrice']*data['productDetail']['0']['productDiscount'])/100)},
                                    prodImage:emptyArray,
                                    IDprod:data.productDetail['0']['ID'],
                                    discoutCout:data.productAmountDiscount,
                                    orgPrice:data['productDetail']['0']['minPrice']
                                }
                            });
                        }else{
                            this.setState({
                                getData:{
                                    ...this.state.getData,
                                    // mainImageDefult:data['productDetail']['0']['image'],
                                    imageMain:data['productDetail']['0']['image']?PROUDCT_URL_IMAGE + data['productDetail']['0']['image']:PROUDCT_URL_IMAGE + data['productDetail']['0']['image'],
                                    pord:data['productDetail']['0'],
                                    prodImage:emptyArray,
                                    IDprod:data.productDetail['0']['ID'],
                                    discoutCout:data.productAmountDiscount,
                                    orgPrice:data['productDetail']['0']['minPrice']
                                }
                            });
                        }
                       
                    }
                    //require SKU
                    this.props.productSKU(data.productDetail['0']['ID']);
                    var param={
                        "name":data.productDetail['0']['alias'],
                        "ID":data.productDetail['0']['categoryID']
                    }
                    setTimeout(() => {
                        this.props.getPorductRelated(param)
                        
                    }, 1000);
                }   
            }
            if(nextProps.productData.product_sku && nextProps.productData.product_sku !=productData.product_sku)
            { 
                if(nextProps.productData.product_sku.message==="success"){
                    nextProps.productData.product_sku.results.map((a)=>{
                        a['dataList'].map((item)=>{
                            return this.state.getData._stateSKU.push(item['name']);
                        })
                    });
                    
                    this._handleGetData('prodSKU', nextProps.productData.product_sku.results)
                }
            }
            if(nextProps.productData.priceSKU && nextProps.productData.priceSKU !=productData.priceSKU){
                if(nextProps.productData.priceSKU['message']==='success'){
                    var data=nextProps.productData.priceSKU['results'];
                    if(this.state.getData.pord['productDiscount']>0){
                        this.setState({
                            getData:{
                                ...this.state.getData,
                                countSKU:data['stock'],
                                count:1,
                                total:data['price']-((data['price']*this.state.getData.pord['productDiscount'])/100)},
                            calculateTotol:data['price']-((data['price']*this.state.getData.pord['productDiscount'])/100),
                            allTotal:0
                        })
                    }
                    else{
                        this.setState({
                            getData:{
                                ...this.state.getData,
                                count:1,
                                countSKU:data['stock'],
                                total:data['price']
                            },
                            allTotal:0,
                            calculateTotol:data['price']
                        })
                    }
                }
            }
            if(nextProps.productData.product_related && nextProps.productData.product_related!=productData.product_related){
                if(nextProps.productData.product_related.message==="success"){
                    var data=nextProps.productData.product_related.results;
              
                    this.setState({
                        getData:{...this.state.getData,productRelated:data}
                    })
                    this.scrollTop();
                }
            }
            if(nextProps.user.add_cart && nextProps.user.add_cart!=user.add_cart){
                if(nextProps.user.add_cart.message==='success'){
                    if(this.state.ui.buyNowOrNot===true){
                        this.setState({
                            ui:{
                                ...this.state.ui,
                                receipt:true
                            }
                        });
                        this.props.getCartList();
                    }else{
                        this.setState({
                        ui:{
                            ...this.state.ui,
                            showDialogAddedTocart:true
                        }
                    })
                    }
                }
            }
            if(nextProps.user.cartList && nextProps.user.cartList!=user.cartList){
                var dataCartList=nextProps.user.cartList.results;
                // const value = dataCartList.filter((item)=>item.ID===this.state.getData.IDprod);
                if(this.state.getData.skuIndexCheck.length>0){
                    const stringData = this.state.getData.skuIndexCheck.reduce((result, item) => {
                        return `${result}${item.value}-`
                      }, "");
                    var aa=stringData.slice(0,-1);
                    const value = dataCartList.filter((item)=>item['alias']===aa && this.state.getData.IDprod===item.ID);
                    if(value[0]['productDiscount']&&value[0]['productDiscount']>0){
                        const newVlue={...value[0],price:value[0]['price']-((value[0]['price']*value[0]['productDiscount'])/100)}
                        let newData = this.state.getData.dataBuyNow;
                        newData[0]=newVlue;
                        
                    }
                   else{
                        const newVlue={...value[0],price:value[0]['price']}
                        let newData = this.state.getData.dataBuyNow;
                        newData[0]=newVlue;
                   }
                }   
            }
            if(nextProps.productData.user_likied && nextProps.productData.user_likied!=productData.user_likied){
                if(nextProps.productData.user_likied.message==='success'){
                    let newData = this.state.getData.productRelated;
                    let row=newData[this.state.data.indxUserLike];
                    row = { ...row,liked: row.liked + 1, youLiked: 1 };
                    newData[this.state.data.indxUserLike]=row;
                    this.setState({ getData:{...this.state.getData,productRelated:newData} });
                    const data_send_notification = {
                        user: row.storeUserName,
                        type: "seller",
                        data: { type: 'user_like' },
                        message: "Product " + row.name + " gets a favorite from customer " + this.state.data.userFullName
                    }
                    this.props.setNotification(data_send_notification)
                }
            }
            
        }catch(e){
            }
    }
    _loopSetgetData(key,value){
       this.setState({
           getData:{
               ...this.state.getData,
               [key[0]]:value[0],
               imageMain:PROUDCT_URL_IMAGE + value[1]
           }
       })
    }

    _handleGetData(key,value){
        this.setState({
            getData:{...this.state.getData,[key]:value}
        })
    }
    _handleUi(key,value){
        const images=this.state.getData.prodImage;
        if(key==="index"){
            if(value <= -1){
                this.setState({
                    ui:{...this.state.ui,[key]:images.length-1}
                })
            }
            else if(value >=images.length){
                this.setState({
                    ui:{...this.state.ui,[key]:0}
                })
            }
            else{
                this.setState({
                    ui:{...this.state.ui,[key]:value}
                })
            }
        }
        else if(key){
            this.setState({
                ui:{...this.state.ui,[key]:value}
            })
        }
    }
    
    _handleBuyNow(){
        this.props.getCartList();
        if(this.state.getData.count>this.state.getData.countSKU){
            this.setState({
                ui:{...this.state.ui,message:keyword('please-select-a-product-type')?keyword('please-select-a-product-type'):"Please select a product type",showDailog:true}
            })     
        }
        else{
            if(this.state.getData.skuIndexCheck.length>0){
                let aliasSKU="";
                for (var i = 0; i < this.state.getData.skuIndexCheck.length; i++) {
                    aliasSKU += this.state.getData.skuIndexCheck[i].value + '-';
                }

                const qty=this.state.getData.count;
                const dataCart={
                    "dataCart":[
                        {
                            ID:this.state.getData.pord['ID'],
                            qty,
                            skuAlias:aliasSKU.substr(0, aliasSKU.length-1)
                        }
                    ]
                }
                const value = this.state.getData.skuIndexCheck.filter((item)=>item.value=="");
                if(value.length<=0){
                    this.props.addCart(dataCart);
                    this._handleUi('buyNowOrNot',true)
                }
            }
        }
    }
    _handleAddToCart(){
        if(this.state.getData.count>this.state.getData.countSKU){
            this.setState({
                ui:{...this.state.ui,message:keyword('please-select-a-product-type')?keyword('please-select-a-product-type'):"Please select a product type",showDailog:true}
            })     
        }
        else{
            if(this.state.getData.skuIndexCheck.length>0){
                let aliasSKU="";
                for (var i = 0; i < this.state.getData.skuIndexCheck.length; i++) {
                    aliasSKU += this.state.getData.skuIndexCheck[i].value + '-';
                }

                const qty=this.state.getData.count;
                const dataCart={
                    "dataCart":[
                        {
                            ID:this.state.getData.pord['ID'],
                            qty,
                            skuAlias:aliasSKU.substr(0, aliasSKU.length-1)
                        }
                    ]
                }
                const value = this.state.getData.skuIndexCheck.filter((item)=>item.value=="");
                if(value.length<=0){
                    this._handleUi("addTocart",true);
                    this._handleGetData('dataDialog',this.state.getData.pord);
                    this.props.addCart(dataCart);
                }
            }
            
        }
    }

    _silde_product(data){
        var rs=[];
        if(data){
            data.map((sl,indx)=>{
                rs.push(
                    <div key={indx} className="div_store_detail">
                        <img className="image_store_stories" src={sl}/>
                    </div>
                )
            })
        }
        return rs;
    }
    _setFocusColor = (id, alias,dataSKU) => {
        let skuIndexCheck = this.state.getData.skuIndexCheck
        let objIndex = skuIndexCheck.findIndex((obj => obj.id == id));
        skuIndexCheck[objIndex].value = alias;
        this._handleGetData('skuIndexCheck',skuIndexCheck);
        
        if(dataSKU['productImage']){
            this.setState({
                getData:{...this.state.getData,showingImage:PROUDCT_URL_IMAGE+dataSKU['productImage']}
            })
            
        }else if(dataSKU['productImageImage']){
            this.setState({
                getData:{...this.state.getData,showingImage:PRODUCT_MORE_URL_IMAGE+dataSKU['productImageImage']}
            })
        }
    }
    _calculatePrice=()=>{
        if(this.state.getData.skuIndexCheck.length>0){
            let aliasSKU=[]
            for(var i=0;i<this.state.getData.skuIndexCheck.length;i++){
                aliasSKU+=this.state.getData.skuIndexCheck[i].value+ '-';
            }
            
            const dataPost={
                "ID":this.state.getData.IDprod,
                "aliasSKU":aliasSKU.substr(0,aliasSKU.length-1)
            }
            this.props.getProductPriceSKU(dataPost)
        }
    }
    _checkAddress(){
        var A=JSON.parse(localStorage.getItem('amatakUserAddress'));
        if(A.length<=0){
            this._handleUi('showAddress',true);
        }
       
    }
    _handleMainImage(data){
        if(data.productImage){
            this.setState({
                getData:{...this.state.getData,imageMain:PROUDCT_URL_IMAGE+data.productImage}
            })
        }else if(data.productImageImage){
            this.setState({
                getData:{...this.state.getData,imageMain:PRODUCT_MORE_URL_IMAGE+data.productImageImage}
            })
        }else{
            this.setState({
                getData:{...this.state.getData,imageMain:null}
            })
        }
    }
    _userOnClickLiked(index,product){
        var Data={
            "productAlias":product['alias'],
            "shareTo":"",
            "type": "like"  
        }
        this.props.userLikeShare(Data);
        this.setState({data:{...this.state.data,indxUserLike:index}})
    }
    _LoopProductRelated(data){
        var result =[];
        if(data){
            data.map((oneProd,index)=>{
                result.push(
                    <OneProduct
                        showBtnCart={false}
                        fun_clickLike={()=>{this._userOnClickLiked(index,oneProd)}}
                        key={index} dataProduct={oneProd} prodsSKU={this.state.data.prodsSKU} 
                    />
                )
            })
        }
        return(result)
    }
    _loopDiscout(discout){
        var rs=[];
        if(discout){
            discout.map((one,inx)=>{
                rs.push(
                    <div key={inx} className="oneDis">
                        {keyword('work-message-buy-more-than')?keyword('work-message-buy-more-than'):"Buy more than"} <span> {one['amount']} </span>​​ {keyword('discount')?keyword('discount'):"Discount"} <span>{one['discount']}%</span>
                    </div>
                )
            })
        }return rs;
    }
    _handleDiscout(count,ArrayDIS){
        if (ArrayDIS.length>0 && count) {
          if (count < ArrayDIS[0]['amount']) {
              this.setState({
                calculateTotol:count*this.state.getData.total,
                allTotal:0
              })
            } else {
            if (ArrayDIS.length === 1) {
                let dataA=count * this.state.getData.total * ArrayDIS[0]['discount']
                dataA=dataA/100;
                this.setState({
                    allTotal:count * this.state.getData.total,
                    calculateTotol:count * this.state.getData.total-dataA
                })
            } else {
              for (let i = 0; i < ArrayDIS.length; i++) {
                if (count >= ArrayDIS[i]['amount']) {
                    let dataA=count * this.state.getData.total * ArrayDIS[i]['discount']
                    dataA=dataA/100
                    this.setState({
                        allTotal:count * this.state.getData.total,
                        calculateTotol:count* this.state.getData.total-dataA
                    })
                }
              }
            }
          }
        }else{
            this.setState({
                calculateTotol:count*this.state.getData.total,
                allTotal:0
            })
        }
      }

      _loadingLegleg(){
        this.setState({
          Loading:true
        })
        setTimeout(() => {
          this.setState({
            Loading:false
          })
        }, 4000);
    }
    render() {
        let bgP=setColor('primaryBackground');
        let colP=setColor('primaryColor');
        
        let skuIndexCheck = [];
        
        let SKU= this.state.getData.prodSKU.map((item,i)=>{
            skuIndexCheck.push({id:item['idToSort'],value:''});
            let result = item['dataList'].map((subData,j)=>{
                return(
                    <button key={j} 
                        id={this.state.getData.skuIndexCheck.length>0 && this.state.getData.skuIndexCheck[i].value===subData['alias']?"btnLoopSKUClicked":"btnLoopSKU"}
                        style={this.state.getData.skuIndexCheck.length>0 && this.state.getData.skuIndexCheck[i].value===subData['alias']?{backgroundColor:bgP,color:colP}:null}
                        onClick ={()=>{this._setFocusColor(item['idToSort'],subData['alias'],subData);this._calculatePrice();}}>
                            {subData['productImageImage']?
                            <div className="one_sku_detail">
                                <img src={PRODUCT_THUMBNAIL_MORE_URL_IMAGE+subData['productImageImage']} id="image_product_detail"/>
                                <p>{subData['name']}</p>
                            </div>
                            :
                            subData['productImage']?
                            <div className="one_sku_detail">
                                <img src={PROUDCT_URL_IMAGE+subData['productImage']} id="image_product_detail"/>
                                <p>{subData['name']}</p>
                            </div>
                            :
                            <div className="one_sku_detail">
                                <p>{subData['name']}</p>
                            </div>
                            }
                    </button>
                )
            })
            return(
                <div key={i} className="control_show_sku">
                    <div>
                        {item['mainSpecificationName']}:
                         {this.state.getData.skuIndexCheck.length>0? 
                         <h5>
                            {this.state.getData.skuIndexCheck[i].value}
                         </h5>
                         :null}
                    </div>
                    <div>
                        {result}
                    </div>
                </div>
            )
        })
        
        if (this.state.getData.prodSKU.length > this.state.getData.skuIndexCheck && this.state.getData.skuIndexCheck.length===0) {
            this.setState({
                getData:{...this.state.getData,skuIndexCheck:skuIndexCheck}
            })
        }

        const settings = {
			dots: true,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
            verticalSwiping: true,
            arrows: true,
            prevArrow:(<button type="button"  id="Pre_slide_in_detail_prod">{keyword('word-for-detail-product-previous')?keyword('word-for-detail-product-previous'):"Previous"}</button>),
            nextArrow: (<button  type="button" id="Nex_slide_in_detail_prod">{keyword('word-for-detail-product-next')?keyword('word-for-detail-product-next'):"Nexts"}</button>),
			
        };
        
        let url_product = window.location.href;
      
        let rated=this.state.getData.pord['rated']
    return (
    <div> 
        
        <Navigation/>
            {
                this.state.Loading===true &&<Loading/>
            }
        <div className="rith">

         <Container className="detailClass" >
            <Row state={{padding:0,margin:0}}>
                <Col lg={6} md={6} sm={12} xs={12} style={{textAlign:'start'}}>
                    <Row style={{width:'100%',padding:10}}>
                        <div className="controle_iamg_detail___">
                            {
                                this.state.getData.showingImage!=''?
                                <div style={{transition:'all 2s'}}>
                                    <div className="div_store_prod_detail">
                                        <img id="image_store_stories" src={this.state.getData.showingImage}/>
                                    </div>
                                <button id="btn_show_other_image_more" onClick={()=>{this.setState({
                                    getData:{...this.state.getData,showingImage:''},
                                    })}}>{keyword('check-out-detailed-product-photos')?keyword('check-out-detailed-product-photos'):'Check out detailed product photos'}</button>
                                </div>
                                :
                                <div style={{transition:'all 2s'}}>
                                    <Slider 
                                        {...settings}
                                    >
                                        {
                                            (this.state.getData.prodImage.length===0)? <img id="image_store_stories" src={NOTIMAGE}/>:null
                                        }
                                        {this._silde_product(this.state.getData.prodImage)}
                                    </Slider>
                                </div>
                            }
                            
                        </div>
                       
                    </Row>
                     <FacebookButton url={url_product} appId={272282387539672} id="btn_Share_detail">
                        {keyword('word-share-product')?keyword('word-share-product'):"Share"} <i class='fab fa-facebook-square'></i>
                    </FacebookButton>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} style={{textAlign:'start'}}>
                    {
                        this.state.getData.pord && this.state.getData.pord['productDiscount']>0 ?
                        <div className="show_discount_persent">
                        <h1>
                            {keyword('discount')?keyword('discount'):"Discount"} 
                            <span>
                                {
                                    this.state.getData.pord['productDiscount']
                                } %
                            </span>
                        </h1>
                        </div>:
                        null
                    }
                    
                    <div className="detail_block">
                        <div >
                            {
                                this.state.getData.pord && this.state.getData.pord['productDiscount']>0 ?
                                <div className="detail_block_price">
                                    <h2>
                                        <span>$ {this.state.getData.pord['minPrice']>0?amount(this.state.getData.pord['minPrice']):0}</span>
                                    </h2>
                                        <h1>
                                            <span>$ {this.state.getData.orgPrice>0?amount(this.state.getData.orgPrice):0}</span>
                                        </h1>
                                </div>
                                :
                                <div className="detail_block_price">
                                    <h2>
                                        <span>$ {this.state.getData.pord['minPrice']>0?amount(this.state.getData.pord['minPrice']):0} </span>
                                    </h2>
                                </div>
                            }
                           
                        </div>
                        <h1 className="name_show_prod">{this.state.getData.pord['name']}</h1>
                        <h6>{keyword('word-product-instock')?keyword('word-product-instock'):"Product in stock"}: <span>{this.state.getData.countSKU?this.state.getData.countSKU:"(រងចាំការកំណត់!)"}</span></h6>
                        <h6>{keyword('amount-product')?keyword('amount-product'):"Quantity of products"}: {
                                            this.state.getData.countSKU===0?<span>(រងចាំការកំណត់!)</span>
                                            :
                                            this.state.getData.countSKU>0? 
                                            <span>{keyword('word-product-limited-instock')?keyword('word-product-limited-instock'):"Product limite of stock"}</span> 
                                            :
                                            <span>{keyword('out-of-stock')?keyword('out-of-stock'):"Out of stock"}</span>} 
                        </h6>
                        

                        <div className="RatingClassn">
                            <Rating name="read-only" value={parseInt(rated,10)} readOnly id="RatingStarDetail"/>
                        </div>
                        <Row className="show_cout_like_share">
                            <h1>{keyword('rate')?keyword('rate'):"Rate"}:</h1>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6} className="like_count">
                                <ThumbUpAlt id="icon_like_ditail"/> 
                                <h5>{keyword('like')?keyword('like'):'Like'}: { this.state.getData.pord['liked']}  </h5>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6} className="like_share">
                                <i className="fa fa-share"></i>
                                <h5>{keyword('word-product-sharing')?keyword('word-product-sharing'):"Sharing"}: { this.state.getData.pord['shared']}</h5>
                            </Col>
                        </Row>
                        <div className="choise_sku"> 
                               {SKU}
                        </div>
                        <div className="count_detail">  
                            <Row>
                                {
                                    this.state.getData.count<=1?<button disabled style={{height:44}}></button>:<button 
                                    onClick={()=>{
                                        this._handleDiscout(this.state.getData.count-1,this.state.getData.discoutCout);
                                        this._handleGetData('count',this.state.getData.count-1);
                                    }
                                    }
                                    >-</button>
                                }
                                    <input id="Countinput_detail" value={this.state.getData.count} readOnly="readOnly"></input>
                                {
                                    this.state.getData.countSKU>this.state.getData.count? 
                                    <button  
                                    onClick={()=>{
                                        this._handleDiscout(this.state.getData.count+1,this.state.getData.discoutCout);
                                        this._handleGetData('count',this.state.getData.count+1);
                                    }
                                        
                                    }
                                    >+</button>:<button disabled style={{height:44}}></button>
                                }
                                
                            </Row>
                        </div>
                        <div className="totalSKUPrice">
                            {
                                this.state.getData.discoutCout.length>0
                                &&
                                <div className="discout_phara">
                                   {keyword('word-there-is-a-special-discount')?keyword('word-there-is-a-special-discount'):"There is a special discount!"}
                                    <br/>
                                    {
                                        this._loopDiscout(this.state.getData.discoutCout)
                                    }
                                </div>
                            }
                            {
                                this.state.calculateTotol>0?
                                <h1>
                                    {keyword('amount-price')?keyword('amount-price'):"Total price"}: <span> $ {this.state.calculateTotol} </span> 
                                    {   
                                        this.state.allTotal>0 &&
                                        <p id="TotalALL"> {keyword('price')?keyword('price'):"Price"} <span>$ {this.state.allTotal}</span></p>  
                                    }
                                </h1>
                                :
                                <h1>
                                    {keyword('amount-price')?keyword('amount-price'):"Total price"}: $ <span>_ _ _</span>    
                                </h1>   
                            }
                        </div>
                        <div className="many_product_selected">
                            <Row >
                                <Col md={6} sm={6} xs={6}>
                                    <div style={{width:'100%'}}>
                                        <button style={{backgroundColor:bgP,color:colP}} id="buttonADDTOCART" onClick={()=>{this._handleAddToCart();this._handleUi('buyNowOrNot',false)}}>{keyword('add-to-cart')?keyword('add-to-cart'):"Add to cart"}</button>
                                    </div>
                                </Col>
                                <Col md={6} sm={6} xs={6}>
                                    <button style={{backgroundColor:bgP,color:colP}} id="buttonBuyNow" onClick={()=>{this._handleBuyNow()}}>{keyword('buy-now')?keyword('buy-now'):"Buy now"}</button>
                                </Col>
                            </Row>
                          
                        </div>
                    </div>
                    
                </Col>
            </Row>
        </Container> 
        <div className="smallTitleSocail">
            
        </div>
        
        <Container className="writingdetailPro">
            <h3>{keyword('product-details')?keyword('product-details'):"Product details"}</h3>
            <div dangerouslySetInnerHTML={{__html: this.state.getData.productDetail_discription}} />
            <h3>{keyword('new-product')?keyword('new-product'):"New products"}</h3>
            
        </Container>
        <Container>
            <div className="it_show_no_data">
                {this.state.getData.productRelated.length===0?
                <h4>{keyword('no-product')?keyword('no-product'):"No product"}</h4>
                :
                <Row>
                    {this._LoopProductRelated(this.state.getData.productRelated)}
                </Row>
                }
                
            </div>
        </Container>
        
        {/* <Footer/> */}
        </div>
        <Dailog 
            show={this.state.ui.showDailog} 
            onHide={() => {this._handleUi('showDailog',false)}}
            label={this.state.ui.message?this.state.ui.message:keyword('please-select-a-product-type')?keyword('please-select-a-product-type'):"Please select a product"}
            />
        
        <ComfirmBuy 
            onShow={this.state.ui.receipt} 
            onHide={()=>{this._handleUi('receipt',false)}} 
            dataCart={this.state.getData.dataBuyNow}
            total={this.state.calculateTotol}
        />
    
        <ButtonBuyFooter functionBuy={()=>{this._handleBuyNow()}} functionAdd={()=>{this._handleAddToCart();this._handleUi('buyNowOrNot',false)}}/>
        <Modal
            show={this.state.ui.showDialogAddedTocart} 
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal_added_to_cart"
            >
            <Modal.Body style={{textAlign:'center'}}>
            <img src={GIF.done_add_tocart} id="image_gif_done_added_tocart"/>
            <br/>
            <h1 >{keyword('product-successfully-carted')?keyword('product-successfully-carted'):"Product add to cart successfully"}</h1>
            </Modal.Body>
            <Modal.Footer style={{justifyContent:'center'}}>
                <button id="btn_contenue_shopping_home" onClick={()=>{this.setState({ui:{...this.state.ui,showDialogAddedTocart:false}})}}>{keyword('word-for-continue-buying')?keyword('word-for-continue-buying'):"Continue Buying"}</button>
            
                <button id="btn_contenue_shopping_home">
                <Link to="/addtocart">
                    <span>{keyword('go-to-cart')?keyword('go-to-cart'):"Go to cart"}</span>
                </Link> 
                </button>
            
            </Modal.Footer>
        </Modal>
       
    </div>
    );
  }
}
