import React, { Component } from 'react'
import {Row,Col,Modal} from 'react-bootstrap'
import OneProduct from '../containers/compornentOneProduct'
import '../Style/profile.css'
import '../Assets/color.css'
import {Close,CloseOutlined} from '@material-ui/icons'
import NavigationsNew from '../containers/navigationContainer'
import{LOGO,IMAGES,NOTIMAGE} from '../Assets/images'
import ProductRecommand from '../Component/productsRecommend'
import { Link } from 'react-router-dom';
import {STORE_URL_IMAGE,MEMBER_URL_IMAGE,LOGO_IMAGE_URL} from '../modules/app/config'
import {FavoriteBorder} from '@material-ui/icons'
import ChangePassword from '../containers/compornentChangePassowrd'
import NewPin from '../containers/compornentNewPin'
import AddAddress from '../containers/compornentAddAddress';
import OrderHistory from '../containers/componentOrderHistory';
import groupBy from "lodash.groupby"
import reduce from "lodash.reduce"
import moment from 'moment';
import 'moment/locale/km';
import Privacy from '../Component/privacy'
import TermOfServer from '../Component/termOfService'
import {Digital} from 'react-activity';
import { axios } from "../modules/app";
import {setColor,setLayout} from '../utils/colors'
import {keyword} from '../utils/keywords'
import {store} from '../store/index'
import amount from '../utils/fomartAmount'
export default class Profile extends Component {
    constructor(props){
        super(props);
        this.state={
           showSettingColor:0,
           localColor:'#fff',
           statementData: [],
           data:{
               imageProfile:'',
               userName:'',
               userPhone:'',
            
               productLiked:[],
               noMore_productLiked:false,
               productLikedPage:1,

               shopLiked:[],
               shopLikedPage:1,
               followedStore_no_more:false,

               indxFollowedStore:'',

               transaction:[],
               pageTransaction:1,
               DetailTrananction:'',
               userWallet:0,
               userID:''
           },
           ui:{
               title:'ប្រវិត្តិការទិញ',
               editPassword:false,
               CreatePassword:false,
               addAddress:false,
               history:false,
               personal_role:false,
               privatcy:false,
               aboutwebsite:false,
               termOfService:false,

               transactionNull:false,
               showDetailTrananction:false,
               showWallet:false
           },
            data_type: {
                wingAgent: 'ដាក់ប្រាក់តាមភ្នាក់ងារវីង',
                'Cash-In': 'ដាក់ប្រាក់',
                buy: 'ការទិញទំនិញ',
                shipping: 'តម្លៃដឹកជញ្ជូន',
                abaAccount: 'វេរប្រាក់ទៅគណនីABA',
                topup: 'បញ្ចូលលុយទូរស័ព្ទ',
                abaCashIn:'បញ្ចូលលុយតាមABA',
                webCashIn:'បញ្ចូលលុយតាមអ្នកគ្រប់គ្រង',
                webCashOut:'ដកលុយតាមអ្នកគ្រប់គ្រង'
            }
        }
        
    }
    componentDidMount(){
        try{
            this.props.getUserWallet();
        }catch(e){

        }
    }
    componentWillReceiveProps(nextProps){
        const{productData,user,shopData,error_getFollowedStores}=this.props;
        if(nextProps.productData.productsLiked && nextProps.productData.productsLiked!=productData.productsLiked){
            if(nextProps.productData.productsLiked.message==='success'){
                var data = nextProps.productData.productsLiked['results'];
                if(data.length>0){
                    if(this.state.data.productLiked.length===0){
                        this.setState({
                            data:{...this.state.data,productLiked:data}
                        });
                    }else{
                        var dataOld=this.state.data.productLiked;
                        data.map((dat)=>{
                            dataOld.push(dat);
                        });
                        this.setState({
                            data:{...this.state.data,productLiked:dataOld}
                        });
                    }
                }else{
                    this._handle_state_data('followedStore_no_more',true)
                }
            }
        }
        if(nextProps.shopData.storesFollowed && nextProps.shopData.storesFollowed != shopData.storesFollowed){
          if(nextProps.shopData.storesFollowed.message==='success'){
                var data = nextProps.shopData.storesFollowed['data'];
                if(data.length>0){
                    if(this.state.data.shopLiked.length===0){
                        this.setState({
                            data:{...this.state.data,shopLiked:data}
                        });
                    }else{
                        var dataOld=this.state.data.shopLiked;
                        data.map((dat)=>{
                            dataOld.push(dat);
                        });
                        this.setState({
                            data:{...this.state.data,shopLiked:dataOld}
                        });
                    }
                }else{
                    this._handleUi('transactionNull',true)
                }
            }
        }
        if(nextProps.error_getFollowedStores && nextProps.error_getFollowedStores!= error_getFollowedStores){
            if(nextProps.error_getFollowedStores.data.message==="no_store"){
              this.setState({
                  data:{...this.state.data,followedStore_no_more:true}
              })
            }
        }
        if(nextProps.shopData.follow_store && nextProps.shopData.follow_store!=shopData.follow_store){
            let newData = this.state.data.shopLiked;
            let row=newData[this.state.data.indxFollowedStore].store;

            if(nextProps.shopData.follow_store.message==='success' && nextProps.shopData.follow_store.data==='unfollowed'){
                let array=this.state.data.shopLiked;
                let row=array[this.state.data.indxFollowedStore];
                let stories=row.stories;
                let store=row.store;
                store={...store,followers:store.followers-1,youFollowed:0 }
                
                let redy={
                    "stories":stories,
                    "store":store
                }
                
                array[this.state.data.indxFollowedStore]=redy;
            
                let unfollowed=this.state.data.shopLiked;
            
                this.setState({
                    data:
                        {...this.state.data,
                            stores:array,
                        }
                });
                unfollowed.splice(this.state.data.indxFollowedStore,1);
            }
        }
        if(nextProps.user.get_transaction_user && nextProps.user.get_transaction_user!= user.get_transaction_user){
            if(nextProps.user.get_transaction_user.message==='success'){
                var data=nextProps.user.get_transaction_user.results;
                let sectionedData = groupBy(nextProps.user.get_transaction_user.results, function (item) {
                    return moment(item.datatime).format("YYYY/MM/DD");
                });
                sectionedData = reduce(
                    sectionedData,
                    (acc, next, index) => {
                        acc.push({
                            key: index,
                            title: index,
                            data: next
                        });
                        return acc;
                    },
                    []
                ).sort((a, b) => a.date < b.date ? -1 : 1)
                this.setState({
                    data:{...this.state.data,pageTransaction:this.state.data.pageTransaction + 1}
                });
              
                if(sectionedData.length>0){
                    if(this.state.data.pageTransaction===1){
                        this.setState({
                            statementData:sectionedData
                        });
                    }
                    else{
                        var data= this.state.statementData;
                        sectionedData.map((ta)=>
                            {
                                data.push(ta)
                            } 
                        )
                        this.setState({
                            statementData:data
                        })
                    }
                }else{
                    this.setState({
                        ui:{...this.state.ui,transactionNull:true}
                    })
                }
                this.props.get_orderHistory();
                this.props.get_address();
            }
        }
        
        if(nextProps.user.user_wallet && nextProps.user.user_wallet!=user.user_wallet){
            
            if(nextProps.user.user_wallet.message==='success'){
                this.setState({
                    data:{
                        ...this.state.data,userWallet:
                        nextProps.user.user_wallet.results.balance
                    }
                })
                this._getDataUser();
                this.props.getTransaction(this.state.data.pageTransaction);
            }
        }
    }
    _handleUi(key,value){
        this.setState({
            ui:{
                ...this.state.ui,
                [key]:value
            }
        })
    }
    _handleFollow(alias,index){
        this.setState({
            data:{...this.state.data,indxFollowedStore:index}
        })
        this.props.followStore({"storeAlias":alias});
    }
    _handle_state_data(key,value){
        this.setState({
            data:{
                ...this.state.data,[key]:value
            }
        })
    }

    _getDataUser(){
        var dataUser=JSON.parse(localStorage.getItem('persist:amatakWebsite'));
        var JsonDATA=JSON.parse(dataUser['user'])['login']['data'];
        if(JsonDATA['image']){
            this.setState({
                data:{...this.state.data,
                    imageProfile:MEMBER_URL_IMAGE+JsonDATA['image'],
                    userID:JsonDATA['userName'],
                    userName:JsonDATA['fullName'],
                    userPhone:JsonDATA['fullPhoneNumber']}
            })
        }else{
            this.setState({
                data:{...this.state.data,
                    userID:JsonDATA['userName'],
                    userName:JsonDATA['fullName'],
                    userPhone:JsonDATA['fullPhoneNumber']}
            })
        }
        
    }
    _onClick_mind_user(type){
        if(type && type==="productLiked"){
            this.setState({
                ui:{...this.state.ui,title:'ទំនិញចូលចិត្ត'}
            });
            this.props.getProductsLike(this.state.data.productLikedPage);
        }
        if(type && type==="shopLiked"){
            this.setState({
                ui:{...this.state.ui,title:'ហាងចូលចិត្ត'}
            });
            this.props.followedStores(this.state.data.shopLikedPage)
        }
        if(type && type==="orderHistory"){
            this.setState({
                ui:{...this.state.ui,title:'ប្រវិត្តិការទិញ'}
            })
        }
       this.setState({
           data:{...this.state.data,productLiked:[],shopLiked:[]}
       })
    }
    _loopProducts(Data){
        var result=[];
        if(Data){
            Data.map((oneProd,index)=>{
                result.push(
                    <OneProduct
                         fun_clickLike={()=>{this._userOnClickLiked(index,oneProd)}}
                         key={index} dataProduct={oneProd} prodsSKU={this.state.data.prodsSKU} 
                    functionbtn={()=>{
                        this._handleData('dataDialog',oneProd);
                        this._handleUi('showDialog',true);
                    }} />
                )
            })
        }
        return(result)
    }
    _hadle_transaction_dataMore(){
        this.props.getTransaction(this.state.data.pageTransaction+1);
        this.setState({
            data:{...this.state.data,pageTransaction:this.state.data.pageTransaction+1}
        })
    }
    _loopShops(shop){
        let colP=setColor('primaryColor');
        let bgP=setColor('primaryBackground');
        let bgL=setColor('lightBackground');
        let colL=setColor('lightColor');
        var result =[];
            if(shop.length>0){
                    result.push(
                        shop.map((data,index)=>{
                            return(
                                <Col md={4} className="oneShop_edit" key={index}>
                                        <div className="div_control_shop">
                                        <div className="div_top_pro" style={{backgroundColor:bgL,color:colL}}>
                                            <div className="div_Image">
                                                <img src={STORE_URL_IMAGE + data['store']['image']}/>
                                                <h1>
                                                    <span id="nameStore"> {data['store']['name']}</span>
                                                    <br/>
                                                     {keyword('customers')?keyword('customers'):"Customers"}៖<span id="follwer">{data['store']['followers']}</span>
                                                </h1>
                                            </div>
                                            {
                                                    data['store']['youFollowed']===1? 
                                                    <button onClick={()=>{this._handleFollow(data['store']['alias'],index)}}>
                                                        <FavoriteBorder id="iconFavoritebtnClick"/>
                                                    </button>
                                                    :
                                                    <button onClick={()=>{this._handleFollow(data['store']['alias'],index)}}>
                                                        <FavoriteBorder id="iconFavoritebtn"/>
                                                    </button>
                                            }
                                        </div>
                                        <div className="data_story_shop">
                                            {
                                                // data['stories'] ? <ProductRecommand count_show={4} showTitle={false} products={data['stories']} title={""}/>:null
                                            }
                                        </div>
                                        <div className="btn_go_to_shop_die">
                                            <button style={{backgroundColor:bgP,color:colP}}>
                                                <Link to={{
                                                    pathname: "/DetailShop",
                                                    storeAlias:data
                                                }}>
                                                {keyword('go-to-cart')?keyword('go-to-cart'):"Go to cart"}
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                    
                                </Col>
                            )
                        })
                ) 
            }else{
                result.push(
                    <div className="not_shop_" key={0}>
                        <h4>{keyword('there-is-no-store-you-like')?keyword('there-is-no-store-you-like'):"No favorite shops"}</h4>
                    </div>
                )
            }
            return result
    }
    _click_change_password(){
        var paymentPin=JSON.parse(localStorage.getItem('persist:amatakWebsite'));
        var JsonDATA=JSON.parse(paymentPin['user'])['login']['data']['paymentPin'];
        if(JsonDATA===true){
          //it setted pin already
            this._handleUi('editPassword',true);
        }
        else{
            this._handleUi('CreatePassword',true);
        }
    }
    _handle_transaction(data){
        if(data){
            var datatime = moment(data.date).format("DD-MMM-YYYY, hh:mm a");
            if(data.data.type && data.data.type!==''){
                return(
                    <div>
                        <p className="detail_train_one_one">
                            <span id="mainTitle_trancsantion">
                                {
                                    this.state.data_type[data.data.type]
                                }
                            </span>
                                <hr/>
                                {keyword('id')?keyword('id'):"ID"}:<span>{data['ID']}</span>
                                <br/>
                                {/* checkType transaction */}
                                {
                                    data.data.type==='wingAgent'?
                                    <div>
                                        លេខទូរសព្ទអ្នកផ្ញើ:<span>19222</span>
                                        <br/>
                                        លេខទូរសព្ទអ្នកទទួល:<span>19222</span>
                                        <br/>
                                        លេខកូដសំងាត់៨ខ្ទង់:<span>19222</span>
                                        <br/>
                                        ចំនួនទឹកប្រាក់:<span> $ 19222</span>
                                        <br/>
                                        ថ្លៃសេវាកម្ម(USD):<span>19222</span>
                                    </div>
                                    :data.data.type==='Cash-In'?
                                    <div>
                                        បញ្ចូលប្រាក់តាម:<span>{data.data['partnerName'] && data.data['partnerName']}</span>
                                        <br/>
                                        ចំនួនទឹកប្រាក់បញ្ចូល:<span>$ {data['data']['amount']>0?amount(data['data']['amount']):"0"} </span>
                                        <br/>
                                        ថ្លៃសេវាកម្ម(USD):<span>$ {data.data['fee']>0?amount(data.data['fee']):"0"} </span>
                                    </div>
                                    :data.data.type==='buy'?
                                    <div>
                                        លេខសម្គាល់ការបញ្ជាទិញ:<span>{data['data']['orderID'] && data['data']['orderID']}</span>
                                        <br/>
                                        តម្លៃ:<span>$ {data['data']['price']>0?amount(data['data']['price']):"0"}</span>
                                        <br/>
                                        ប្រភេទទំនិញ:<span>{data['data']['productSKU']}</span>
                                        <br/>
                                        ចំនួន:<span>{data['data']['qty']}</span>
                                        <br/>
                                        តម្លៃសរុប:<span>$ {data['data']['total']>0?amount(data['data']['total']):"0"} </span>
                                    </div>
                                    :data.data.type==='shipping'?
                                    <div>
                                        តម្លៃសេវ៉ាដឹកជញ្ជូន:<span >{data['data']['total']>0?amount(data['data']['total']):"0"} </span>
                                    </div>
                                    :data.data.type==='abaAccount'?
                                    <div>
                                        ឈ្មោះគណនី:<span>{data['data']['accountName']}</span>
                                        <br/>
                                        លេខគណនី:<span>{data['data']['accountNumber']}</span>
                                        <br/>
                                        ចំនួនទឹកប្រាក់វេរទៅ:<span>$ {data['data']['amountPaid']>0?amount(data['data']['amountPaid']):"0"} </span>
                                        <br/>
                                        ចំនួនទឹកប្រាក់ទទួលបាន:<span>$ {data['data']['amountReceive']?amount(data['data']['amountReceive']):"0"} </span>
                                        <br/>
                                        តម្លៃសេវ៉ាកម្ម:<span>$ {data['data']['fee']>0?amount(data['data']['fee']):"0"} </span>
                                    </div>
                                    :data.data.type==='topup'?
                                    <div>
                                        ប្រព័ន្ធ:<span>{data['data']['operatorName']}</span>
                                        <br/>
                                        ចំនួនទឹកប្រាក់(USD):<span>$ {data['data']['amount']>0?amount(data['data']['amount']):"0"}</span>
                                    </div>
                                    :data.data.type==='abaCashIn'?
                                    <div>
                                        ចំនួនទឹកប្រាក់បញ្ចូល:<span>$ {data['data']['amount']>0?amount(data['data']['amount']):"0"}</span>
                                        <br/>
                                        ចំនួនទឹកប្រាក់បញ្ចូល(USD):<span>$ {data['data']['amount']>0?amount(data['data']['amount']):"0"} </span>
                                        <br/>
                                        តម្លៃសេវ៉ាកម្ម:<span>$ {data['data']['fee']>0?amount(data['data']['fee']):"0"}</span>
                                    </div>:
                                    data.data.type==='webCashIn'?
                                    <div>
                                        ចំនួនទឹកប្រាក់បញ្ចូល:<span>$ {data['amount']>0?data['amount']:"0"} </span>
                                        <br/>
                                    </div>:
                                    data.data.type==='webCashOut'?
                                     <div>
                                     ចំនួនទឹកប្រាក់បញ្ចូល:<span>$ {data['data']['amount']>0?amount(data['data']['amount']):"0"} </span>
                                     <br/>
                                     ទឹកប្រាក់បានដក:<span>$ {data['data']['amount']>0?amount(data['data']['amount']):"0"}</span>
                                     <br/>
                                     តម្លៃសេវ៉ាកម្ម:<span>$ {data['data']['fee']>0?amount(data['data']['fee']):"0"} </span>
                                 </div>:
                                 null
                                }
                                សរុប:<span style={data['amount']>0?{color:'blue'}:{color:'red'}}>$ {data['amount']>0?amount(data['amount']):"0"}</span>
                               
                        </p>
                        <div className="dailog_show_transaction_date">
                            {
                                datatime
                            }
                        </div>
                        
                    </div>
                )
            }else{
                return(
                    <div>
                        {
                            datatime
                        }
                    </div>
                )
            }
        }
    }
    render() {
        let bgP= setColor('primaryBackground');
        let colP=setColor('primaryColor');
        let dataTransaction = this.state.statementData.map((a, i) => {
            let item = a.data.map((e, j) => {
                var types = JSON.parse(e.tranData);
                return (
                    <Row key={j} className="one_action_tran" onClick={()=>{ 
                            this.setState({
                                ui:{
                                    ...this.state.ui,
                                    showDetailTrananction:true
                                    },
                                data:{
                                    ...this.state.data,
                                    DetailTrananction:{
                                        'date':e['datatime'],
                                        'data':JSON.parse(e['tranData']),
                                        'ID':e['ID'],
                                        'amount':e['amount']
                                    },
                                }
                                }) 
                                }}>
                           <div className="type_of_action">
                                {types["type"] === 'abaCashIn' ?
                                    (e["status"] === 0 ?
                                        <h6>{this.state.data_type[types.type]}<span> (កំពុងស្នើរសុំ...!)</span></h6>
                                        : 
                                        <h6>{this.state.data_type[types.type]}<span> (ស្នើរសុំត្រូវបានបញ្ចប់...!)</span></h6>)
                                        :
                                        types["type"] === 'abaAccount' ?
                                        (e['status'] === 0 ?
                                            <h6>{this.state.data_type[types.type]}<span > (កំពុងស្នើរសុំ...!)</span></h6>
                                            : <h6>{this.state.data_type[types.type]}<span> (ស្នើរសុំត្រូវបានបញ្ចប់...!)</span></h6>
                                        )
                                        :
                                        this.state.data_type[types.type]
                                }
                            </div>
                            <div className={e.amount > 0 ?"color_blue" : "color_red"}>
                                {
                                    (types["type"] === 'abaCashIn' || types["type"] === 'abaAccount') && e["status"] === 0 ?
                                    <p style={{ color: '#bdbdbd' }}>$ {e.amount>0?amount(e.amount):"0"}</p> :
                                   "$" + e.amount>0?amount(e.amount):"0"
                                }
                            </div>
                    </Row>
                )
            })
            return (
                <Col md={12} key={i} className="one_day_of_transaction">
                    <div className="one_show_day_of_transaction">
                        <div className="hearder_one_day_of_transaction">
                            <p>{a.title}</p>
                        </div>
                        <div>
                            {item}
                        </div>
                    </div>
                </Col>
            )
        });
        let imageLogo=setLayout('logo');
        var info=store.getState().productData;
        return (
            <div>
                <NavigationsNew/>
                <div className='container'>
                    <div className="profile_image">
                        <div className="image_user">
                            <div className="name_user">
                                <img  src={this.state.data.imageProfile?this.state.data.imageProfile:IMAGES.me} id="image_profile_user"></img>
                                <div className="name___">
                                    <h1>{this.state.data.userName && this.state.data.userName}</h1>
                                    <h2>{this.state.data.userPhone && this.state.data.userPhone}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="socail">
                                <button>
                                <i className='fab fa-buffer'></i>{info && info['sitedescription'] && info['sitedescription']['results'][0]?info['sitedescription']['results'][0]['keyword']:""}
                                </button>
                                <button>
                                <i className='fab fa-buffer'></i>{info && info['sitedescription'] && info['sitedescription']['results'][0]?info['sitedescription']['results'][0]['name']:""}
                                </button>
                        </div>
                        <div className="detail_setting">
                            <ul>
                                <li onClick={()=>{this._handleUi('showWallet',true)}} >
                                    <i className='fas fa-wallet' id="icon_VpnKey"></i> {keyword('wellet')?keyword('wellet'):"Wallet"}
                                </li>
                                <li onClick={()=>{this._click_change_password()}}>
                                    <i className='fas fa-user-lock' id="icon_VpnKey"></i> {keyword('change-code')?keyword('change-code'):"Chnage Password"}
                                </li>
                                <li onClick={()=>{this._handleUi('addAddress',true)}}>
                                    <i className='fas fa-map-marked-alt' id="icon_edit_location"></i>{keyword('address')?keyword('address'):"Address"}
                                </li>
                                <li onClick={()=>{this._handleUi('history',true)}}>
                                    <i className='fas fa-user-clock' id="icon_history"></i>{keyword('history')?keyword('history'):"History"}
                                </li>
                                <li onClick={()=>{this._handleUi('privatcy',true)}}>
                                    <i className='fas fa-list-ul'  id="personal_role"></i>{keyword('word-privacy-policy')?keyword('word-privacy-policy'):"Privacy Policy"}
                                </li>
                                <li onClick={()=>{this._handleUi('termOfService',true)}}>
                                    <i className='fas fa-tasks' id="icon_tasks"></i> {keyword('word-terms-of-use')?keyword('word-terms-of-use'):"Terms of use"}
                                </li>
                                <li >
                                    <Link to={"/about"}>
                                        <i className='fas fa-exclamation' id="icon_about"></i>
                                        {keyword('about')?keyword('about'):"About"}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="three_button">
                                <button  style={this.state.ui.title==='ទំនិញចូលចិត្ត' ?{backgroundColor:bgP,color:colP}:null} onClick={()=>{this._onClick_mind_user("productLiked")}} className={this.state.ui.title==='ទំនិញចូលចិត្ត' ? "this_button_active":""}>
                                    {keyword('product-favorite')?keyword('product-favorite'):"Product favorite"}
                                </button>
                                <button  style={this.state.ui.title==='ហាងចូលចិត្ត' ?{backgroundColor:bgP,color:colP}:null} onClick={()=>{this._onClick_mind_user("shopLiked")}} className={this.state.ui.title==='ហាងចូលចិត្ត' ? "this_button_active":""}>
                                    {keyword('favorite-shop')?keyword('favorite-shop'):"Product shop"}
                                </button>
                                <button style={this.state.ui.title==='ប្រវិត្តិការទិញ' ?{backgroundColor:bgP,color:colP}:null}  onClick={()=>{this._onClick_mind_user("orderHistory")}} className={this.state.ui.title==='ប្រវិត្តិការទិញ'?"this_button_active":""}>
                                    {keyword('history')?keyword('history'):"History"}
                                </button>
                        </div>
                    </div>
                   <Row className="setting_small_screen">
                        <Col xs={4} style={{margin:0,padding:0}}>
                            <button style={{borderRight:'1px solid white '}} onClick={()=>{this._handleUi('showWallet',true)}}>
                                <i className='fas fa-wallet' id="icon_VpnKey"></i>  {keyword('wellet')?keyword('wellet'):"Wallet"}
                            </button>
                        </Col>
                        <Col xs={4} style={{margin:0,padding:0}} onClick={()=>{this._click_change_password()}}>
                            <button style={{borderRight:'1px solid white '}}>
                            <i className='fas fa-user-lock' id="icon_VpnKey"></i> {keyword('change-code')?keyword('change-code'):"Chnage Password"}
                            </button>
                        </Col>
                        <Col xs={4} style={{margin:0,padding:0}}>
                            <button onClick={()=>{this._handleUi('addAddress',true)}}>
                            <i className='fas fa-map-marked-alt' id="icon_edit_location"></i>{keyword('address')?keyword('address'):"Address"}
                            </button>
                        </Col>
                        <Col xs={4} style={{margin:0,padding:0}}>
                            <button style={{borderTop:'1px solid white '}} onClick={()=>{this._handleUi('history',true)}}>
                            <i className='fas fa-user-clock' id="icon_history"></i>{keyword('history')?keyword('history'):"History"}
                            </button>
                        </Col>
                        <Col xs={4} style={{margin:0,padding:0}}>
                            <button style={{borderTop:'1px solid white '}} onClick={()=>{this._handleUi('privatcy',true)}}>
                            <i className='fas fa-list-ul'  id="personal_role"></i>{keyword('word-privacy-policy')?keyword('word-privacy-policy'):"Privacy Policy"}
                            </button>
                        </Col>
                        <Col xs={4} style={{margin:0,padding:0}}>
                            <button style={{borderTop:'1px solid white '}} onClick={()=>{this._handleUi('termOfService',true)}}>
                            <i className='fas fa-tasks' id="icon_tasks"></i> {keyword('word-terms-of-use')?keyword('word-terms-of-use'):"Terms of use"}
                            </button>
                        </Col>
                    </Row>
                   <div className="profile_show_data_screen_small">
                        <button style={this.state.ui.title==='ទំនិញចូលចិត្ត' ?{backgroundColor:bgP,color:colP}:null}  onClick={()=>{this._onClick_mind_user("productLiked")}} className={this.state.ui.title==='ទំនិញចូលចិត្ត' ? "this_button_active":""}>
                            {keyword('product-favorite')?keyword('product-favorite'):"Product favorite"}
                        </button>
                        <button style={this.state.ui.title==='ហាងចូលចិត្ត' ?{backgroundColor:bgP,color:colP}:null} onClick={()=>{this._onClick_mind_user("shopLiked")}} className={this.state.ui.title==='ហាងចូលចិត្ត' ? "this_button_active":""}>
                            {keyword('favorite-shop')?keyword('favorite-shop'):"Product shop"}
                        </button>
                        <button style={this.state.ui.title==='ប្រវិត្តិការទិញ' ?{backgroundColor:bgP,color:colP}:null} onClick={()=>{this._onClick_mind_user("orderHistory")}} className={this.state.ui.title==='ប្រវិត្តិការទិញ' ? "this_button_active":""}>
                            {keyword('history')?keyword('history'):"History"}
                        </button>
                   </div>
                    <div className="show_data_user_done">
                        {this.state.ui.title &&
                            <h6 style={{backgroundColor:bgP,color:colP }}>{
                                this.state.ui.title==='ប្រវិត្តិការទិញ'?
                                    keyword('history')?
                                        keyword('history')
                                    :
                                    "Product favorite"
                                :
                                this.state.ui.title==='ហាងចូលចិត្ត'?
                                        keyword('favorite-shop')?
                                            keyword('favorite-shop')
                                        :"Product shop"
                                :
                                    this.state.ui.title==='ទំនិញចូលចិត្ត'?
                                        keyword('product-favorite')?
                                            keyword('product-favorite')
                                            :"History"
                                :""
                            }</h6>
                            }
                            {   this.state.ui.title==="ទំនិញចូលចិត្ត"?
                                    this.state.data.productLiked && this.state.data.noMore_productLiked===false?
                                    <Row>
                                        { this._loopProducts(this.state.data.productLiked)}
                                    </Row>
                                    :
                                    this.state.data.noMore_productLiked===true ?
                                        <h1>{keyword('data-revealed')?keyword('data-revealed'):"No more . . ."}</h1>
                                        :
                                        <h1>{keyword('data-revealed')?keyword('data-revealed'):"No more . . ."}</h1>
                                :this.state.ui.title==="ហាងចូលចិត្ត" ?
                                    this.state.data.shopLiked && this.state.data.followedStore_no_more===false?
                                    <Row>
                                        {this._loopShops(this.state.data.shopLiked)}
                                    </Row>
                                    :
                                    this.state.data.followedStore_no_more===true ?
                                        <h1>{keyword('data-revealed')?keyword('data-revealed'):"No more . . ."}</h1>
                                        :
                                        <h1>{keyword('data-revealed')?keyword('data-revealed'):"No more . . ."}</h1>
                                :this.state.ui.title==="ប្រវិត្តិការទិញ" ?
                                    <OrderHistory/>  
                                :null
                            }
                    </div>
                </div>
                <ChangePassword
                    show={this.state.ui.editPassword}
                    onHide={()=>{this._handleUi("editPassword",false)}}
                />
                <NewPin
                    onShow={this.state.ui.CreatePassword} 
                    onHide={()=>{this._handleUi("CreatePassword",false)}} 
                />
                <AddAddress 
                   onShow={this.state.ui.addAddress} 
                   onHide={()=>{this._handleUi('addAddress',false)}} 
               />
                <Privacy
                    show={this.state.ui.privatcy} 
                    onHide={()=>{this._handleUi('privatcy',false)}}
                />
                <TermOfServer 
                    show={this.state.ui.termOfService}
                    onHide={()=>{this._handleUi('termOfService',false)}}
                    />
                <Modal
                    animation={true}
                    show={this.state.ui.history} 
                    size="lg"
                    style={{backgroundColor:'gray'}}
                    className="modal_personal_role"
                    >
                    <Modal.Header>
                        <p id="title_reHistory">ប្រវិត្តិ</p>
                        <Close id="id_remove" onClick={()=>this._handleUi('history',false)}/>
                    </Modal.Header>
                    <Modal.Body style={{textAlign:'center',overflow:'scroll'}}>
                    {
                        dataTransaction
                    }
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        {
                            this.props.user.loading && this.props.user.loading===true ?
                            <div className="loadingSmall_transation">
                                <Digital color="#727981" size={20} speed={1} animating={true} />
                            </div>
                            :
                            this.state.ui.transactionNull===false?
                            <button onClick={()=>{this._hadle_transaction_dataMore()}} id="btn_transaction_dataMore">{keyword('see-more-products')?keyword('see-more-products'):"See more . . ."}</button>
                            :
                            <p>{keyword('data-revealed')?keyword('data-revealed'):"No more . . ."}</p>
                        }
                    
                    </Modal.Footer>
                </Modal>
                <Modal
                    animation={true}
                    show={this.state.ui.showDetailTrananction} 
                    size="sm"
                    style={{backgroundColor:'gray'}}
                    className="modal_personal_role"
                    centered
                    >
                    <Modal.Body style={{textAlign:'center'}}>
                        <div className="dailog_show_transaction">
                            {
                                this._handle_transaction(this.state.data.DetailTrananction)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        <button id="btnHide" onClick={()=>{this._handleUi('showDetailTrananction',false)}}> {keyword('ok')?keyword('ok'):"OK"}  </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    animation={true}
                    show={this.state.ui.showWallet} 
                    size="md"
                    style={{backgroundColor:'gray'}}
                    centered
                    >
                    <Modal.Body style={{textAlign:'center'}}>
                    <div className="header_changePassword">
                        <img src={imageLogo?LOGO_IMAGE_URL+imageLogo:NOTIMAGE} id="img_logo_changePassword"/>
                        <CloseOutlined id="iconCloseChangePassword" onClick={()=>{this._handleUi('showWallet',false)}}/>
                    </div>
                    <div className="wallet_User_div">
                        <h2>
                            $
                            {
                                amount(this.state.data.userWallet)
                            }
                        </h2>
                        <p>
                           {keyword('account-number')?keyword('account-number'):"Account number"} :<span>{this.state.data.userID}</span>
                        </p>
                        <div>
                            <button style={{backgroundColor:bgP,color:colP}} id="btn_caseIN_video">{keyword('word-cash-in')?keyword('word-cash-in'):"How to cash-in"}</button>
                        </div>
                    </div>
                    <div>

                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        <button id="btnHide" onClick={()=>{this._handleUi('showWallet',false)}}>{keyword('ok')?keyword('ok'):"OK"} </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
