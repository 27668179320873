import { success, error, abort } from "redux-saga-requests";

export const TEST = 'TEST'
export const GET_NAME = 'GET_NAME'
export const APP_REFRESH = 'APP_REFRESH'
export const APP_WILL_REFRESH = 'APP_WILL_REFRESH'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const CHENGE_TITLE = 'CHENGE_TITLE'

export const fetchTest = () => ({
  type: TEST
});
export const getName = payload => ({
  type: GET_NAME,
  payload
});

export const appChangeLanguage = (payload) => ({
  type: CHANGE_LANGUAGE,
  payload
});
export const appChangeTitle = () => ({
  type: CHENGE_TITLE
});


export const appRefresh = () => ({
  type: APP_REFRESH
});

export const appWillRefresh = () => ({
  type: APP_WILL_REFRESH
});


const initialState = {
  loading: false,
  error: false,
  lang:'en'
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * TEST
     */
    case TEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(TEST):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(TEST):
    console.log('error TEST', action.payload);     
    return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(TEST):
      return { ...state, loading: false };
      /**
     * GET_NAME
     */
    case GET_NAME:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_NAME): 
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case error(GET_NAME):
      return {
        ...state,
        loading: false,
      };
    case abort(GET_NAME):
      return { ...state, loading: false };
    /**
     * CHANGE_LANGUAGE
     */
     case CHANGE_LANGUAGE:
      return {
      ...state,
      loading: true,
      error: false,
      };
  case success(CHANGE_LANGUAGE):         
      return {
      ...state,
      ...action.payload,
      loading: false
      };
  case error(CHANGE_LANGUAGE):
      return {
      ...state,
      ...action.payload,
      loading: false,
      error: true
      };
  case abort(CHANGE_LANGUAGE):
      return { ...state, loading: false };  
  /**
   * CHENGE_TITLE
   */
  case CHENGE_TITLE:
        return {
        ...state,
        loading: true,
        error: false,
        };
  case success(CHENGE_TITLE):         
        return {
        ...state,
        ...action.payload,
        loading: false
        };
  case error(CHENGE_TITLE):
        return {
        ...state,
    ...action.payload,
    loading: false,
    error: true
    };
  case abort(CHENGE_TITLE):
  return { ...state, loading: false };


    /**
     * DEFAULT CASE
     */
    default:
      return state;
  }
}

export default registerReducer;