import { success, error, abort } from "redux-saga-requests";

export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";
export const OWN_SOCKET = "OWN_SOCKET";
export const USER_MESSAGE_LIST = "USER_MESSAGE_LIST";
export const OLD_MESSGES = "OLD_MESSGES";
export const PARTNER_SEEN = "PARTNER_SEEN";
export const SEND_MESSAGE = "SEND_MESSAGE"
export const SELECT_USER_CHAT = "SELECT_USER_CHAT"
export const USER_MESSAGE_LIST__LOCAL="USER_MESSAGE_LIST__LOCAL";
    
export const onReceiveMessage = payload => ({
  type: RECEIVE_MESSAGE,
  payload
});

export const getOwnSocket = payload => ({
  type: OWN_SOCKET,
  payload
});

export const getUserMessageList = payload => ({
  type: USER_MESSAGE_LIST,
  payload
});

export const getOldMessages = payload => ({
  type: OLD_MESSGES,
  payload
});

export const updatePartnerSeen = payload => ({
  type: PARTNER_SEEN,
  payload
});

export const onSendMessage = payload => ({
  type: SEND_MESSAGE,
  payload
});

export const selectUserChat = payload => ({
  type: SELECT_USER_CHAT,
  payload
});

export const getUserMessageList__ = payload => ({
  type: USER_MESSAGE_LIST__LOCAL,
  payload
});

const initialState = {
  loading: false,
  error: false,
  messages:[],
  selected_user:false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * RECEIVE_MESSAGE
     */
    case RECEIVE_MESSAGE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(RECEIVE_MESSAGE):   
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(RECEIVE_MESSAGE):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(RECEIVE_MESSAGE):
      return { ...state, loading: false };

      /**
     * OWN_SOCKET
     */
    case OWN_SOCKET:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(OWN_SOCKET):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(OWN_SOCKET):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(OWN_SOCKET):
      return { ...state, loading: false };

       /**
     * USER_MESSAGE_LIST
     */
    case USER_MESSAGE_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(USER_MESSAGE_LIST):      
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(USER_MESSAGE_LIST):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(USER_MESSAGE_LIST):
      return { ...state, loading: false };

        /**
     * OLD_MESSGES
     */
    case OLD_MESSGES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(OLD_MESSGES):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(OLD_MESSGES):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(OLD_MESSGES):
      return { ...state, loading: false };

        /**
     * PARTNER_SEEN
     */
    case PARTNER_SEEN:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(PARTNER_SEEN):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(PARTNER_SEEN):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(PARTNER_SEEN):
      return { ...state, loading: false };

        /**
     * SELECT_USER_CHAT
     */
    case SELECT_USER_CHAT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(SELECT_USER_CHAT):        
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SELECT_USER_CHAT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SELECT_USER_CHAT):
      return { ...state, loading: false };

          /**
     * USER_MESSAGE_LIST__LOCAL
     */
    case USER_MESSAGE_LIST__LOCAL:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(USER_MESSAGE_LIST__LOCAL):   console.log(USER_MESSAGE_LIST__LOCAL, action.payload);
         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(USER_MESSAGE_LIST__LOCAL):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(USER_MESSAGE_LIST__LOCAL):
      return { ...state, loading: false };
      
    default:
      return state;
  }

  
  
}

export default userReducer;