import { call, put, takeLatest, retry } from "redux-saga/effects";
import {error,success} from "redux-saga-requests";
import {axios} from "../app";
import {
    STORE_ALL,
    FOLLOWING_STORE,
    FOLLOWED_STORES,
    STORE_STORIES
} from './reducer'
import {normalize} from "../../utils/normalize"

export function* getStores({ payload }) {
    try {
        const stores = yield call(axios.get,"stores/all/"+payload );
        const requested = normalize(stores)
        yield put({
            type: success(STORE_ALL),
            payload: {
                stores: requested
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(STORE_ALL),
            payload: {
                error_getStores:message
            }
        });
    }
}
export function* postFollowStores({ payload }) {
    try {
        const follow_store = yield call(axios.post,"/follow_store",payload );
        const requested = normalize(follow_store);
       
        yield put({
            type: success(FOLLOWING_STORE),
            payload: {
                follow_store: requested
            }
        });
    } 
    catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(FOLLOWING_STORE),
            payload: {
                errorProduct:"Error POST_F0LLOW STORE"
            }
        });
    }
}
export function* getFollowedStores({ payload }) {
    try {
        const storesFollowed = yield call(axios.get,"stores/followed/"+payload );
        const requested = normalize(storesFollowed)
        yield put({
            type: success(FOLLOWED_STORES),
            payload: {
                storesFollowed: requested
            }
        });
    } 
    catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(FOLLOWED_STORES),
            payload: {
                error_getFollowedStores:message
            }
        });
    }
}

export function* getStoreStories({ payload }) {
    try {
        const storeStories = yield call(axios.get,"slide");
        const requested = normalize(storeStories)
        yield put({
            type: success(STORE_STORIES),
            payload: {
                storeStories: requested
            }
        });
    } 
    catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(STORE_STORIES),
            payload: {
                error_storeStories:message
            }
        });
    }
}


export function* ShopSaga() {
    yield takeLatest(STORE_ALL,getStores);
    yield takeLatest(FOLLOWED_STORES,getFollowedStores);
    yield takeLatest(FOLLOWING_STORE,postFollowStores);
    yield takeLatest(STORE_STORIES,getStoreStories);
}
