import { connect } from "react-redux";
import ComfirmBuy from "../Component/ComfirmBuy"
import {
    get_address,
    get_shipping_address_price,

  } from '../modules/address/reducer'
import {
  confirm_pin,
  checkOut_user,
  getCartList,
  setNotification
} from '../modules/user/reducer'
import productData, {getDiscoutCart}from '../modules/product/reducer'
const mapStateToProps = state => ({
  ...state,
  get_addressData:state.AddressData.get_address,
  buyAlready:state.user.check_out_user,
  errorsent_trancsaction:state.user.errorsent_trancsaction,
  discout_cart:state.productData.discout_cart
});

const mapDispatchToProps = {
  get_address,
  confirm_pin,
  getCartList,
  setNotification,
  get_shipping_address_price,
  checkOut_user,
  getDiscoutCart
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComfirmBuy);
