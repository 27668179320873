import React, { Component } from 'react';
import {Carousel} from 'react-bootstrap';
import '../Style/Component/storestories.css'
import {STORE_URL_IMAGE,PROUDCT_URL_IMAGE} from '../modules/app/config'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Loading from '../Component/loading'
import {NOTIMAGE} from '../Assets/images'
import {SLIDE_URL_IMAGE} from '../modules/app/config'
export default class Slide extends Component {
    constructor(props){
        super(props);
        this.state={
            index:0,
            data:[]
        }
    }
    componentDidMount(){
    }
    _loopSlider=(storeStories)=>{
        var rs=[];
        if(storeStories){
            storeStories.map((sl,indx)=>{
                rs.push(
                    <div key={indx} className="div_store_stories">
                      <div>
                            <img className="image_store_stories" src={sl['sliImage']? SLIDE_URL_IMAGE+sl['sliImage']:NOTIMAGE}/>
                            {/* <img className="image_store_stories_pf_store" src={sl['store']['image']?STORE_URL_IMAGE+sl['store']['image']:NOTIMAGE}/> */}
                      </div>
                    </div>
                )
            })
        }
        return rs;
    }
    
  render() {
    const {storeStories} =this.props;
    const settings = {
        dots:true,
        lazyLoad: 'ondemand',
        slidesToShow:1,
        slidesToScroll: 1,
        accessibility:true,
        prevArrow:(<button type="button"  id="prev_store_stories_home_silde">prevArrow</button>),
        nextArrow: (<button  type="button" id="next_store_stories_home_slide">prevArrow</button>),
        arrows: true,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1199,
                  settings: {
                    slidesToShow:1,
                    slidesToScroll: 1,
                    autoplaySpeed: 3000,
                    autoplay: false,
                  }
                },
            {
            breakpoint: 991,
              settings: {
                slidesToShow:1,
                slidesToScroll: 1,
                autoplaySpeed: 3000,
                autoplay: false,
              }
            },
            {
            breakpoint: 767,
                settings: {
                slidesToShow:1,
                slidesToScroll: 1,
                autoplaySpeed: 3000,
                autoplay: false,
                dots:false,
                }
            },
            {
            breakpoint: 575,
                settings: {
                slidesToShow:1,
                slidesToScroll: 1,
                autoplaySpeed: 3000,
                autoplay: false,
                dots:false,
                }
            }
          ]
    };

    return(
        <div className="slide_background"> 
             <Slider {...settings}>
             {
                 this._loopSlider(storeStories)
             }
            </Slider>
      
    </div>
    );
  }
}

export class FixedSlide extends Component{
    render(){
        return(
            <div>
                A
            </div>
        )
    }
}