import React, { Component } from 'react'
import {Container,Row,Col} from 'react-bootstrap'
import '../Style/search.css'
import '../Assets/color.css'
import {Badge,Drawer,Input} from '@material-ui/core'
import {Link} from 'react-router-dom'
import OneProduct from '../containers/compornentOneProduct'
import Navigations from '../containers/navigationContainer'
import {Digital} from 'react-activity';
import { keyword } from '../utils/keywords'
export default class Search extends Component {
    constructor(props){
        super(props);
        this.state={
            ui:{},
            data:{
                stringSearch:'',
                categories:[],
                products:[]
            }
        }
    }
    componentDidMount(){
        var stringSearch=this.props.match.params.stringSearch;
        this.props.getPorductCategory();
        if(stringSearch && stringSearch!=null){
            this.props.getPorductFilter(stringSearch);
        }
        this._handleData('stringSearch',stringSearch)
    }
    componentWillReceiveProps(nextProps){
        const {productData,match}=this.props;
        if(nextProps.match && nextProps.match!=match){
            this.props.getPorductFilter(nextProps.match.params.stringSearch);
            this._handleData('stringSearch',nextProps.match.params.stringSearch)
        }
        if(nextProps.productData.products_category && nextProps.productData.products_category!=productData.products_category){
          var data=nextProps.productData.products_category.results;
            this.setState({
                data:{...this.state.data,categories:data}
            })
        }if(nextProps.productData.products_filter&&nextProps.productData.products_filter!=productData.products_filter){
            if(nextProps.productData.products_filter.message==='success'){
                var data=nextProps.productData.products_filter.results;
                this.setState({
                    data:{...this.state.data,products:data}
                })
            }
        }
      }
    _handleData(key,value){
        this.setState({
            data:{...this.state.data,[key]:value}
        })
    }
    _handleUi(key,value){
        this.setState({
            data:{...this.state.ui,[key]:value}
        })
    }
    _loopContain(nav){
        var result =[];
        if(nav){
          nav.map((title,index)=>{
            if(index){
              result.push(
                <Link key={index} to={{
                      pathname: "/oneCategory",
                      CategoryID:title['categoryID']
                      }} id="linkInFooter">
                    <h1>{title['name']}</h1>
                </Link> 
              )
            }
          })
        }
        return result
      }
      _loopProducts(Data){
        var result=[];
        if(Data){
            Data.map((oneProd,index)=>{
                result.push(
                    <OneProduct
                        showBtnCart={true}
                        fun_clickLike={()=>{this._userOnClickLiked(index,oneProd)}}
                        key={index} dataProduct={oneProd} prodsSKU={this.state.data.prodsSKU} 
                        functionbtn={()=>{
                            this._handleData('dataDialog',oneProd);
                            this._handleUi('showDialogAddTocart',true);
                            this.props.productSKU(oneProd['ID']);
                        }} />
                )
            })
        }
        return(result)
    }
    render() {
        return (
            <div className="resultSearch">
                <Navigations/>
                    
                <Container>
                    <h1>លទ្ធិផលការស្វែងរក៖ <span>{this.props.match.params.stringSearch}</span></h1>
                    <div className="showProduct">
                        <Row>
                            {
                                    this.props.productData.loading 
                                    ?
                                    <div className="loadingSmall_search">
                                        <Digital color="#727981" size={32} speed={1} animating={true} />
                                    </div>
                                    :
                                    this.state.data.products.length>0?
                                    this._loopProducts(this.state.data.products):
                                    <div className="nodataMore_search">
                                        <h1>{keyword('no-product')?keyword('no-product'):"No products"}</h1>
                                    </div>
                            }
                        </Row>
                    </div>
                </Container>
            </div>
        )
    }
}
