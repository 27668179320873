import React, { Component } from 'react'
import {Modal,Row,Col} from 'react-bootstrap'
import {CloseOutlined} from '@material-ui/icons'
import '../Style/Component/dailog_addTocart.css'
import {NOTIMAGE} from '../Assets/images'
import {PROUDCT_URL_IMAGE,PRODUCT_MORE_URL_IMAGE,PRODUCT_THUMBNAIL_MORE_URL_IMAGE} from '../modules/app/config'
import {ErrorOutline} from '@material-ui/icons'
import {setColor} from '../utils/colors'
import { keyword } from '../utils/keywords';
import amount from '../utils/fomartAmount'
export default class Dailog_addTocart extends Component {
    constructor(props){
        super(props);
        this.state={
            getData:{
                total:null,
                countSKU:null,
                count:1,
                showingImage:'',
                oldSKU:1,
                skuIndexCheck:[]
            },
            ui:{
                wrongError:false,
                writeError:''
            }
        }
    }
    componentDidMount(){
      
    }

    componentWillReceiveProps(nextProps){
        const{dataSKU,productData,skuPrice,ID,countSKU}=this.props;
        if(nextProps.dataSKU && nextProps.dataSKU!=dataSKU){
           this.setState({
                getData:{
                    ...this.state.getData
                    ,skuIndexCheck:[],
                    oldSKU:ID,
                    showingImage:'',
                    total:null,
                }
            }) 
        }
        if(nextProps.countSKU && nextProps.countSKU!=countSKU){
            this.setState({
                getData:{
                    ...this.state.getData,countSKU:nextProps.countSKU
                }
            })
        }
    }
    _handleGetData(key,value){
        this.setState({
            getData:{...this.state.getData,[key]:value}
        })
    }
    _handleAddToCart(){
        const{ID}=this.props;
        if(this.state.getData.skuIndexCheck.length>0){
            let aliasSKU="";
            for (var i = 0; i < this.state.getData.skuIndexCheck.length; i++) {
                aliasSKU += this.state.getData.skuIndexCheck[i].value + '-';
            }

            const qty=1;
            const dataCart={
                "dataCart":[
                    {
                        ID:ID,
                        qty,
                        skuAlias:aliasSKU.substr(0, aliasSKU.length-1)
                    }
                ]
            }
            
            const value = this.state.getData.skuIndexCheck.filter((item)=>item.value=="");
            
            if(value.length<=0){
               this.props.addCart(dataCart);
            }
            else{
                this.setState({
                    ui:{...this.state.ui,
                        wrongError:true,
                        writeError:keyword('please-select-a-product-type')?keyword('please-select-a-product-type'):"Please select a product type"
                    }
                })
            }
        }
    }
    _handleDiscout(count,ArrayDIS){
        if (ArrayDIS.length>0 && count) {
          if (count < ArrayDIS[0]['amount']) {
              this.setState({
                calculateTotol:count*this.state.getData.total,
                allTotal:0
              })
            } else {
            if (ArrayDIS.length === 1) {
                let dataA=count * this.state.getData.total * ArrayDIS[0]['discount']
                dataA=dataA/100;
                this.setState({
                    allTotal:count * this.state.getData.total,
                    calculateTotol:count * this.state.getData.total-dataA
                })
            } else {
              for (let i = 0; i < ArrayDIS.length; i++) {
                if (count >= ArrayDIS[i]['amount']) {
                    let dataA=count * this.state.getData.total * ArrayDIS[i]['discount']
                    dataA=dataA/100
                    this.setState({
                        allTotal:count * this.state.getData.total,
                        calculateTotol:count* this.state.getData.total-dataA
                    })
                }
              }
            }
          }
        }else{
            this.setState({
                calculateTotol:count*this.state.getData.total,
                allTotal:0
            })
        }
      }
    _calculatePrice=()=>{
        const{ID}=this.props;
        if(this.state.getData.skuIndexCheck.length>0){
            let aliasSKU=[]
            for(var i=0;i<this.state.getData.skuIndexCheck.length;i++){
                aliasSKU+=this.state.getData.skuIndexCheck[i].value+ '-';
            }
            
            const dataPost={
                ID:ID,
                aliasSKU:aliasSKU.substr(0,aliasSKU.length-1)
            }
            this.props.getProductPriceSKU(dataPost)
        }
    }
    _setFocusColor = (id, alias,dataSKU) => {
        let skuIndexCheck = this.state.getData.skuIndexCheck
        let objIndex = skuIndexCheck.findIndex((obj => obj.id === id));
        skuIndexCheck[objIndex].value = alias;
        this._handleGetData('skuIndexCheck',skuIndexCheck);
        if(dataSKU['productImage']){
            this.setState({
                getData:{...this.state.getData,showingImage:PROUDCT_URL_IMAGE+dataSKU['productImage']}
            })
        }else if(dataSKU['productImageImage']){
            this.setState({
                getData:{...this.state.getData,showingImage:PRODUCT_MORE_URL_IMAGE+dataSKU['productImageImage']}
            })
        }
    }
    
    render() {
        var bgP =setColor('primaryBackground');
        var colP=setColor('primaryColor')
        const{show,onHide,total,dataSKU,data,countSKU}=this.props;
        let skuIndexCheck = [];
        let SKU= dataSKU.map((item,i)=>{
            skuIndexCheck.push({id:item['idToSort'],value:''});
            let result = item['dataList'].map((subData,j)=>{
                return(
                    <button key={j} 
                        className={ this.state.getData.skuIndexCheck.length && this.state.getData.skuIndexCheck[i].value && this.state.getData.skuIndexCheck[i].value=== subData['alias'] ?"btnLoopSKUClicked_addtocart":"btnLoopSKU_addtocart"}
                        style={this.state.getData.skuIndexCheck.length && this.state.getData.skuIndexCheck[i].value && this.state.getData.skuIndexCheck[i].value=== subData['alias']? {backgroundColor:bgP,color:colP}:null}
                        onClick ={()=>{
                            this._setFocusColor(item['idToSort'],subData['alias'],subData);this._calculatePrice();
                            }}>
                            {subData['productImageImage']?
                            <div className="oneSKU_dailog_addTocart">
                                <img src={PRODUCT_THUMBNAIL_MORE_URL_IMAGE+subData['productImageImage']} id="image_SKU_PRODUCT"/>
                                <p>{subData['name']}</p>
                            </div>
                            :
                            subData['productImage']?
                            <div className="oneSKU_dailog_addTocart">
                                <img src={PROUDCT_URL_IMAGE+subData['productImage']} id="image_SKU_PRODUCT"/>
                                <p>{subData['name']}</p>
                            </div>
                            :
                            <div className="oneSKU_dailog_addTocart">
                                <p>{subData['name']}</p>
                            </div>
                            }
                       
                    </button>
                )
            })
            return(
                <div key={i} className="control_show_sku_dailog_addTocart">
                    <div>
                        {item['mainSpecificationName']}:
                         {this.state.getData.skuIndexCheck.length>0? 
                         <h5>
                            {this.state.getData.skuIndexCheck[i].value}
                         </h5>
                         :null}
                    </div>
                    <div>
                        {result}
                    </div>
                </div>
            )
        })
        if (dataSKU.length> this.state.getData.skuIndexCheck.length) {
            this.setState({
                getData:{...this.state.getData,skuIndexCheck:skuIndexCheck}
            })
        }
        

       
        return (
            <div className="dailog_addTocart">
                <Modal 
                onHide={this.props.onHide}
                show={show}
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="model_custome_width"
                centered> 
                <Modal.Header>
                    <h3>{keyword('please-select-a-product-type')?keyword('please-select-a-product-type'):"Please select a product type"}</h3>
                    <CloseOutlined id="id_remove" onClick={this.props.onHide}/>
                </Modal.Header>
                <Modal.Body className="modal_add_to_dai_cart">
                <Row>
                    <Col lg={4} md={4} sm={6} xs={12}>
                        {
                            data && this.state.getData.showingImage===''?
                            <img src={
                                PROUDCT_URL_IMAGE+data['image']
                            } id="imageSHOWPRODUCT"/>
                            :
                            this.state.getData.showingImage!=''?
                            <img src={
                                this.state.getData.showingImage
                            } id="imageSHOWPRODUCT"/>
                            :
                            <div>
                                 <img src={NOTIMAGE} id="imageSHOWPRODUCT"/>
                            </div>
                        }
                    </Col>
                    <Col lg={8} md={8} sm={6} xs={12} className="dialog_add_data_one_cart_border">
                        <div className="detail_block_price_dailog_addCart">
                            <h2>
                                <span>$ {data['minPrice']>0?amount(data['minPrice']):0}</span>
                            </h2>
                            <h1>
                                <span>$ {data['marketPrice']>0?amount(data['marketPrice']):0}</span>
                            </h1>
                        </div>
                        <h1 className="name_show_prod_dailog_addCart">{data['name']}</h1>
                        <div className="totalSKUPrice_dailog_add_tocart">
                        <h6>{keyword('amount-product')?keyword('amount-product'):"Quantity of products"}: <span>{countSKU?countSKU:keyword('word-wait-for-selecting')?keyword('word-wait-for-selecting'):"(Waiting for selecting!)"}</span></h6>
                            {
                               total?<h1>{keyword('amount-price')?keyword('amount-price'):"Total price"}: <span> $ {(total * this.state.getData.count)?amount((total * this.state.getData.count)):"0"} </span></h1>:<h1>{keyword('amount-price')?keyword('amount-price'):"Total price"}: <span>_ _ _ _</span></h1>   
                            }
                           
                        </div>
                         {
                            SKU
                         }
                         <div className="count_detail_dailog_add_to_cart">  
                            <Row>
                                {
                                    this.state.getData.count<=1?<button disabled style={{height:44}}></button>:<button 
                                    onClick={()=>{
                                         this._handleGetData('count',this.state.getData.count-1);
                                    }
                                    }
                                    >-</button>
                                }
                                    <input id="Countinput_detail" value={this.state.getData.count} readOnly="readOnly"></input>
                                {
                                    this.state.getData.countSKU>this.state.getData.count? 
                                    <button  
                                    onClick={()=>{
                                        this._handleGetData('count',this.state.getData.count+1);
                                    }   
                                    }
                                    >+</button>:<button disabled style={{height:44}}></button>
                                }
                                
                            </Row>
                        </div>
                    </Col>
                    <div className="cotroler_btn_2_in_addTocart_dailog">
                        <button id="btnFinished_dai_add_cart" style={{backgroundColor:bgP,color:colP}} onClick={()=>{this._handleAddToCart()}}>
                            {keyword('ok')?keyword('ok'):"OK"}
                        </button>
                    </div>
                </Row>
                </Modal.Body>
            </Modal>
            <Modal
                onHide={()=>{this.setState({ui:{...this.state.ui,wrongError:false}})}}
                animation={false}
                show={this.state.ui.wrongError} 
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                className="mode_wrongPassword_confirmPin"
                >
                <Modal.Body style={{textAlign:'center'}}>
                <ErrorOutline id="anig_icon_error"/>
                <br/>
                <h1>{this.state.ui.writeError} !</h1>
                </Modal.Body>
                <Modal.Footer style={{justifyContent:'center'}}>
                    <button id="btnHide" onClick={()=>{this.setState({ui:{...this.state.ui,wrongError:false}})}}>{keyword('word-wait-for-review')?keyword('word-wait-for-review'):"Review"}</button>
                </Modal.Footer>
            </Modal>
            </div>
        )
    }
}
