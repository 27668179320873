import React, { Component } from 'react'
import Navigation from '../containers/navigationContainer'
import {Tab,Row,Col,Nav,Tooltip,OverlayTrigger,Modal,Container} from 'react-bootstrap'
import {Store,Search,FavoriteBorder,Loyalty,Clear,FiberNew} from '@material-ui/icons'
import '../Style/detailShop.css'
import {STORE_URL_IMAGE} from '../modules/app/config'
import OneProduct from '../containers/compornentOneProduct'
import Dailog_addTocart from '../containers/compornentDailogAddTocart'
import {Digital} from 'react-activity';
import { Link } from 'react-router-dom';
import {GIF} from '../Assets/images'
import {setColor} from '../utils/colors';
import { keyword } from '../utils/keywords'
export default class DetailShop extends Component {
    constructor(props){
        super(props);
        this.state={
            ui:{
                showDialogAddTocart:false,
                showDialogAddedTocart:false,
                title:'ទំនិញទាំងអស់',
            },
            data:{
                storeProduct:[],
                storeProductPage:1,
                
                all_prod_no_more:false,

                dataStore:{},
                topSell:false,
                newSell:false,
                indxUserLike:0,

                dataDialog:{},
                prodsSKU:[],
                countSKU:'',
                total:'',
                status:'all',
                userFullName:''
            },
            subtriNing:false
        }
    }
    componentDidMount(){
        const a = this.props.match.params.storeAlias;
        if(a){
            let payload={
                "storeAlias":a,
                "page":this.state.data.storeProductPage,
                "boolean1":this.state.data.topSell,
                "boolean2":this.state.data.newSell
            }
            this.props.get_products_in_store(payload);
           
        }
        try{
            var dataUser=JSON.parse(localStorage.getItem('persist:amatakWebsite'));
            var JsonDATA=JSON.parse(dataUser['user'])['login']['data'];
            this.setState({
                data:{...this.state.data,
                    userFullName:JsonDATA['fullName']
                }
            });
        }
        catch(e){}
    }
    componentWillReceiveProps(nextProps){
        try{
            const {productData,error_productsInStore,user}=this.props;
            if(nextProps.productData.productsInStore && nextProps.productData.productsInStore!=productData.productsInStore){
                if(nextProps.productData.productsInStore.message==="success"){
                    var data=nextProps.productData.productsInStore.results;
                    // console.log(data)
                    if(data.length>0){
                        if(this.state.data.storeProduct.length===0){
                            // console.log('data',data)
                            this.setState({
                                data:{...this.state.data,
                                storeProduct:data,
                                dataStore:data[0],
                               }
                            });
                        }else{
                            var dataOld=this.state.data.storeProduct;
                            data.map((dat)=>{
                                dataOld.push(dat);
                            });
                            this.setState({
                                data:{...this.state.data,storeProduct:dataOld}
                            });
                        }
                    }else{
                        this.setState({
                            data:{...this.state.data,all_prod_no_more:true}
                        })
                    }
                    var str=Number(data[0]['storeUserName'].substr(4,data[0]['storeUserName'].length-4))
                    this.setState({
                        subtriNing:str
                    })
                }
            }
            if(nextProps.productData.user_likied && nextProps.productData.user_likied!=productData.user_likied){
               
                if(nextProps.productData.user_likied.message==='success'){
                    let newData = this.state.data.storeProduct;
                    let row=newData[this.state.data.indxUserLike];
                    row = { ...row,liked: row.liked + 1, youLiked: 1 };
                    newData[this.state.data.indxUserLike]=row;
                    this.setState({ data:{...this.state.data,storeProduct:newData} })
                    const data_send_notification = {
                        user: row.storeUserName,
                        type: "seller",
                        data: { type: 'user_like' },
                        message: "Product " + row.name + " gets a favorite from customer "  + this.state.data.userFullName
                    }
                    this.props.setNotification(data_send_notification)
                }
            }
            if(nextProps.productData.product_sku && nextProps.productData.product_sku!=productData.product_sku ){
                if(nextProps.productData.product_sku.message==="success"){
                    this.setState({
                        data:{
                            ...this.state.data,prodsSKU:nextProps.productData.product_sku.results
                        }
                    })
                }
            }
            if(nextProps.productData.priceSKU && nextProps.productData.priceSKU!=productData.priceSKU){
                if(nextProps.productData.priceSKU['message']==='success'){
                    var data=nextProps.productData.priceSKU['results'];
                    this.setState({
                        data:{...this.state.data,countSKU:data['stock'],total:data['price']}
                    })
                }
            }
            if(nextProps.user.add_cart && nextProps.user.add_cart!=user.add_cart){
                if(nextProps.user.add_cart.message==='success'){
                    this.setState({
                        ui:{
                            ...this.state.ui,
                            showDialogAddTocart:false,
                            showDialogAddedTocart:true,
                        }
                    })
                }
            }
        }
        catch(e){
            console.log('->error DetailShop')
        }
    }
    _handleUi(key,value){
        this.setState({
            ui:{...this.state.ui,[key]:value}
        })
    }
    _handleData(key,value){
        this.setState({
            data:{...this.state.data,[key]:value}
        })
    }
    _loopProducts(Data){
        var result=[];
        if(Data){
            Data.map((oneProd,index)=>{
                result.push(
                    <OneProduct
                        showBtnCart={true}
                        fun_clickLike={()=>{this._userOnClickLiked(index,oneProd)}}
                        key={index} dataProduct={oneProd} prodsSKU={this.state.data.prodsSKU} 
                    functionbtn={()=>{
                        this._handleData('dataDialog',oneProd);
                        this._handleUi('showDialogAddTocart',true);
                        this.props.productSKU(oneProd['ID']);
                    }} />
                )
            })
        }
        return(result)
    }
    _handle_state_ui(key,value){
        this.setState({
            ui:{...this.state.ui,[key]:value}
        })
    }
    _hadle_all_product_dataMore(){
        const a = this.props.match.params.storeAlias;
        if(a){
            if(this.state.data.status==='all'){
                let payload={
                    "storeAlias":a,
                    "page":this.state.data.storeProductPage+1,
                    "boolean1":false,
                    "boolean2":false
                }
                this.props.get_products_in_store(payload);
                this._handleData('storeProductPage',this.state.data.storeProductPage+1);
            }
            if(this.state.data.status==='new'){
                let payload={
                    "storeAlias":a,
                    "page":this.state.data.storeProductPage+1,
                    "boolean1":true,
                    "boolean2":false
                }
                this.props.get_products_in_store(payload);
                this._handleData('storeProductPage',this.state.data.storeProductPage+1);
            }
            if(this.state.data.status==='topSell'){
                let payload={
                    "storeAlias":a,
                    "page":this.state.data.storeProductPage+1,
                    "boolean1":false,
                    "boolean2":true
                }
                this.props.get_products_in_store(payload);
                this._handleData('storeProductPage',this.state.data.storeProductPage+1);
            }
            this.setState({
                data:{...this.state.data,storeProductPage:this.state.data.storeProductPage+1}
            })
        }
       
       
    }
    _userOnClickLiked(index,product){
        var Data={
            "productAlias":product['alias'],
            "shareTo":"",
            "type": "like"  
        }
        this.props.userLikeShare(Data);
        this.setState({data:{...this.state.data,indxUserLike:index}})
    }

    change_type_group(type){
    const a = this.props.match.params.storeAlias;
    this.setState({
        data:{
            ...this.state.data,
            storeProduct:[],
            storeProductPage:1,
            all_prod_no_more:false}
    })
        if(type==='all'){
            let payload={
                "storeAlias":a,
                "page":1,
                "boolean1":false,
                "boolean2":false
            }
            this.props.get_products_in_store(payload);
            this._handleUi('title',"ទំនិញទាំងអស់")
        }
        if(type==='new'){
            let payload={
                "storeAlias":a,
                "page":1,
                "boolean1":true,
                "boolean2":false
            }
            this._handleUi('title',"ទំនិញមកថ្មី")
            this.props.get_products_in_store(payload);
        }
        if(type==='topSell'){
            let payload={
                "storeAlias":a,
                "page":1,
                "boolean1":false,
                "boolean2":true
            }
            this._handleUi('title',"ទំនិញលក់ដាច់")
            this.props.get_products_in_store(payload);
        }
    }
    render() {
        let bgP=setColor('primaryBackground');
        let colP=setColor('primaryColor');
        
        return (
            <div className="detailShop">
                <Navigation/>
                <div>
                    <Tab.Container defaultActiveKey="all_products">
                        <Container>
                        <Row className="lili">
                            <Col lg={2} md={3} sm={3} xs={4}>
                            <img src={STORE_URL_IMAGE + this.state.data.dataStore['storeImage']} id="image_profile_Shop"></img> 
                            </Col>
                            <Col lg={10} md={9} sm={9} xs={8} >
                                <Row className="namme_shop" style={{margin:0}}>
                                    <Col md={8}  style={{margin:0}}>
                                        <h1>{this.state.data.dataStore['storeName']}</h1>
                                        <h2>{keyword('customers')?keyword('customers'):"Customers"}<span>{this.state.data.dataStore['followers']}</span></h2>
                                    </Col>
                                    <Col md={4} style={{textAlign:'right',margin:0}}>
                                        {/* <Link to={{
                                            pathname:"/message",
                                            state: {
                                                IDshop:this.state.subtriNing,
                                                ImageShop:this.state.data.dataStore['storeImage'],
                                                NameShop:this.state.data.dataStore['storeName']
                                            }
                                            }}>
                                            <button id="btnNewChat">
                                                ទំនាក់ទំនងហាង<i class="fa fa-chain"></i>
                                            </button>
                                        </Link> */}
                                    </Col>
                                </Row>
                                <Row className="three_button_profile_shop_big">
                                    <Col md={4} sm={4} xs={4}>
                                        <Nav.Item style={this.state.ui.title==='ទំនិញទាំងអស់'?{backgroundColor:bgP,color:colP}:null}  className={this.state.ui.title==='ទំនិញទាំងអស់'?"nav_item_profile_shop_clicked":"nav_item_profile_shop"}  onClick={()=>{this.change_type_group('all')}}>
                                            <Nav.Link eventKey="all_products" >
                                                    <h1>{keyword('all-product')?keyword('all-product'):"All product"}</h1>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Col>
                                    <Col md={4} sm={4} xs={4} >
                                        <Nav.Item style={this.state.ui.title==='ទំនិញមកថ្មី'?{backgroundColor:bgP,color:colP}:null} className={this.state.ui.title==='ទំនិញមកថ្មី'?"nav_item_profile_shop_clicked":"nav_item_profile_shop"} onClick={()=>{this.change_type_group('new')}}>
                                            <Nav.Link eventKey="new_products" >
                                             <h1>
                                                {keyword('new-product')?keyword('new-product'):"New product"}
                                            </h1>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Col>
                                    <Col md={4} sm={4} xs={4}>
                                        <Nav.Item style={this.state.ui.title==='ទំនិញលក់ដាច់'?{backgroundColor:bgP,color:colP}:null} className={this.state.ui.title==='ទំនិញលក់ដាច់'?"nav_item_profile_shop_clicked":"nav_item_profile_shop"} onClick={()=>{this.change_type_group('topSell')}}>
                                            <Nav.Link eventKey="TopSell_products" >
                                                <h1> {keyword('best-product')?keyword('best-product'):"Best product"}</h1>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Row className="three_button_profile_shop_small">
                                <Col md={4} sm={4} xs={4} style={{padding:5}}>
                                    <Nav.Item  style={this.state.ui.title==='ទំនិញទាំងអស់'?{backgroundColor:bgP,color:colP}:null} className={this.state.ui.title==='ទំនិញទាំងអស់'?"nav_item_profile_shop_clicked":"nav_item_profile_shop"}  onClick={()=>{this.change_type_group('all')}}>
                                        <Nav.Link eventKey="all_products" >
                                                <h1>{keyword('all-product')?keyword('all-product'):"All product"}</h1>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Col> 
                                <Col md={4} sm={4} xs={4} style={{padding:5}}>
                                    <Nav.Item  style={this.state.ui.title==='ទំនិញមកថ្មី'?{backgroundColor:bgP,color:colP}:null} className={this.state.ui.title==='ទំនិញមកថ្មី'?"nav_item_profile_shop_clicked":"nav_item_profile_shop"} onClick={()=>{this.change_type_group('new')}}>
                                        <Nav.Link eventKey="new_products" >
                                            <h1>
                                            {keyword('new-product')?keyword('new-product'):"New product"}
                                            </h1>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Col>
                                <Col md={4} sm={4} xs={4} style={{padding:5}}>
                                    <Nav.Item  style={this.state.ui.title==='ទំនិញលក់ដាច់'?{backgroundColor:bgP,color:colP}:null} className={this.state.ui.title==='ទំនិញលក់ដាច់'?"nav_item_profile_shop_clicked":"nav_item_profile_shop"} onClick={()=>{this.change_type_group('topSell')}}>
                                        <Nav.Link eventKey="TopSell_products" >
                                            <h1>{keyword('best-product')?keyword('best-product'):"Best product"}</h1>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Col>
                            </Row>
                        
                        </Row>
                        </Container>
                             <Container>
                                <Tab.Content>
                                        <Tab.Pane eventKey="all_products">
                                            <Row>
                                                {
                                                    this._loopProducts(this.state.data.storeProduct)
                                                }
                                                 {
                                                      this.props.productData.loading ?
                                                      <div className="loadingSmall_detailShop">
                                                          <Digital color={bgP} size={20} speed={1} animating={true} />
                                                      </div>
                                                      :
                                                    (this.state.data.storeProduct.length>0 && this.state.data.all_prod_no_more===false)?
                                                    <div className="get_shop_more">
                                                        <button style={{backgroundColor:bgP,color:colP}} id="btn_control_shops_more_propduct_shop" onClick={()=>{this._hadle_all_product_dataMore()}}>{keyword('see-more-products')?keyword('see-more-products'):"See more"}</button>
                                                    </div>
                                                    :
                                                    this.state.data.all_prod_no_more===false?
                                                    null
                                                    :
                                                    <div className="nodataMore">
                                                        <h1>{keyword('no-product')?keyword('no-product'):"No product"}</h1>
                                                    </div>
                                                }
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="new_products">
                                            <Row>
                                                {
                                                    this._loopProducts(this.state.data.storeProduct)
                                                }
                                                {
                                                      this.props.productData.loading ?
                                                      <div className="loadingSmall_detailShop">
                                                          <Digital color={bgP} size={20} speed={1} animating={true} />
                                                      </div>
                                                      :
                                                    (this.state.data.storeProduct.length>0 && this.state.data.all_prod_no_more===false)?
                                                    <div className="get_shop_more">
                                                        <button id="btn_control_shops_more_propduct_shop" onClick={()=>{this._hadle_all_product_dataMore()}}>{keyword('see-more-products')?keyword('see-more-products'):"See more"}</button>
                                                    </div>
                                                    :
                                                    this.state.data.all_prod_no_more===false?
                                                    null
                                                    :
                                                    <div className="nodataMore">
                                                        <h1>{keyword('no-product')?keyword('no-product'):"No product"}</h1>
                                                    </div>
                                                }
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="TopSell_products">
                                            <Row>
                                                {
                                                    this._loopProducts(this.state.data.storeProduct)
                                                }
                                                {
                                                     this.props.productData.loading ?
                                                     <div className="loadingSmall_detailShop">
                                                         <Digital color={bgP} size={20} speed={1} animating={true} />
                                                     </div>
                                                     :
                                                    (this.state.data.storeProduct.length>0 && this.state.data.all_prod_no_more===false)?
                                                    <div className="get_shop_more">
                                                        <button id="btn_control_shops_more_propduct_shop" onClick={()=>{this._hadle_all_product_dataMore()}}>{keyword('see-more-products')?keyword('see-more-products'):"See more"}</button>
                                                    </div>
                                                    :
                                                    this.state.data.all_prod_no_more===false?
                                                    null
                                                    :
                                                    <div className="nodataMore">
                                                        <h1>{keyword('no-product')?keyword('no-product'):"No product"}</h1>
                                                    </div>
                                                }
                                            </Row>
                                        </Tab.Pane>
                                </Tab.Content>
                            </Container>
                    </Tab.Container>
                </div>
                <Dailog_addTocart 
                ID={this.state.data.dataDialog['ID']}
                dataSKU={this.state.data.prodsSKU}
                data={this.state.data.dataDialog}
                total={this.state.data.total}
                countSKU={this.state.data.countSKU}
                show={this.state.ui.showDialogAddTocart}
                onHide={()=>{this.setState({ui:{...this.state.ui,showDialogAddTocart:false}})}}
                />
                <Modal
                    show={this.state.ui.showDialogAddedTocart} 
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="modal_added_to_cart"
                    >
                    <Modal.Body style={{textAlign:'center'}}>
                    <img src={GIF.done_add_tocart} id="image_gif_done_added_tocart"/>
                    <br/>
                    <h1>{keyword('product-successfully-carted')?keyword('product-successfully-carted'):"Product add to cart successfully"}</h1>
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent:'center'}}>
                        <button id="btn_contenue_shopping_home" onClick={()=>{this.setState({ui:{...this.state.ui,showDialogAddedTocart:false}})}}>{keyword('word-for-continue-buying')?keyword('word-for-continue-buying'):"Continue Buying"}</button>
                    
                        <button id="btn_contenue_shopping_home">
                        <Link to="/addtocart">
                            <span>{keyword('go-to-cart')?keyword('go-to-cart'):"Go to cart"}</span>
                        </Link> 
                        </button>
                    
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
