import { connect } from "react-redux";
import DetailComponent from "../Screen/DetailProduct"

import {
  productSKU,
  getProductDetail,
  getProductPriceSKU,
  getPorductRelated,
  userLikeShare,
  getDiscoutCart,
  getProductDetailDiscription
} 
from '../modules/product/reducer'
import {
  getCartList,
  addCart,
  setNotification
} from '../modules/user/reducer'
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = {
  getDiscoutCart,
  productSKU,
  userLikeShare,
  getProductDetail,
  getProductPriceSKU,
  addCart,
  getPorductRelated,
  setNotification,
  getCartList,
  getProductDetailDiscription
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailComponent);
