import { connect } from "react-redux";
import ComfrimPin from "../Component/comfrimPin"
import {
  confirm_pin
  } from '../modules/user/reducer'
const mapStateToProps = state => ({
  ...state,
  err_CONFIRM_PIN:state.user.err_CONFIRM_PIN
 
});

const mapDispatchToProps = {
  confirm_pin
 }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComfrimPin);