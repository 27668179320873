import React, { Component } from 'react';
import '../Style/Component/buttonBuyFooter.css'
import {Row,Container,Col} from 'react-bootstrap'
import {setColor} from '../utils/colors';
import {keyword} from '../utils/keywords'
export default class ButtonBuyFooter extends Component {     
  render() {
    let bgP=setColor('primaryBackground');
    let colP=setColor('primaryColor');
    const {functionBuy,functionAdd} =this.props
    return (
        <div>
                <div  id="controlButtonBuyFooter">
                <Row style={{margin:'auto',padding:'auto',borderTop:'1px solid rgb(228, 228, 228)'}}>
                    <button style={{backgroundColor:bgP,color:colP}} className="btnTwo_ButtonBuyFooter" onClick={()=>{functionAdd()}}>{keyword('add-to-cart')?keyword('add-to-cart'):"Add to cart"}</button>
                    <button style={{backgroundColor:bgP,color:colP}} className="btnTwo_ButtonBuyFooter" onClick={()=>{functionBuy()}}>{keyword('buy-now')?keyword('buy-now'):"Buy now"}</button>
                </Row>
            
                </div>
            <div id="controlButtonBuyFooterNotFight"></div>
        </div>
     
    );
  }
}
