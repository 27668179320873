import React, { Component } from 'react';
import Navigations from '../containers/navigationContainer'

import {LOGO_IMAGE_URL} from '../modules/app/config'
import { setLayout} from '../utils/colors'
import "../Style/Component/privacy.css"
export default class HomepageNew extends Component {
    
  render() {
    let imageLogo=setLayout('logo');
    return (
        <div>  
            <Navigations/>
            <div className="contant_privatcy_screen">
                <img src={LOGO_IMAGE_URL+imageLogo}  id="logo_inPrivatcy"/>
            </div>
            <div className="container">
            <div className="contant_privatcy_screen">
                <h1>គោលការណ៍ឯកជនភាព</h1>
                    <p>
                        <span className="show_title_privatcy">គោលការណ៍ឯកជនភាព</span>
                        <br/>
                        អមតៈទទួលស្គាល់សារៈសំខាន់នៃភាពឯកជនក៏ដូចជាសារៈសំខាន់នៃការរក្សាការសម្ងាត់នៃព័ត៌មានផ្ទាល់ខ្លួន។  គោលការណ៍ឯកជនភាពនេះអនុវត្តចំពោះផលិតផលនិងសេវាកម្មទាំងអស់ដែលផ្តល់ដោយពួកយើងហើយយើងអាចប្រមូលប្រើប្រាស់និងផ្សព្វផ្សាយព័ត៌មានទាក់ទងនឹងអ្នកប្រើប្រាស់។
                        អ្នកអាចប្រើសេវាកម្មនិងផលិតផលរបស់យើងតាមរយៈឧបករណ៍ចល័តតាមរយៈកម្មវិធីទូរស័ព្ទចល័តឬគេហទំព័រដែលធ្វើឱ្យប្រសើរលើទូរស័ព្ទ។  គោលការណ៍ឯកជនភាពនេះក៏អនុវត្តចំពោះការប្រើប្រាស់សេវាកម្មនិងផលិតផលរបស់យើងផងដែរ។  
                        ការប្រមូលព័ត៌មាន
                        ភាពជាឯកជនគឺសំខាន់សម្រាប់យើងហើយយើងបានចាត់វិធានការដើម្បីធានាថាយើងមិនប្រមូលព័ត៌មានពីអ្នកច្រើនជាងអ្វីដែលចាំបាច់សម្រាប់យើងដើម្បីផ្តល់ជូនអ្នកនូវសេវាកម្មរបស់យើងនិងការពារគណនីរបស់អ្នក:
                        <br/>•	ព័ត៌មានរួមមានឈ្មោះអ្នកប្រើប្រាស់អាស័យដ្ឋានលេខទូរស័ព្ទនិងអាស័យដ្ឋានអ៊ីម៉ែលនឹងត្រូវប្រមូលនៅពេលចុះឈ្មោះអ្នកប្រើប្រាស់។  ប្រសិនបើអ្នក​ចុះឈ្មោះតាមរយៈប្រព័ន្ធផ្សព្វផ្សាយសង្គមផ្សេងទៀតដូចជាហ្វេសប៊ុកឬ វីឆាត យើងអាចប្រមូលឈ្មោះគណនីនិងរូបថតប្រវត្តិរូបរបស់អ្នកនៅលើវេទិកាទាំងនោះ។
                        <br/>•  ប្រសិនបើអ្នកទាក់ទងសេវាកម្មអតិថិជនរបស់យើងយើងអាចកត់ត្រាការសន្ទនារបស់អ្នកជាមួយយើងហើយប្រមូលព័ត៌មានបន្ថែមដើម្បីផ្ទៀងផ្ទាត់អត្តសញ្ញាណរបស់អ្នក។
                        <br/>•	ទាក់ទងនឹងប្រតិបត្តិការនិងសេវាទូទាត់ប្រាក់ណាមួយឬសេវាកម្មនានាក្រោមគ្រោងការណ៍ការពារអ្នកទិញព័ត៌មានរួមមានមិនរាប់បញ្ចូលលេខគណនីធនាគារវិក័យប័ត្រនិងព័ត៌មានចែកចាយលេខប័ណ្ណ / ឥណពន្ធកាលបរិច្ឆេទផុតកំណត់និងលេខកូដសុវត្ថិភាពនិងព័ត៌មានតាមដាន  អាចត្រូវបានប្រមូលដើម្បីជួយសម្រួលដល់ការលក់និងការទិញក៏ដូចជាការទូទាត់ថ្លៃទិញផលិតផលឬសេវាកម្មដែលបានធ្វើប្រតិបត្តិការឬទទួលបានតាមរយៈពាក្យសុំ។
                        <br/>•	ពីមួយពេលទៅមួយពេលយើងប្រមូលព័ត៌មានអំពីអ្នកប្រើប្រាស់របស់យើងនិងអ្នកប្រើប្រាស់នាពេលអនាគតក្នុងកំឡុងពេលពិព័រណ៍ពាណិជ្ជកម្មព្រឹត្តិការណ៍ឧស្សាហកម្មនិងមុខងារផ្សេងទៀត។  ព័ត៌មានដែលយើងអាចប្រមូលបាននៅក្នុងព្រឹត្តិការណ៍ទាំងនេះរួមមានប៉ុន្តែមិនកំណត់ចំពោះឈ្មោះអ្នកប្រើប្រាស់អាស័យដ្ឋានលេខទូរស័ព្ទនិងអាស័យដ្ឋានអ៊ីមែលទេ។
                        ដើម្បីប្រើប្រាស់សេវាកម្មរបស់យើងនិងផលិតផលអ្នកប្រើត្រូវផ្តល់ប្រភេទទិន្នន័យជាក់លាក់ (ដែលអាចរាប់បញ្ចូលទិន្នន័យផ្ទាល់ខ្លួន) ដូចជាឈ្មោះអ្នកប្រើប្រាស់អាស័យដ្ឋានលេខទូរស័ព្ទនិងអាស័យដ្ឋានអ៊ីមែល។  ប្រសិនបើត្រូវការទិន្នន័យជាក់លាក់យើងនឹងជូនដំណឹងដល់អ្នកនៅពេលប្រមូល។  ក្នុងករណីដែលអ្នកប្រើប្រាស់មិនបានផ្តល់ទិន្នន័យគ្រប់គ្រាន់ដែលត្រូវបានសម្គាល់ថាចាំបាច់យើងប្រហែលជាមិនអាចបញ្ចប់ដំណើរការចុះឈ្មោះឬផ្តល់ផលិតផលឬសេវាកម្មជាក់លាក់បានទេ។  
                        ការប្រើប្រាស់ទិន្នន័យផ្ទាល់ខ្លួន
                        យើងប្រមូលនិងប្រើទិន្នន័យផ្ទាល់ខ្លួនរបស់អ្នកសម្រាប់គោលបំណងដូចខាងក្រោម
                        <br/>•	ផ្ទៀងផ្ទាត់អត្តសញ្ញាណរបស់អ្នក
                        <br/>•	ផ្ទៀងផ្ទាត់សិទ្ធិរបស់អ្នកក្នុងការចុះឈ្មោះជាអ្នកប្រើប្រាស់គេហទំព័រឬក្លាយជាអ្នកសរសេរប្លក់ឬឥទ្ធិពលនៃគេហទំព័រ
                        <br/>•	ដំណើរការការចុះឈ្មោះរបស់អ្នកជាអ្នកប្រើប្រាស់ដែលផ្តល់ឱ្យអ្នកនូវអត្តសញ្ញាណប័ណ្ណសម្រាប់ពាក្យសុំនិងថែរក្សានិងគ្រប់គ្រងការចុះឈ្មោះរបស់អ្នក
                        <br/>•	ផ្តល់ជូនអ្នកនូវសេវាកម្មអតិថិជននិងការឆ្លើយតបទៅនឹងបណ្តឹងតបឬការឆ្លើយតបសំណួររបស់អ្នក
                        <br/>•	វាយតម្លៃគណនេយ្យភាពនិងហានិភ័យប្រតិបត្តិការរបស់អ្នកប្រើប្រាស់រកឃើញនិងការពារការលួចបន្លំនិងឧប្បត្តិហេតុសន្តិសុខផ្សេងទៀត។
                        កំណត់ការអភិវឌ្ឍនិងធ្វើទីផ្សារផលិតផលនិងសេវាកម្មដែលយើងជឿជាក់ថាអ្នកនឹងផ្តល់តម្លៃរួមទាំងកម្មវិធីរុករកនិងឧបករណ៍នានាដោយអនុលោមតាមច្បាប់ដែលអាចអនុវត្តបាន។  ខូឃីស៍ឬបច្ចេកវិទ្យាស្រដៀងគ្នាផ្សេងទៀតអាចត្រូវបានប្រើដើម្បីផ្តល់ឱ្យអ្នកនូវការផ្សាយពាណិជ្ជកម្មផ្អែកលើសកម្មភាពរុករកនិងចំណាប់អារម្មណ៍របស់អ្នក។  នៅពេលដែលយើងត្រូវបានទាមទារដោយច្បាប់ដែលអាចអនុវត្តបានយើងនឹងស្វែងរកការព្រមព្រៀងរបស់អ្នកមុនពេលបញ្ជូនគោលបំណងបង្កើតទំនាក់ទំនងសម្រាប់អ្នក។
                        ការបង្ហាញកាលបរិច្ឆេទផ្ទាល់ខ្លួន
                        យើងអាចបង្ហាញនិងផ្ទេរទិន្នន័យផ្ទាល់ខ្លួនរបស់អ្នកទៅអ្នកផ្តល់សេវាកម្មដែលភ្ជាប់ដោយយើងដើម្បីជួយផ្តល់សេវាកម្មដល់អ្នក។  ឧទាហរណ៍នៃអ្នកផ្តល់សេវាទាំងនេះរួមមាន៖
                        <br/>•	ដៃគូអាជីវកម្មរបស់យើង (ឧទាហរណ៍ដើម្បីឱ្យពួកគេផ្តល់ជូនអ្នកនូវការបញ្ចុះតម្លៃនៃការផ្តល់ជូនដែលអាចចាប់អារម្មណ៍អ្នក)
                        <br/>•	វេទិកាទីផ្សារដូចជាហ្គូហ្គោលធ្វីតធ័រហ្វេសប៊ុក អិនស្តាគ្រាម និងអ្នកផ្គត់ផ្គង់សេវាកម្មវិភាគទាក់ទងនឹងអាកប្បកិរិយារបស់អ្នកប្រើប្រាស់ដើម្បីកែសំរួលមាតិកាដែលអ្នកឃើញពេលប្រើកម្មវិធីរបស់យើងវេទិការទីផ្សារទាំងនេះអាចបញ្ចូលព័ត៌មានដែលពួកគេប្រមូលជាមួយទិន្នន័យរបស់ពួកគេ។  វេទិកាដើម្បីធ្វើការផ្សព្វផ្សាយគោលដៅ។  សកម្មភាពនៃវេទិកាទីផ្សាររបស់ភាគីទីបីទាំងនេះត្រូវបានគ្រប់គ្រងដោយគោលការណ៍ឯកជនភាពផ្ទាល់ខ្លួនរបស់ពួកគេមិនមែនគោលនយោបាយភាពឯកជននេះទេ។
                        <br/>•	អ្នកផ្តល់សេវាបង់ប្រាក់ដើម្បីជួយទូទាត់ប្រាក់សម្រាប់ប្រតិបត្តិការនានា។
                        <br/>•	ដៃគូភ័ស្តុភារសម្រាប់ការផ្តល់សេវាដឹកជញ្ជូន;
                        <br/>•	អ្នកផ្តល់សេវាកម្មធានាផ្តល់សេវាកម្មធានា។
                        <br/>•	អ្នកផ្តល់សេវាអតិថិជនដើម្បីផ្តល់សេវាកម្មបន្ទាប់ពីលក់  
                        <br/>•	អ្នកផ្តល់សេវាត្រួតពិនិត្យហានិភ័យដើម្បីវាយតម្លៃសុវត្ថិភាពគណនីនិងហានិភ័យប្រតិបត្តិការ។
                        អ្នកផ្តល់សេវាទាំងនេះត្រូវតែគោរពតាមតម្រូវការទិន្នន័យនិងសុវត្ថិភាពព័ត៌មានរបស់យើងហើយត្រូវបានអនុញ្ញាតឱ្យប្រើប្រាស់ទិន្នន័យផ្ទាល់ខ្លួនរបស់អ្នកទាក់ទងនឹងគោលបំណងដែលបានបញ្ជាក់ខាងលើហើយមិនមែនសម្រាប់គោលបំណងផ្ទាល់ខ្លួនរបស់ពួកគេទេ។
                        នៅពេលចាំបាច់និងដើម្បីអនុវត្តតាមច្បាប់ដែលអាចអនុវត្តបានយើងក៏អាចបង្ហាញនិងផ្ទេរទិន្នន័យផ្ទាល់ខ្លួនរបស់អ្នកទៅទីប្រឹក្សាអាជីពភ្នាក់ងារអនុវត្តច្បាប់អ្នកធានារ៉ាប់រងរដ្ឋាភិបាលនិងបទប្បញ្ញត្តិនិងអង្គការដទៃទៀតសម្រាប់គោលបំណងដែលបានបញ្ជាក់ខាងលើឬតាមតម្រូវការឬអនុញ្ញាតដោយច្បាប់ដែលអាចអនុវត្តបាន។
                        យើងរក្សាទុកទិន្នន័យផ្ទាល់ខ្លួនរបស់អ្នកដរាបណាត្រូវការដើម្បីផ្តល់សេវាកម្មឬផលិតផលដល់អ្នកឬតាមតម្រូវការឬអនុញ្ញាតដោយច្បាប់ដែលអាចអនុវត្តបានដូចជាច្បាប់ពន្ធនិងគណនេយ្យ។
                        យើងអាចផ្តល់ទិន្នន័យសរុបឬអនាមិកទៅឱ្យភាគីទីបីប៉ុន្តែនៅពេលដែលយើងធ្វើដូច្នេះព័ត៌មានដែលយើងចែករំលែកគឺស្ថិតក្នុងទ្រង់ទ្រាយដែលមិនបានកំណត់អត្តសញ្ញាណដែលមិនបញ្ជាក់អត្តសញ្ញាណផ្ទាល់ខ្លួន។  សិទ្ធិក្នុងការចូល / កែទិន្នន័យផ្ទាល់ខ្លួន
                        នៅក្រោមច្បាប់ដែលអាចអនុវត្តបានអ្នកអាចមានសិទ្ធិទទួលបានព័ត៌មានផ្ទាល់ខ្លួនដែលកាន់កាប់ដោយយើងនិងស្នើសុំការកែព័ត៌មាន។
                        អនុលោមតាមច្បាប់ដែលអាចអនុវត្តបានយើងសូមរក្សាសិទ្ធិក្នុងការគិតថ្លៃសេវាសមរម្យសម្រាប់អ្នក
                        ដំណើរការនៃការចូលដំណើរការកាលបរិច្ឆេទឬសំណើកែតម្រូវណាមួយ
                        Cookies
                        យើងប្រើ“ Cookies” ដើម្បីផ្ទុកព័ត៌មានជាក់លាក់អំពីអ្នកនិងតាមដានការចូលមើលរបស់អ្នកទាំងតាមរយៈកុំព្យូទ័ររឺតាមឧបករណ៍ចល័ត។ វាមិនមែនជារឿងចម្លែកទេដែលគេហទំព័រប្រើខូឃីស៍ដើម្បីបង្កើនអត្តសញ្ញាណអ្នកទស្សនារបស់ពួកគេ។
                        <br/>
                        <span className="show_title_privatcy">អនីតិជន</span>
                        <br/>
                        ពាក្យសុំនិងមាតិការបស់ពួកគេមិនត្រូវបានកំណត់គោលដៅសម្រាប់អនីតិជន (អាយុក្រោម ១៨ ឆ្នាំទេ) ហើយយើងមិនមានបំណងលក់ផលិតផលឬសេវាកម្មណាមួយរបស់យើងទៅឱ្យអនីតិជនឡើយ។ ប្រសិនបើអនីតិជនបានផ្តល់ឱ្យយើងនូវព័ត៌មានផ្ទាល់ខ្លួនដោយគ្មានការយល់ព្រមពីអាណាព្យាបាលឬអាណាព្យាបាលឪពុកម្តាយឬអាណាព្យាបាលគួរតែទាក់ទងមកយើងដើម្បីលុបចេញនូវព័ត៌មានទាំងនោះ។
                        វិធានការណ៍សន្តិសុខ
                        យើងប្រើវិធីសាស្រ្តសុវត្ថិភាពសមហេតុផលក្នុងការធ្វើពាណិជ្ជកម្មដើម្បីការពារការចូលប្រើគេហទំព័រដោយគ្មានការអនុញ្ញាតដើម្បីរក្សាភាពត្រឹមត្រូវនៃទិន្នន័យនិងដើម្បីធានាការប្រើប្រាស់ព័ត៌មានត្រឹមត្រូវដែលយើងមាន។
                        សម្រាប់អ្នកប្រើប្រាស់ដែលបានចុះឈ្មោះនៃពាក្យសុំព័ត៌មានមួយចំនួនរបស់អ្នកអាចត្រូវបានមើលនិងកែសម្រួលតាមរយៈគណនីរបស់អ្នកដែលត្រូវបានការពារដោយពាក្យសម្ងាត់។  យើងសូមណែនាំឱ្យអ្នកកុំបែងចែកលេខសម្ងាត់របស់អ្នកទៅនរណាម្នាក់។  អ៊ីម៉ែលរបស់បុគ្គលិកមិនត្រូវការ។  ប្រសិនបើអ្នកចែករំលែកឧបករណ៍ជាមួយអ្នកផ្សេងអ្នកមិនគួរជ្រើសរើសរក្សាទុកព័ត៌មានចូល (ឧទាហរណ៍: លេខសម្គាល់អ្នកប្រើនិងពាក្យសម្ងាត់) នៅលើឧបករណ៍ដែលបានចែករំលែកនោះទេ។
                        គ្មានការបញ្ជូនទិន្នន័យតាមអ៊ីនធឺណិតឬបណ្តាញឥតខ្សែណាមួយអាចត្រូវបានធានាថាមានសុវត្ថិភាពឥតខ្ចោះនោះទេ។  ជាលទ្ធផលនៅពេលដែលយើងព្យាយាមការពារព័ត៌មានដែលយើងរក្សាទុកសម្រាប់អ្នកយើងមិនអាចធានានូវសុវត្ថិភាពនៃព័ត៌មានទាំងឡាយណាដែលអ្នកបានបញ្ជូនមកយើងនោះទេហើយអ្នកធ្វើវាដោយប្រថុយនឹងការផ្លាស់ប្តូរគោលការណ៍ឯកជននេះ។
                        ការផ្លាស់ប្តូរណាមួយចំពោះគោលការណ៍ឯកជនភាពនេះនឹងត្រូវបានផ្សព្វផ្សាយដោយយើងប្រកាសគោលការណ៍ភាពឯកជនដែលបានធ្វើវិសោធនកម្មនៅក្នុងពាក្យសុំ។  បន្ទាប់ពីបានផ្សព្វផ្សាយគោលការណ៍ឯកជនថ្មីនឹងមានប្រសិទ្ធភាពភ្លាមៗ។  ការការឆ្លើយតបរបស់អ្នក
                        យើងសូមស្វាគមន៍ចំពោះការចូលរួមរបស់អ្នកទាក់ទងនឹងគោលការណ៍ភាពឯកជនរបស់យើងនិងរាល់មតិយោបល់លើសេវាកម្មដែលយើងផ្តល់ជូនអ្នក។  អ្នកអាចផ្ញើយោបល់និងការឆ្លើយតបរបស់អ្នកមកកាន់

                    </p>
                </div>
            </div>
      </div>
    );
  }
}
