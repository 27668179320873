import {success,error,abort} from "redux-saga-requests";
export const ADD_SHIPPING_ADDREES="ADD_SHIPPING_ADDREES";
export const GET_SHIPPING_ADDREES="GET_SHIPPING_ADDREES";
export const DELETE_SHIPPING_ADDREES="DELETE_SHIPPING_ADDREES";
export const EDIT_SHIPPING_ADDRESS ="EDIT_SHIPPING_ADDRESS";

export const GET_SHIPPING_ADDRESS_PROVICE="GET_SHIPPING_ADDRESS_PROVICE";
export const GET_SHIPPING_ADDRESS_PRICE ="GET_SHIPPING_ADDRESS_PRICE";
//----------------------------------------->Address
export const add_Address = payload=>({
  type:ADD_SHIPPING_ADDREES,
  payload
});
export const get_address =() =>({
  type:GET_SHIPPING_ADDREES
});
export const delete_address =payload =>({
  type:DELETE_SHIPPING_ADDREES,
  payload
});
export const edit_address =payload =>({
  type:EDIT_SHIPPING_ADDRESS,
  payload
});

export const get_shipping_address_provice =()=>({
  type:GET_SHIPPING_ADDRESS_PROVICE
});

export const get_shipping_address_price =payload =>({
  type:GET_SHIPPING_ADDRESS_PRICE,
  payload
});

const initialState = {
    loading: false,
    error: false
  };


const AddressData = (state = initialState, action) => {
    switch (action.type) {

/**
 * ADD_SHIPPING_ADDREES
 */
      case ADD_SHIPPING_ADDREES:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case success(ADD_SHIPPING_ADDREES):         
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(ADD_SHIPPING_ADDREES):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
        };
      case abort(ADD_SHIPPING_ADDREES):
        return { ...state, loading: false };

/**
 * GET_SHIPPING_ADDREES
 */
      case GET_SHIPPING_ADDREES:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case success(GET_SHIPPING_ADDREES):         
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(GET_SHIPPING_ADDREES):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
        };
      case abort(GET_SHIPPING_ADDREES):
        return { ...state, loading: false };

      /**
       * DELETE_SHIPPING_ADDREES
       */
      case DELETE_SHIPPING_ADDREES:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case success(DELETE_SHIPPING_ADDREES):         
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(DELETE_SHIPPING_ADDREES):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
        };
      case abort(DELETE_SHIPPING_ADDREES):
        return { ...state, loading: false };

      /**
       * EDIT_SHIPPING_ADDRESS
       */
      case EDIT_SHIPPING_ADDRESS:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case success(EDIT_SHIPPING_ADDRESS):         
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(EDIT_SHIPPING_ADDRESS):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
        };
      case abort(EDIT_SHIPPING_ADDRESS):
        return { ...state, loading: false };

         /**
       * GET_SHIPPING_ADDRESS
       */
      case EDIT_SHIPPING_ADDRESS:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case success(EDIT_SHIPPING_ADDRESS):         
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(EDIT_SHIPPING_ADDRESS):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
        };
      case abort(EDIT_SHIPPING_ADDRESS):
        return { ...state, loading: false };


           /**
       * GET_SHIPPING_ADDRESS_PROVICE
       */
      case GET_SHIPPING_ADDRESS_PROVICE:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case success(GET_SHIPPING_ADDRESS_PROVICE):         
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(GET_SHIPPING_ADDRESS_PROVICE):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
        };
      case abort(GET_SHIPPING_ADDRESS_PROVICE):
        return { ...state, loading: false };


           /**
       * GET_SHIPPING_ADDRESS_PRICE
       */
      case GET_SHIPPING_ADDRESS_PRICE:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case success(GET_SHIPPING_ADDRESS_PRICE):         
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(GET_SHIPPING_ADDRESS_PRICE):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
        };
      case abort(GET_SHIPPING_ADDRESS_PRICE):
        return { ...state, loading: false };





      default:
        return state;
    }
  
    
    
    
  }
  
  export default AddressData;