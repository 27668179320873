import { connect } from "react-redux";
import Search from "../Screen/search"
import {getPorductFilter,getPorductCategory} from '../modules/product/reducer'
const mapStateToProps = state => ({
  ...state,
  
});

const mapDispatchToProps = {
  getPorductFilter,getPorductCategory
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
