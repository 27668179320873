import { call, put,takeLatest} from "redux-saga/effects";
import { error, success } from "redux-saga-requests";
import io from 'socket.io-client';

import { axios } from "../app";
import {
    USER_MESSAGE_LIST__LOCAL,
    RECEIVE_MESSAGE,
    OWN_SOCKET,
    USER_MESSAGE_LIST,
    OLD_MESSGES,
    PARTNER_SEEN,
    SEND_MESSAGE,
    SELECT_USER_CHAT,
} from "./reducer";
import store from '../../store'
import {socket} from '../../store'
import {normalize} from "./../../utils/normalize"
var idNoSeen=[];
export function* selectUserChat({payload}) {
    try {
        console.log('selectUserChat',payload)
        var props = store.getState();
        var selected_user = payload;
        var dataSocket = props.chat.dataSocket;
        if(selected_user !== ''){
            var data = {
                from: dataSocket, 
                to: JSON.parse(selected_user), 
                type: 'member'
            }
            socket.emit("client_select_user", data);
        }
        yield put({
            type: success(SELECT_USER_CHAT),
            payload: {
                selected_user: selected_user,
                // iduser_new_chat:idNoSeen
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(SELECT_USER_CHAT),
            payload: {
                error:"selectUserChat message error"
            }
        });
    }
}
export function* onSendMessage({payload}) {
    try {
        // console.log('onSendMessage---->',payload)
        var props = store.getState();
        //get from sele chat already
        var selected_user = props.chat.selected_user;
         //get from sele chat already
        var dataSocket = props.chat.dataSocket;
        var message = payload;
        message[0].sent = true;
        message[0].received = false
        if(selected_user !== ''){
            var data = {
                from: dataSocket,
                to:JSON.parse(selected_user),
                messages:message,
                type: 'member'
            }
            socket.emit("client_send", data);
        yield put({
            type: success(SEND_MESSAGE),
        });
   
        }else{
            alert('please select user')
        }
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        console.log('error',message)
        yield put({
            type: error(SEND_MESSAGE),
            payload: {
                error:"onSendMessage message error"
            }
        });
    }
}

export function* onReceiveMessage({payload}) {
    try {
            var props = store.getState();
            var messages = props.chat.messages;
            var selected_user = props.chat.selected_user;
            var message = payload.message


            console.log('-------',message[0].user._id, JSON.parse(selected_user)._id)
           if(selected_user && message[0].user._id == JSON.parse(selected_user)._id){
                console.log('-',message[0].user._id,)
                var messages_new = [ ...messages,...message];
                var data = {
                    to:message[0].user._id,
                    from: payload.to._id,
                    type:'member'
                }
                socket.emit("client_seen", data);
            }else {
                var store_=props.chat.userListMessage;
                var arrayE = [];    
                const found = store_.find(element => element._id == message[0].user._id);
                console.log('arrayE',found)
                const index = store_.findIndex(x => x._id == message[0].user._id);
                var jsn = JSON.parse(found.message)[0];
                jsn = {...jsn, text:message[0].text} 
                var newItem = {...found,not_seen:true,message:JSON.stringify([jsn])}
                arrayE = store_;
                arrayE[index] = newItem
                
                yield store.dispatch({ type: USER_MESSAGE_LIST__LOCAL, payload:arrayE});
            }
  
       
       
       
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(RECEIVE_MESSAGE),
            payload: {
                error:"receive message error"
            }
        });
    }




    // try {
    //     var props = store.getState();
    //     var messages = props.chat.messages;
    //     var selected_user = props.chat.selected_user;
    //     var message = payload.message
    //     var messages_new = [ ...messages,...message];
    //    if(selected_user && message[0].user._id == JSON.parse(selected_user)._id){
    //     // if(selected_user && payload.to.id===JSON.parse(selected_user)._id){
    //         var messages_new = [ ...messages,...message];
    //         var data = {
    //             to:message[0].user._id,
    //             from: payload.to._id,
    //             type:'member'
    //         }
    //         if(idNoSeen.length>0){
    //             var newAarry=[];
    //             idNoSeen.map((itme)=>{
    //                 if(itme!=message[0].user._id){
    //                     newAarry.push(itme.trim());
    //                 }
    //             })
    //             idNoSeen=newAarry
    //         }
    //         socket.emit("client_seen", data);
    //     }else{
    //         var messages_new = [ ...messages];
    //         let idShop=payload['message']['0']['user']['_id'];
    //         let idshopAlready=idNoSeen.filter((number)=> {return number===idShop});
    //         if(idNoSeen.length===0 || idshopAlready!=idShop){
    //             idNoSeen.push(idShop)
    //         }
    //     }
    //     yield put({
    //         type: success(RECEIVE_MESSAGE),
    //         payload: {
    //             messages: messages_new,
    //             iduser_new_chat:idNoSeen
    //         }
    //     });
    // } catch (e) {
    //     const parseError = yield JSON.parse(JSON.stringify(e));
    //     const message = parseError;
    //     yield put({
    //         type: error(RECEIVE_MESSAGE),
    //         payload: {
    //             error:"receive message error"
    //         }
    //     });
    // }
}

export function* getOwnSocket({payload}) {
    try {
        yield put({
            type: success(OWN_SOCKET),
            payload: {
                dataSocket: payload,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(OWN_SOCKET),
            payload: {
                error:"getOwnSocket message error"
            }
        });
    }
}

export function* getUserMessageList({payload}) {
    try {
        yield put({
            type: success(USER_MESSAGE_LIST),
            payload: {
                userListMessage: payload,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(USER_MESSAGE_LIST),
            payload: {
                error:"getUserMessageList message error"
            }
        });
    }
}
function* getUserMessageList__({payload}) {
    console.log('getUserMessageList__', payload);
    
    try {
        yield put({
            type: success(USER_MESSAGE_LIST__LOCAL),
            payload: {
                userListMessage: payload,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(USER_MESSAGE_LIST__LOCAL),
            payload: {
                error:"getUserMessageList message error"
            }
        });
    }
}
export function* getOldMessages({payload}) {
    try {
        yield put({
            type: success(OLD_MESSGES),
            payload: {
                messages: payload,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(OLD_MESSGES),
            payload: {
                error:"getOldMessages message error"
            }
        });
    }
}

export function* updatePartnerSeen({payload}) {
    try {
        var props = store.getState();
        var messages = props.chat.messages;
        var selected_user = props.chat.selected_user;
        var new_messages = [];
        if(selected_user && payload.from == JSON.parse(selected_user)._id){
            for (let index = 0; index < messages.length; index++) {
                const element = messages[index];
                new_messages.push({...element, received: true})
            }
            console.log('seen new nessage', new_messages);
        }
        yield put({
            type: success(PARTNER_SEEN),
            payload: {
                messages:new_messages,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(PARTNER_SEEN),
            payload: {
                error:"receive message error"
            }
        });
    }
}

export function* chatSaga() {
    yield takeLatest(RECEIVE_MESSAGE, onReceiveMessage);     
    yield takeLatest(USER_MESSAGE_LIST__LOCAL, getUserMessageList__);     
    yield takeLatest(OWN_SOCKET, getOwnSocket);    
    yield takeLatest(USER_MESSAGE_LIST, getUserMessageList);    
    yield takeLatest(OLD_MESSGES, getOldMessages);    
    yield takeLatest(PARTNER_SEEN, updatePartnerSeen);    
    yield takeLatest(SEND_MESSAGE, onSendMessage)
    yield takeLatest(SELECT_USER_CHAT, selectUserChat)
}