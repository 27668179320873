import {store} from '../store/index'
export function keyword(key){
   const data=store.getState().app['data_title'];
   if(data && data['results'].length>0){
       var a=data['results'].find((e)=>e['titAlias']===key);
       if(a){
        return a['titTitle']   
       }else{
        return ""
       }
   }else{
       return ""
   }
}