import { call, put, takeLatest, retry } from "redux-saga/effects";
import {error,success} from "redux-saga-requests";
import {axios} from "../app";
import {
    DELETE_SHIPPING_ADDREES,
    GET_SHIPPING_ADDREES,
    ADD_SHIPPING_ADDREES,
    EDIT_SHIPPING_ADDRESS,

    GET_SHIPPING_ADDRESS_PROVICE,
    GET_SHIPPING_ADDRESS_PRICE
} from './reducer'
import {normalize} from "../../utils/normalize"


//-------------------------------------------------ADDREES
export function* getAddress() {
    try {
        const get_address =yield call(axios.get,"/user_profile_information/address/" );
        const requested = normalize(get_address)
        yield put({
            type: success(GET_SHIPPING_ADDREES),
            payload: {
                get_address: requested
            }
        });
        
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(GET_SHIPPING_ADDREES),
            payload: {
                err_get_address:message
            }
        });
    }
}

export function* addAddress(payload) {
    try {
        const add_address =yield call(axios.post,"user_profile_information/addAddress",payload['payload']);
        const requested = normalize(add_address)
        yield put({
            type: success(ADD_SHIPPING_ADDREES),
            payload: {
                add_address: requested
            }
        });
       
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        
        yield put({
            type: error(ADD_SHIPPING_ADDREES),
            payload: {
                err_add_address:"erroAddAddress"
            }
        });
    }
}

export function* deleteAddress(payload) {
    try {
        const del_address =yield call(axios.post,"user_profile_information/deleteAddress",payload['payload']);
        const requested = normalize(del_address)
        yield put({
            type: success(ADD_SHIPPING_ADDREES),
            payload: {
                del_address: requested
            }
        });
      
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(ADD_SHIPPING_ADDREES),
            payload: {
                err_add_address:"erroDeleteAddress"
            }
        });
    }
}

export function* editAddress(payload) {
    try {
        const edit_address =yield call(axios.post,"user_profile_information/editAddress",payload['payload']);
        const requested = normalize(edit_address)
        yield put({
            type: success(ADD_SHIPPING_ADDREES),
            payload: {
                edit_address: requested
            }
        });
     
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        
        yield put({
            type: error(ADD_SHIPPING_ADDREES),
            payload: {
                err_add_address:"erroAddAddress"
            }
        });
    }
}

export function* getShippingAddressProvice() {
    try {
        const get_shipping_address_provice =yield call(axios.get,"/shipping_address" );
        const requested = normalize(get_shipping_address_provice)
       
        yield put({
            type: success(GET_SHIPPING_ADDRESS_PROVICE),
            payload: {
                get_shipping_address_provice: requested
            }
        });
       
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(GET_SHIPPING_ADDRESS_PROVICE),
            payload: {
                err_get_address:message
            }
        });
    }
}

export function* getShippingAddressPrice(payload) {
    try {
        const get_shipping_address_price =yield call(axios.get,"shipping_address/store_price/india/"+ payload.payload );
        const requested = normalize(get_shipping_address_price)
        
        yield put({
            type: success(GET_SHIPPING_ADDRESS_PRICE),
            payload: {
                get_shipping_address_price: requested
            }
        });
      
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(GET_SHIPPING_ADDRESS_PRICE),
            payload: {
                err_get_address:message
            }
        });
    }
}
export function* AddressSaga() {
    yield takeLatest(GET_SHIPPING_ADDREES,getAddress);
    yield takeLatest(ADD_SHIPPING_ADDREES,addAddress);
    yield takeLatest(DELETE_SHIPPING_ADDREES,deleteAddress);
    yield takeLatest(EDIT_SHIPPING_ADDRESS,editAddress);

    yield takeLatest(GET_SHIPPING_ADDRESS_PROVICE,getShippingAddressProvice);
    yield takeLatest(GET_SHIPPING_ADDRESS_PRICE,getShippingAddressPrice);
}
