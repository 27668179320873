export const LOGO= require('../Assets/images/logo.png');
export const NOTIMAGE=require('../Assets/images/no_image.png');

export const PAYMENIMAGE=[
    require('../Assets/images/aba.png'),
    require('../Assets/images/alipay.png'),
    require('../Assets/images/emoney.png'),
    require('../Assets/images/pipay.png'),
    require('../Assets/images/smart_luy.png'),
    require('../Assets/images/wing.jpg'),
];
export const GIF={
    'waiting':require('../Assets/images/waiting.gif'),
    'delivery':require('../Assets/images/delivery.gif'),
    'already':require('../Assets/images/done.gif'),
    'done_add_tocart':require('../Assets/images/done_added_tocart.gif'),
    'wallet':require('../Assets/images/wallet.gif'),
    'coins':require('../Assets/images/coins.gif'),
    'cartEmty':require('../Assets/images/gif_cart.gif'),
    'thank':require('../Assets/images/thz1.gif'),
    'present':require('../Assets/images/present.gif')
}

export const ICONS={
    'facebook':require('../Assets/images/facebook.png'),
    'google':require('../Assets/images/google.png'),
    'lg-en':require('../Assets/images/lang-en.png'),
    'lg-kh':require('../Assets/images/lang-kh.png'),
    'lg-cn':require('../Assets/images/lang-cn.png')
}
export const IMAGES={
    'appStore':require('../Assets/images/appStore.jpg'),
    'playStore':require('../Assets/images/playstore.jpg'),
    'logo':require('../Assets/images/App-Logo.png'),
    'me':require('../Assets/images/ME.webp')
}
export const ICONMESSAGER= require('../Assets/images/iconMessager.png')
