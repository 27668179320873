import React, { Component } from 'react';
import '../Style/Component/categories.css'
import '../Assets/color.css'
import {CATEGORIES_URL_IMAGE} from '../modules/app/config'
import {GridList,GridListTile} from '@material-ui/core'
import {Row,Col} from 'react-bootstrap'
import Slider from "react-slick";
import {store} from "../store/index"
import { Link } from 'react-router-dom';
import {_handleData} from '../utils/colors'
import { red } from '@material-ui/core/colors';
import {setColor} from '../utils/colors'
export default class Categories extends Component {
    _looping(Array,color){
        var AAA=0;
        var Rs=[];
        var color=setColor('lightBackground');
        if(Array){
            if((Array.length)%6===0){
                AAA=Array.length;
            }else{
                AAA=Array.length+(6-(Array.length)%6);
            }
            if(AAA>0){
                    for(var j=0;j<=AAA-6;j=j+6){
                        if(Array.length>0 && Array[j]['image']){
                            Rs.push(
                                <div key={j} className="control_group_slide">
                                     <Row>
                                         <Col md={6} sm={6} xs={6}>
                                             <Row>
                                                 <Col md={6} sm={6} xs={6} >
                                                     {
                                                         Array[j]?
                                                         <Row className="image_1 ">
                                                             <Link to={{
                                                                 pathname: "/oneCategory/"+Array[j].categoryID
                                                                 }} 
                                                                  className="image_pro" style={{backgroundImage: `url(${CATEGORIES_URL_IMAGE+Array[j]['image']})`}}
                                                                 >
                                                                 <button>{Array[j].name?Array[j].name:null}</button>
                                                             </Link>
                                                         </Row>:null
                                                     }
                                                     {
                                                         Array[j+1]?
                                                         <Row  className="image_2" >
                                                             <Link to={{
                                                                 pathname: "/oneCategory/"+Array[j+1].categoryID
                                                             }} 
                                                             style={{backgroundImage: `url(${CATEGORIES_URL_IMAGE+Array[j+1]['image']})`}} className="image_pro">
                                                                 <button>{Array[j+1].name}</button>
                                                             </Link>
                                                         </Row>
                                                         :<Row  className="image_2" >
                                                        </Row>
                                                     }
                                                    
                                                 </Col>
                                                 {
                                                     Array[j+2]?
                                                     <Col md={6} sm={6} xs={6}className="image_3" >
                                                         <Link to={{
                                                             pathname: "/oneCategory/"+
                                                             Array[j+2].categoryID}}
                                                         >
                                                             <div
                                                                 style={{backgroundImage: `url(${CATEGORIES_URL_IMAGE+Array[j+2]['image']})`}} className="image_pro">
                                                                     <button>{Array[j+2].name}</button>
                                                             </div>
                                                        </Link>
                                                     </Col>
                                                     :
                                                     <Col md={6} sm={6} xs={6} className="image_3" ></Col>
                                                 }
                                             </Row>
                                         </Col>
                                         <Col  md={6} sm={6} xs={6}>
                                             <Row>
                                                 {
                                                     Array[j+3]?
                                                     <Col md={6} sm={6} xs={6} className="image_4" >
                                                         <Link to={{
                                                             pathname: "/oneCategory/"+
                                                             Array[j+3].categoryID}}
                                                         >
                                                        <div 
                                                             style={{backgroundImage: `url(${CATEGORIES_URL_IMAGE+Array[j+3]['image']})`}} className="image_pro">
                                                             <button>{Array[j+3].name}</button>
                                                        </div>
                                                        </Link>
                                                     </Col>
                                                     :
                                                     <Col md={6} sm={6} xs={6} className="image_4" > </Col>
                                                 }
                                                 {
                                                     Array[j+4]?
                                                     <Col md={6} sm={6} xs={6}className="image_5" >
                                                        <Link to={{
                                                                 pathname: "/oneCategory/"+Array[j+4].categoryID
                                                         }}>
                                                         <div
                                                             style={{backgroundImage: `url(${CATEGORIES_URL_IMAGE+Array[j+4]['image']})`}} className="image_pro">
                                                             <button>{Array[j+4].name}</button>
                                                         </div>
                                                        </Link>
                                                     </Col>
                                                     :
                                                     <Col md={6} sm={6} xs={6} className="image_5" > </Col>
                                                 }
                                             </Row>
                                             {   Array[j+5]?
                                                 <Row   className="image_6" >
                                                      <Link to={{
                                                                 pathname: "/oneCategory/"+
                                                                 Array[j+5].categoryID
                                                         }} style={{backgroundImage: `url(${CATEGORIES_URL_IMAGE+Array[j+5]['image']})`}} className="image_pro">
                                                         <button>{Array[j+5].name}</button>
                                                     </Link>
                                                 </Row>:
                                                  <Row className="image_6" >
                                                 </Row>
                                             }
                                            
                                         </Col>
                                     </Row>
                                 </div>
                            )
                    }
                }
            }
        }
        return Rs;
    }
   
    _loop_data_small_screee(Array){
        var color=setColor('lightColor');
        var rs=[];
        if(Array){
            Array.map((sl,indx)=>{
                rs.push(
                    <div key={indx} className="iamge_smail_cat" style={{color:color}}>
                        <Link to={{
                                pathname: "/oneCategory/"+
                                sl.categoryID
                        }}>
                        <img src={CATEGORIES_URL_IMAGE+sl.image}  />
                        <button>{sl.name}</button>
                    </Link>
                    </div>
                )
            })
        }
        return rs;
    }
  render() {
    var color=setColor('lightBackground');
    const settings = {
        dots: true,
        accessibility:true,
        prevArrow:(<button type="button" id="prev">Previous</button>),
        nextArrow: (<button  type="button" id="next">Previous</button>),
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:true
      };
      const settings_1 = {
        slidesToShow: 3,
        slidesToScroll: 3,
        speed: 500,
        rows: 2,
        slidesPerRow: 1,
        arrows:false
      };
    const {DataCategories} =this.props;
    return (
        <div >
            <div className="categoryScreen_big">
            <Slider  {...settings}>
                {
                    this._looping(DataCategories)
                }
            </Slider>  
        </div>
        <div className="categoryScreen_small">
            <Slider  {...settings_1}>
                {
                    this._loop_data_small_screee(DataCategories)
                }
            </Slider>  
        </div>
      </div>
    );
  }
}