import React, { Component } from 'react'
import {Modal,Row,Col} from 'react-bootstrap'
import {ErrorOutline,Close,LocalMallTwoTone, BorderAll, BorderBottom} from '@material-ui/icons';
import '../Style/address.css'
import {setColor} from '../utils/colors'
import { keyword } from '../utils/keywords';
export default class addAddress extends Component {
    constructor(props){
        super(props);
        this.state={
            _addresses:[],
            modalAdd:false,
            addAddressMore:false,

            setIDAddress:'',
            showModal:true,

            editInfo:false,
            noInput:false,
            _userAddress:{
                name:'',
                phone:'',
                address:'',
                addDetail:"",
                locationType:"",
                addAddressMore:false
            },
            _got_provice:[]
        }
    }
    componentDidMount(){    
        if(localStorage.getItem('persist:amatakWebsite')){
            const user_sms = JSON.parse(localStorage.getItem('persist:amatakWebsite'));
            const login_sms=JSON.parse(user_sms.user);
            if(login_sms.login && login_sms.login.message==="success"){
              if(login_sms.login && login_sms.login.token){
                this.props.get_shipping_address_provice();
              }
           }
         }
        this._getDataUser();
    }
    componentWillReceiveProps(nextProps){
        const {AddressData}=this.props;
        if(nextProps.AddressData.get_address && nextProps.AddressData.get_address !=AddressData.get_address ){
            if(nextProps.AddressData.get_address.message==="success"){
                this.setState({
                    _addresses:nextProps.AddressData.get_address['data']
                })
            }
        }
        if(nextProps.AddressData.del_address && nextProps.AddressData.del_address!=AddressData.del_address){
            if(nextProps.AddressData.del_address['message']==="success"){
                this.setState({
                    _addresses:nextProps.AddressData.del_address['data']
                });
                if(nextProps.AddressData.del_address['data'].length>0){
                    var DATA=nextProps.AddressData.del_address['data'][nextProps.AddressData.del_address['data'].length-1];
                    localStorage.setItem('@UserAddress-Amatak',JSON.stringify(DATA));
                }else{
                    localStorage.setItem('@UserAddress-Amatak','');
                }
            }
        }
        if(nextProps.AddressData.add_address && nextProps.AddressData.add_address!=AddressData.add_address){
            if(nextProps.AddressData.add_address['message']==="success"){
                this.setState({
                    _addresses:nextProps.AddressData.add_address['data']
                });
                var DATA=nextProps.AddressData.add_address['data'][nextProps.AddressData.add_address['data'].length-1];
                localStorage.setItem('@UserAddress-Amatak',JSON.stringify(DATA));
            }
        }
        if(nextProps.AddressData.edit_address && nextProps.AddressData.edit_address!=AddressData.edit_address){
            if(nextProps.AddressData.edit_address['message']==="success"){
                this.setState({
                    _addresses:nextProps.AddressData.edit_address['data']
                });
            }
        }

        if(nextProps.AddressData.get_shipping_address_provice && nextProps.AddressData.get_shipping_address_provice!=AddressData.get_shipping_address_provice){
            if(nextProps.AddressData.get_shipping_address_provice['message']==="success"){
                this.setState({
                    _got_provice:nextProps.AddressData.get_shipping_address_provice['results']
                });
            }
            
        }
    }

    _getDataUser(){
        try{
            var dataUser=JSON.parse(localStorage.getItem('persist:amatakWebsite'));
            var JsonDATA=JSON.parse(dataUser['user'])['login']['data']
            this.setState({
                _userAddress:{...this.state._userAddress,name:JsonDATA['fullName'],phone:JsonDATA['fullPhoneNumber']}
            })
        }catch(e){
            
        }
        
    }
    _loopTypeOfBuilding(){
        let bgP=setColor('primaryBackground');
        let colP=setColor('primaryColor');
        var typeOfBuilding=[{"name":keyword('home-1')?keyword('home-1'):"Home"},{"name":keyword('school')?keyword('school'):"school"}];
        var result =[];
        typeOfBuilding.map((data,i)=>{
          result.push(
            <button key={i} style={this.state._userAddress.locationType===data['name']?{backgroundColor:bgP,color:colP}:{backgroundColor:"ghostwhite",color:"black"}} className={this.state._userAddress.locationType===data['name']?"btnTypeBuildingClicked":"btnTypeBuilding"}  onClick={()=>{this._handleUserAddress('locationType',data['name'])}}>
                {data['name']}
            </button>
          )
        })
        return result;
      }
    _loopCityProvince(){
        let bgP=setColor('primaryBackground');
        let colP=setColor('primaryColor');
        var result =[];
        var data=this.state._got_provice;
        if(data){
            data.map((address,i)=>{
                result.push(
                  <Col sm={4} xs={6}  key={i} >
                    <button style={this.state._userAddress.address===address['name']?{backgroundColor:bgP,color:colP}:{backgroundColor:"ghostwhite",color:"black"}} className={this.state._userAddress.address===address['name']?"btnChoiseProvideClicked":"btnChoiseProvide"}  
                    onClick={()=>{this._handleUserAddress('address',address['name'])}}>
                        {address['name']}
                    </button>
                  </Col>
                )
            })
        }
        return result;
      }
      _submitAddress(){
        var localsto= localStorage.getItem('@UserAddress-Amatak');
        var a=this.state._userAddress.name,b=this.state._userAddress.phone,c=this.state._userAddress.address,d=this.state._userAddress.addDetail,e=this.state._userAddress.locationType;
        if(a && b && c  && d && e){
              var Data={
                            address:
                            [{name:a,
                            phone:b,
                            address:c,
                            addDetail:d,
                            locationType:e}]
                        }
            this.props.add_Address(Data);
            this.setState({
                _userAddress:{
                    ...this.state._userAddress,
                    address:'',
                    addDetail:"",
                    locationType:""
                },
                addAddressMore:false
            });
            this.props.onHide();
          }
        else if(localsto==='' && (a==='' || b==='' || c==='' || d==='' || e==='')){    
                this.setState({
                    noInput:true
                })
        }
        else if(localsto===''){
            this.setState({
                noInput:true
            })
        }
    }
    _handleUserAddress(key,value){
        this.setState({_userAddress:{...this.state._userAddress,[key]:value}});
    }
    _EditAddress(name,pho,add,lAt,adD,idAdd,forstarte){
        this.setState({
            // addAddressMore:forstarte,
            _userAddress:{
                name:name,
                phone:pho,
                address:add,
                addDetail:adD,
                locationType:lAt,
                addAddressMore:forstarte,
            },
            setIDAddress:idAdd,
        });
    }
    _setLocalstore=(data)=>{
        if(data){
            localStorage.setItem('@UserAddress-Amatak',JSON.stringify(data));
            this.setState({
                modalAdd:false,
                addAddressMore:false
            })
        }
    }
    

    _loopAddress(){
        var result=[];
        var data=this.state._addresses;
        let bgP=setColor('primaryBackground');
        let colP=setColor('primaryColor');
        if(localStorage.getItem('@UserAddress-Amatak')&&localStorage.getItem('@UserAddress-Amatak')!=""){
            var localSto=localStorage.getItem('@UserAddress-Amatak');
            var BB=JSON.parse(localSto)
            if(data){
                data.map((daram,idex)=>{
                    result.push(
                        <Row style={data[idex]['adID']===BB['adID']?{backgroundColor:bgP,color:colP}:null} key={idex} className="oneAddress" onClick={()=>{this._setLocalstore(daram)}}>
                            <Col sm={12} xs={12}className="detail_one_address">
                                {daram['name']} , {daram['phone']} , {daram['address']} , {daram['locationType']} , {daram['addDetail']}
                            </Col>
                            
                            <Col sm={12} xs={12}className="btn_2_edit_delete">
                                     <button id="btn_deleteAddress" onClick={()=>{this.props.delete_address({'addressID':daram['adID']} )}}>{keyword('delete')?keyword('delete'):"Delete"}</button>
                                     <button id="btn_editAddress" onClick={()=>{
                                             this._EditAddress(daram['name'],daram['phone'],daram['address'],daram['locationType'],daram['addDetail'],daram['adID'],true);
                                    }} >
                                    {keyword('update')?keyword('update'):'Edit'}</button>
                             </Col>
                        </Row>
                        )
                    }
                )
            }
        }
        return(result);
    }
    _editSubmit () {
        var a=this.state._userAddress.name,
            b=this.state._userAddress.phone,
            c=this.state._userAddress.address,
            d=this.state._userAddress.addDetail,
            e=this.state._userAddress.locationType,
            f=this.state.setIDAddress
        var Data={
            "address":[{
                "name":a,
                "phone":b,
                "address":c,
                "addDetail":d,
                "locationType":e,
                "adID": f
            }]
        }
        this.props.edit_address(Data);
    }
    render() {
        let bgP=setColor('primaryBackground');
        let colP=setColor('primaryColor');
        const{onHide,onShow}=this.props
        
        return (
            <div>
               
                <Modal
                    show={onShow}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    keyboard={true}
                    centered
                    className="modal_add_address__"
                    >
                    <Modal.Header>
                        <h3>{keyword('address')?keyword('address'):"Address"}</h3>
                        <Close id="id_remove" onClick={onHide}/>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="showAllAddress">
                        {this._loopAddress()}
                        {
                            this.state._userAddress.addAddressMore?
                            <button  id="btnAddAddressMore" onClick={()=>{this.setState({_userAddress:{...this.state._userAddress,addAddressMore:false}})}}>{keyword('cancel')?keyword('cancel'):"Cancel"}</button>
                            :
                            <button style={{backgroundColor:bgP,color:colP}} id="btnAddAddressMore" onClick={()=>{this.setState({_userAddress:{...this.state._userAddress,addAddressMore:true}})}}>{keyword('add-new-address')?keyword('add-new-address'):"Add new address"}</button>
                        }
                        </div>
                       {
                           this.state._userAddress.addAddressMore?
                                <div className="inputNewAddress">     
                                {
                                    this.state._userAddress.name===''?<p style={{fontSize:12,float:'left',margin:'5px 0px -18px 0px',color:'red'}}>{keyword('word-for-address-require-name')?keyword('word-for-address-require-name'):"*Name required"}</p> :null
                                }
                                <input id="inputName" placeholder={keyword('name')?keyword('name'):"Name"} value={this.state._userAddress.name}  onChange={()=>{this._handleUserAddress('name',document.getElementById('inputName').value)}} style={this.state._userAddress.name===''?{border:'1px solid red'}:{border:'0px solid red',borderBottom:'1px solid black'}}></input>
                                
                                {
                                    this.state._userAddress.phone===''?<p style={{fontSize:12,float:'left',margin:'5px 0px -18px 0px',color:'red'}}>{keyword('word-for-address-require-phone')?keyword('word-for-address-require-phone'):"*Phone required"}</p>:null
                                }
                                <input id="numberPhone" placeholder={keyword('phone-number')?keyword('phone-number'):"Phone number"} value={this.state._userAddress.phone} onChange={()=>{this._handleUserAddress('phone',document.getElementById('numberPhone').value)}} style={this.state._userAddress.phone===''?{border:'1px solid red'}:{border:'0px solid red',borderBottom:'1px solid black'}}></input>
                                
                                <div className="choise">
                                    <label>
                                        {
                                            this.state._userAddress.address===''?<p style={{fontSize:12,color:'red',display:'inline',marginRight:3}}>*</p>:null
                                        }
                                        {keyword('city-province')?keyword('city-province'):"Please select a province or city:"}
                                    </label>
                                    <div className="row">
                                        {this._loopCityProvince()}
                                    </div>

                                    <label>
                                        {
                                        this.state._userAddress.addDetail===''?<p style={{fontSize:12,float:'left',marginRight:3,display:'inline',color:'red'}}>*</p>:null
                                    }
                                        {keyword('house-number,-street-or-address-details')?keyword('house-number,-street-or-address-details'):"Detail location"}
                                    </label>
                                    <textarea value={this.state._userAddress.addDetail} type="text" id="textArea" placeholder= {keyword('house-number,-street-or-address-details')?keyword('house-number,-street-or-address-details'):"Detail location"} onChange={()=>{this._handleUserAddress('addDetail',document.getElementById('textArea').value)}}></textarea>
                                    
                                    <label>
                                    {
                                        this.state._userAddress.locationType===''?<p style={{fontSize:12,display:'inline',color:'red',marginRight:3}}>*</p>:null
                                    }{keyword('location')?keyword('location'):"Location"}
                                    </label>
                                    <div className="row ">
                                        {this._loopTypeOfBuilding()}
                                    </div>
                                </div>
                                {
                                    this.state.editInfo && 
                                    this.state._userAddress.addDetail!='' &&
                                    this.state._userAddress.address!='' && 
                                    this.state._userAddress.locationType !=''&& 
                                    this.state._userAddress.name!="" &&
                                    this.state._userAddress.phone!=""
                                    ?
                                    <button id="btnFinishAddress_" onClick={()=>{this._editSubmit()}} >{keyword('update')?keyword('update'):'Edit'}</button>
                                    :
                                    (
                                        this.state._userAddress.addDetail!='' &&
                                        this.state._userAddress.address!='' && 
                                        this.state._userAddress.locationType !=''&& 
                                        this.state._userAddress.name!="" &&
                                        this.state._userAddress.phone!=""
                                    )?
                                    <button id="btnFinishAddress_" onClick={()=>{this._submitAddress()}} style={{backgroundColor:bgP,color:colP}}>{keyword('ok')?keyword('ok'):"OK"}</button>
                                    :
                                    <button disabled id="btnFinishAddress_">{keyword('ok')?keyword('ok'):"OK"}</button>
                                }  
                                    <button id="btnCancelAddress" onClick={onHide} >{keyword('back')?keyword('back'):"back"}</button>

                            </div>:null
                       }
                       
                    </Modal.Body>
                </Modal>
                <Modal
                     show={this.state.noInput}
                     size="sm"
                     aria-labelledby="contained-modal-title-vcenter"
                     backdrop="static"
                     keyboard={false}
                     centered
                >
                    <Modal.Header  style={{backgroundColor:'#ffeeb6fd'}}>
                        <h5></h5>
                        <Close  onClick={()=>{this.setState({noInput:false})}}/>
                    </Modal.Header>
                    <Modal.Body style={{textAlign:'center'}}>
                        <ErrorOutline style={{fontSize:60}}/>
                        <br/>
                        <br/>
                        <h5>{keyword('please-fill-in-correctly')?keyword('please-fill-in-correctly'):"Please fill in correctly!"}</h5>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
