import { connect } from "react-redux";
import Address from "../Component/addAddress"
import {
    get_address,
    add_Address,
    delete_address,
    edit_address,
    get_shipping_address_provice
  } from '../modules/address/reducer'
const mapStateToProps = state => ({
  ...state,
  // get_addressData:state.AddressData.get_address
});

const mapDispatchToProps = {
  get_address,
  add_Address,
  delete_address,
  edit_address,
  get_shipping_address_provice
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Address);
