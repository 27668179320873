import { success, error, abort } from "redux-saga-requests";

export const LOGIN = 'LOGIN'
export const LOGIN_SMS = 'LOGIN_SMS'
export const ADD_PIN='ADD_PIN';
export const CONFIRM_PIN='CONFIRM_PIN';
export const CHECK_OUT_USER='CHECK_OUT_USER';
export const GET_ORDER_HISTORY='GET_ORDER_HISTORY';
export const GET_CARTLIST='GET_CARTLIST';
export const DELETE_CART='DELETE_CART';
export const ADD_CART="ADD_CART";
export const ORDER_HISTORY_RECEVICE="ORDER_HISTORY_RECEVICE"
export const TRANSACTION="TRANSACTION";
export const SENDNOTIFICATION="SENDNOTIFICATION";
export const USER_WALLET="USER_WALLET";

export const userLogin = payload => ({
  type: LOGIN,
  payload
});
export const userLoginSMS = payload => ({
  type: LOGIN_SMS,
  payload
});


export const addNew_pin = payload => ({
  type: ADD_PIN,
  payload
});
export const confirm_pin = payload => ({
  type: CONFIRM_PIN,
  payload
});

export const checkOut_user = payload => ({
  type: CHECK_OUT_USER,
  payload
});

export const get_orderHistory = payload => ({
  type: GET_ORDER_HISTORY,
  payload
});
export const getCartList = () => ({
  type:GET_CARTLIST
});
export const deleteCart = payload => ({
  type:DELETE_CART,
  payload
});
export const addCart = payload => ({
  type:ADD_CART,
  payload
});
export const comfirmOrderHistoryRecevice = payload => ({
  type:ORDER_HISTORY_RECEVICE,
  payload
});
export const getTransaction = payload => ({
  type:TRANSACTION,
  payload
});
export const setNotification = payload => ({
  type:SENDNOTIFICATION,
  payload
});
export const getUserWallet =() => ({
  type:USER_WALLET
});







const initialState = {
  loading: false,
  error: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * LOGIN
     */
    case LOGIN:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(LOGIN):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(LOGIN):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(LOGIN):
      return { ...state, loading: false };

      /**
     * LOGIN_SMS
     */
    case LOGIN_SMS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(LOGIN_SMS):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(LOGIN_SMS):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(LOGIN_SMS):
      return { ...state, loading: false };

    /**
     * ADD_PIN
     */
    case ADD_PIN:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(ADD_PIN):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(ADD_PIN):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(ADD_PIN):
      return { ...state, loading: false };

      /**
     * CONFIRM_PIN
     */
    case CONFIRM_PIN:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(CONFIRM_PIN):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(CONFIRM_PIN):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(CONFIRM_PIN):
      return { ...state, loading: false };

      /**
     * CHECK_OUT_USER
     */
    case CHECK_OUT_USER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(CHECK_OUT_USER):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(CHECK_OUT_USER):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(CHECK_OUT_USER):
      return { ...state, loading: false };
    /**
     * GET_ORDER_HISTORY
     */
    case GET_ORDER_HISTORY:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_ORDER_HISTORY):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_ORDER_HISTORY):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_ORDER_HISTORY):
      return { ...state, loading: false };

         /**
     * GET_CARTLIST
     */
    case GET_CARTLIST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_CARTLIST):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_CARTLIST):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_CARTLIST):
      return { ...state, loading: false };
    /**
     * ADD_CART
     */
    case ADD_CART:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(ADD_CART):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(ADD_CART):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(ADD_CART):
      return { ...state, loading: false };

      /**
     * DELETE_CART
     */
    case DELETE_CART:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(DELETE_CART):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(DELETE_CART):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(DELETE_CART):
      return { ...state, loading: false };
      /**
     * ORDER_HISTORY_RECEVICE
     */
    case ORDER_HISTORY_RECEVICE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(ORDER_HISTORY_RECEVICE):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(ORDER_HISTORY_RECEVICE):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(ORDER_HISTORY_RECEVICE):
      return { ...state, loading: false };
       /**
     * TRANSACTION
     */
    case TRANSACTION:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(TRANSACTION):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(TRANSACTION):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(TRANSACTION):
      return { ...state, loading: false };

    /**
     * SENDNOTIFICATION
     */
    case SENDNOTIFICATION:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(SENDNOTIFICATION):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SENDNOTIFICATION):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SENDNOTIFICATION):
      return { ...state, loading: false };
      /**
     * USER_WALLET
     */
    case USER_WALLET:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(USER_WALLET):         
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(USER_WALLET):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(USER_WALLET):
      return { ...state, loading: false };
     
      
    default:
      return state;
  }

  
  
}

export default userReducer;