import { connect } from "react-redux";
import NewPin from "../Component/NewPin"
import {
    addNew_pin,
  } from '../modules/user/reducer'
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = {
  addNew_pin
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPin);
