import { createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
import { success } from "redux-saga-requests";
import reducers from "./reducers";
import sagas from "./sagas";
import { APP_REFRESH } from "../modules/app/reducer";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import setupSocket from '../utils/socket'

const persistConfig = {
  key: 'amatakWebsite',
  storage,
}

const sagaMiddleware = createSagaMiddleware();
const middleware = routerMiddleware();
const composedEnhancers = composeWithDevTools(
  applyMiddleware(...[sagaMiddleware], middleware, thunk)
);
const reducer = combineReducers(reducers);
const rootReducer = (state = {}, action) => {
    let newState;
    const { app, ...rest } = state;
    newState = state;
    return reducer(newState, action);
};

var input_value = false
if(localStorage.getItem('persist:amatakWebsite')){
  var dataUser=JSON.parse(localStorage.getItem('persist:amatakWebsite'));
  if(dataUser){
    var JsonDATA=JSON.parse(dataUser['user']);
    if(JsonDATA['login'] && JsonDATA['login']['data'] && JsonDATA['login']['data']['userName']){
      var str=JsonDATA['login']['data']['userName']
      var input_value = str.substr(str.length - 3);
    }
  }
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = createStore(persistedReducer, composedEnhancers);
export const persistor = persistStore(store)
export const socket = setupSocket(store.dispatch, input_value);

sagaMiddleware.run(sagas, socket);

export default store;
